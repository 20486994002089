import { UploadFile } from "antd"
import { File } from "../FileUpload"
import { GiftInfos } from "../../stores/projectStore"
import { GiftInfosHelper } from "../../helpers/giftInfosHelper"
import { useGiftStoreDependencies } from "./useGiftStoreDependencies"
import { useCallback, useRef, useState } from "react"
import { useIntl } from "react-intl"

export type FileUploadProps = {
	initialValue: string
	propertyToUpdateOnUpload: "pdf" | "picture"
	maxFileWeightInMb: number
	formatErrorMessage: string
	sizeErrorMessage: string
	unknownErrorMessage: string
}

export function useFileUpload({
	initialValue,
	propertyToUpdateOnUpload,
	maxFileWeightInMb,
	formatErrorMessage,
	sizeErrorMessage,
	unknownErrorMessage,
}: FileUploadProps) {
	const intl = useIntl()

	if (Number.isNaN(maxFileWeightInMb)) {
		throw new Error("invalid maxFileWeightInMn (number expected)")
	}

	const {
		currentStoreGift,
		currentProjectId,
		giftFileNameById,
		updateGiftInfo,
	} = useGiftStoreDependencies()
	const currentGift = GiftInfosHelper.makeGiftInfos(currentStoreGift!)
	const [fileLoading, setFileLoading] = useState(false)
	const [fileUploadError, setFileUploadError] = useState(
		null as string | null,
	)
	const [fileValidationError, setFileValidationError] = useState(
		null as string | null,
	)
	const [fileName, setFileName] = useState(null as string | null)

	const fileContent = useRef(initialValue)

	const handleFileUpload = useCallback(
		async (file: UploadFile<File>) => {
			setFileValidationError(null)
			if (
				currentStoreGift &&
				file.name === giftFileNameById[currentStoreGift.id!]
			) {
				return
			}
			try {
				setFileLoading(true)
				setFileName(file.name)
				const updatedGift: GiftInfos = Object.assign(
					GiftInfosHelper.updateProperty(
						currentGift,
						propertyToUpdateOnUpload,
						file.response!.preview,
					),
					{
						[file.name.endsWith("pdf")
							? "pdfFileName"
							: "pictureFileName"]: file.name,
					},
				)
				await updateGiftInfo(currentProjectId, updatedGift)
				fileContent.current = file.response?.previewUrl!
				setFileUploadError(null)
			} catch (err) {
				fileContent.current = ""
				setFileUploadError(
					intl.formatMessage(
						{
							description: "configureLinkGiftUpdateError",
							defaultMessage:
								"Une erreur s'est produite lors de {eventDesc}.",
						},
						{ eventDesc: "la mise à jour du fichier" },
					),
				)
			} finally {
				setFileLoading(false)
			}
		},
		[
			intl,
			currentProjectId,
			currentStoreGift,
			currentGift,
			giftFileNameById,
			propertyToUpdateOnUpload,
			updateGiftInfo,
		],
	)

	const handleFileUploadError = useCallback(
		(err: string) => {
			if (err.indexOf("size") >= 0) {
				setFileValidationError(sizeErrorMessage)
				return
			}

			if (err.indexOf("format") >= 0) {
				setFileValidationError(formatErrorMessage)
				return
			}
			setFileValidationError(unknownErrorMessage)
		},
		[formatErrorMessage, sizeErrorMessage, unknownErrorMessage],
	)

	return {
		fileContent: fileContent.current,
		handleFileUpload,
		handleFileUploadError,
		fileLoading,
		fileUploadError,
		fileValidationError,
		fileName,
	}
}
