import {
	Routes,
	Route,
	Navigate,
	useLocation,
	useNavigate,
} from "react-router-dom"

import { useAuth0 } from "@auth0/auth0-react"

import Home from "./views/Home"
import Navbar from "./components/Navbar"
import Login from "./views/Login"

import useUserStore from "./stores/userStore"

import "./App.css"
import { useEffect } from "react"
import CreateProject from "./views/CreateProject"
import CreateGift from "./views/CreateGift"
import ConfigureLinkGift from "./views/ConfigureLinkGift"
import ConfigurePdfGift from "./views/ConfigurePdfGift"
import Receivers from "./views/Receivers"
import ReceiversDetails from "./views/ReceiversDetails"
import SelectOccasion from "./views/SelectOccasion"
import EventDate from "./views/EventDate"
import OtherOccasion from "./views/OtherOccasion"
import TemplateSelection from "./views/TemplateSelection"
import ReceiversMode from "./views/ReceiversMode"
import ReceiversUnique from "./views/ReceiversUnique"
import LayoutSelection from "./views/LayoutSelection"
import EditMessageLayoutPage from "./views/EditMessageLayoutPage"
import OpeningMode from "./views/OpeningMode"
import OpeningGame from "./views/OpeningGame"
import OpeningGameAnswers from "./views/OpeningGameAnswers"
import OpeningDate from "./views/OpeningDate"
import OpeningDateHour from "./views/OpeningDateHour"
import Packaging from "./views/Packaging"
import PackagingTable from "./views/PackagingTable"
import ExperienceLoadingScreen from "./views/ExperienceLoadingScreen"
import ExperienceContainer from "./views/ExperienceContainer"
import ExperienceOpeningQA from "./views/ExperienceOpeningQA"
import ExperienceOpeningDate from "./views/ExperienceOpeningDate"
import ExperiencePreLoadingScreen from "./views/ExperiencePreLoadingScreen"
import { Flex } from "@chakra-ui/react"
import Projects from "./views/Projects"
import Catalog from "./views/Catalog"

const App = () => {
	const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0()
	const location = useLocation()
	const userStore = useUserStore()
	const navigate = useNavigate()

	useEffect(() => {
		getAccessTokenSilently().then((token) => {
			useUserStore.setState((state) =>
				Object.assign(state, { accessToken: token }),
			)
		})

		if (location.pathname === "/") {
			navigate("/home")
			return
		}

		const authlessRoutes = [/^.+\/loading/, /^.+\/opening$/]
		const isAuthless =
			authlessRoutes.filter((r) => r.test(location.pathname)).length > 0
		if (!isAuthenticated && location.pathname !== "/login" && !isAuthless) {
			navigate("/login")
			return
		} else if (isAuthenticated && location.pathname === "/login") {
			navigate("/home")
			return
		}
	}, [userStore, isAuthenticated, getAccessTokenSilently])

	if (isLoading)
		return (
			<>
				<Navbar></Navbar>
			</>
		)

	return (
		<Flex justifyContent="center" w="100vw" h="100vh">
			{isAuthenticated ? <Navbar></Navbar> : undefined}
			<div
				style={{ height: "var(--chakra-sizes-full)", width: "100%" }}
				className="routes-container"
			>
				<Routes>
					<Route path="/home" element={<Home />} />
					<Route path="/home/:status" element={<Home />} />
					<Route path="/login" element={<Login />} />
					<Route path="/projects" element={<Projects />} />
					<Route
						path={"/project/creation"}
						element={<CreateProject />}
					/>
					<Route
						path={"/project/creation?id=:id"}
						element={<CreateProject />}
					/>
					<Route
						path={"/projects/:projectId/gifts/creation"}
						element={<CreateGift />}
					/>
					<Route
						path={"/projects/:projectId/gifts/:giftId/creation"}
						element={<CreateGift />}
					/>
					<Route
						path={"/projects/:projectId/gifts/:giftId/linkInfos"}
						element={<ConfigureLinkGift />}
					/>
					<Route
						path={"/projects/:projectId/gifts/:giftId/pdfInfos"}
						element={<ConfigurePdfGift />}
					/>
					<Route
						path={
							"/projects/:projectId/gifts/:giftId/receiversMode"
						}
						element={<ReceiversMode />}
					/>
					<Route
						path={"/projects/:projectId/gifts/:giftId/receivers"}
						element={<Receivers />}
					/>
					<Route
						path={
							"/projects/:projectId/gifts/:giftId/receiversDetails"
						}
						element={<ReceiversDetails />}
					/>
					<Route
						path={
							"/projects/:projectId/gifts/:giftId/receiversUnique"
						}
						element={<ReceiversUnique />}
					></Route>
					<Route
						path={"/projects/:projectId/gifts/:giftId/occasion"}
						element={<SelectOccasion />}
					/>
					<Route
						path={
							"/projects/:projectId/gifts/:giftId/otherOccasion"
						}
						element={<OtherOccasion />}
					/>
					<Route
						path={"/projects/:projectId/gifts/:giftId/eventDate"}
						element={<EventDate />}
					/>
					<Route
						path={"/projects/:projectId/gifts/:giftId/template"}
						element={<TemplateSelection />}
					/>
					<Route
						path={
							"/projects/:projectId/gifts/:giftId/template/:templateId/layout"
						}
						element={<LayoutSelection />}
					/>
					<Route
						path={
							"/projects/:projectId/gifts/:giftId/template/:templateId/layouts/editMessage"
						}
						element={<EditMessageLayoutPage />}
					/>
					<Route
						path={"/projects/:projectId/gifts/:giftId/openingMode"}
						element={<OpeningMode />}
					/>
					<Route
						path={"/projects/:projectId/gifts/:giftId/openingGame"}
						element={<OpeningGame />}
					/>
					<Route
						path={
							"/projects/:projectId/gifts/:giftId/openingGame/answers"
						}
						element={<OpeningGameAnswers />}
					/>
					<Route
						path={"/projects/:projectId/gifts/:giftId/openingDate"}
						element={<OpeningDate />}
					/>
					<Route
						path={
							"/projects/:projectId/gifts/:giftId/openingDateHour"
						}
						element={<OpeningDateHour />}
					/>
					<Route
						path={"/projects/:projectId/gifts/:giftId/packaging"}
						element={<Packaging />}
					/>
					<Route
						path={
							"/projects/:projectId/gifts/:giftId/packagingTable"
						}
						element={<PackagingTable />}
					/>
					<Route
						path={"/projects/:projectId/gifts/:giftId/loading"}
						element={<ExperiencePreLoadingScreen />}
					/>
					<Route
						path={
							"/projects/:projectId/gifts/:giftId/loading/swipe"
						}
						element={<ExperienceLoadingScreen />}
					/>
					<Route
						path={"/projects/:projectId/gifts/:giftId/loading/date"}
						element={<ExperienceOpeningDate />}
					/>
					<Route
						path={"/projects/:projectId/gifts/:giftId/loading/game"}
						element={<ExperienceOpeningQA />}
					/>
					<Route
						path={"/projects/:projectId/gifts/:giftId/opening"}
						element={<ExperienceContainer />}
					/>
					<Route path={"/catalog"} element={<Catalog />} />
				</Routes>
			</div>
		</Flex>
	)
}

export default App
