import React from "react"
import ProjectBreadcrumb, { Step } from "../components/ProjectBreadcrumb"
import { getSteps } from "./breadcrumbStepsHelper"
import Header2 from "../components/Header2"
import { FormattedMessage } from "react-intl"
import SmallText from "../components/SmallText"
import Page from "../components/Page"
import useProjectStore from "../stores/projectStore"
import {
	TableContainer,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	Flex,
} from "@chakra-ui/react"
import SecondaryButton from "../components/SecondaryButton"
import { Link as DomLink, useNavigate } from "react-router-dom"
import PrimaryButton from "../components/PrimaryButton"

import "./HideMainNavbar.css"
import useFinalizeReceiversUseCase from "../useCases/finalizeReceiversUseCase"
import SlicesHelper from "../stores/slices/helpers/slicesHelper"

export default function ReceiversDetails(): React.JSX.Element {
	const navigate = useNavigate()

	const giftClient = useProjectStore(SlicesHelper.getGiftClient)

	const {
		// receivers
		receivers,
		updateGiftEvent,
		updateGiftEventError,
		// navigation
		currentProjectId,
		currentStoreGift,
	} = useFinalizeReceiversUseCase(giftClient)

	const steps: Step[] = getSteps("Évènement", currentStoreGift?.reachedStep)
	return (
		<Page>
			<ProjectBreadcrumb
				steps={steps}
				pb="63.5px"
				justifyContent="center"
				projectId={currentProjectId.toString()}
				giftId={currentStoreGift?.id?.toString()}
			/>
			<Header2 alignSelf="center" mb="4px">
				<FormattedMessage
					description="receiversDetailsTitleLabel"
					defaultMessage="{cnt} destinataires"
					values={{
						cnt: receivers.length,
					}}
				/>
			</Header2>
			<SmallText mb="24px">
				<FormattedMessage
					description="receiversDetailsSubtitle"
					defaultMessage="Vérifiez vos données importées"
				/>
			</SmallText>

			<TableContainer
				w="750px"
				padding="12px"
				alignSelf="center"
				borderRadius="12px"
				border="1px solid #E2E8F0"
			>
				<Table variant="simple" cellPadding="24px">
					<Thead
						fontFamily="Nunito Sans"
						fontSize="md"
						fontWeight="800"
						lineHeight="16px"
						letterSpacing="0.7px"
					>
						<Tr textTransform="uppercase">
							<Th>
								<FormattedMessage
									description="generalName"
									defaultMessage="nom"
								/>
							</Th>
							<Th>
								<FormattedMessage
									description="generalFirstName"
									defaultMessage="prénom"
								/>
							</Th>
							<Th>
								<FormattedMessage
									description="generalEmail"
									defaultMessage="email"
								/>
							</Th>
							<Th>
								<FormattedMessage
									description="generalPersonKind"
									defaultMessage="genre"
								/>
							</Th>
						</Tr>
					</Thead>
					<Tbody
						fontFamily="Nunito Sans"
						fontSize="md"
						fontWeight="400"
						lineHeight="20px"
					>
						{receivers.map((receiver, idx) => (
							<Tr key={idx} textAlign="right">
								<Td>{receiver.name}</Td>
								<Td>{receiver.firstname}</Td>
								<Td>{receiver.email}</Td>
								<Td>
									<FormattedMessage
										description="receiversGenderSelect"
										defaultMessage="{kind, select, Man {Homme}
                                 Woman {Femme}
                                 other {Autre}}"
										values={{ kind: receiver.kind }}
									/>
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</TableContainer>

			<Flex
				w="full"
				justifyContent="center"
				gap="24px"
				mt="80px"
				mb="24px"
			>
				<SecondaryButton
					as={DomLink}
					variant="outline"
					onClick={(e) => {
						e.preventDefault()
						navigate(
							`/projects/${currentProjectId}/gifts/${
								currentStoreGift!.id
							}/receivers`,
						)
					}}
				>
					<FormattedMessage
						description="generalReturn"
						defaultMessage="Retour"
					/>
				</SecondaryButton>
				<PrimaryButton
					onClick={(e: React.MouseEvent<HTMLButtonElement>): void => {
						e.preventDefault()
						updateGiftEvent().then(() => {
							navigate(
								`/projects/${currentProjectId}/gifts/${currentStoreGift?.id!}/occasion`,
							)
						})
					}}
				>
					<FormattedMessage
						description="generalNext"
						defaultMessage="Suivant"
					/>
				</PrimaryButton>
			</Flex>
			{updateGiftEventError !== null ? (
				<Flex w="554px" alignSelf="center" mb="24px" color="red.500">
					{updateGiftEventError}
				</Flex>
			) : (
				<></>
			)}
		</Page>
	)
}
