import { Flex, Text } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import React from "react"
import Project from "../domain/project"
import { useNavigate } from "react-router-dom"

function DropdownButton(props: {
	children: React.JSX.Element
	onClick?: () => void
	color?: string
}): React.JSX.Element {
	return (
		<Text
			backgroundColor="white"
			color="#1F3043"
			fontFamily="Nunito Sans"
			fontWeight="400"
			fontSize="16px"
			lineHeight="20.8px"
			padding="12px 16px"
			borderBottom="1px solid #02172D14"
			textAlign="left"
			_hover={{
				cursor: "pointer",
			}}
			{...props}
		>
			{props.children}
		</Text>
	)
}

export default function Dropdown(props: {
	visible?: boolean
	project: Project
}): React.JSX.Element {
	const navigate = useNavigate()

	return (
		<Flex
			display={props.visible ? "flex" : "none"}
			position="absolute"
			zIndex="10"
			backgroundColor="white"
			flexDirection="column"
			borderRadius="8px"
			overflow="hidden"
			boxShadow="0px 10px 15px -3px #0000001A"
			top="calc(100% + 4px)"
			right="0"
		>
			<Text
				color="#959EA7"
				padding="12px 16px 0 16px"
				fontFamily="Nunito Sans"
				fontSize="10px"
				fontWeight="800"
				lineHeight="10px"
				textAlign="left"
			>
				<FormattedMessage
					description="homePageProjectActionModalTitle"
					defaultMessage="SÉLECTIONNEZ L'OPTION"
				/>
			</Text>
			<DropdownButton
				onClick={() =>
					navigate(`/project/creation?id=${props.project.id}`)
				}
			>
				<FormattedMessage
					description="homePageProjectActionModalModify"
					defaultMessage="Modifier l'expérience"
				/>
			</DropdownButton>
			<DropdownButton color="#DF3B30">
				<FormattedMessage
					description="homePageProjectActionModalDelete"
					defaultMessage="Supprimer l'expérience"
				/>
			</DropdownButton>
		</Flex>
	)
}
