import { useIntl } from "react-intl"
import { useGiftStoreDependencies } from "../components/hooks/useGiftStoreDependencies"
import { useCallback, useState } from "react"
import { getGiftDate } from "../domain/gift"
import GiftClient from "../domain/adapters/secondary/giftClient"
import { DateHelper } from "../helpers/dateHelper"
import { GiftEventInfos } from "../stores/projectStore"

export default function useChangeGiftEventDateUseCase(giftClient: GiftClient) {
	const intl = useIntl()

	const { currentProjectId, currentStoreGift } = useGiftStoreDependencies()
	const updateGiftEventInfos = giftClient.updateGiftEventInfos

	const today = new Date()
	const [date, setDate] = useState(
		getGiftDate(currentStoreGift?.event?.date) ?? today,
	)
	const [hasDateChanged, setHasDateChanged] = useState(false)
	const [updateError, setUpdateError] = useState<string | null>(null)
	const [activeStartDate, setActiveStartDate] = useState<Date | null>(today)

	const currentStoreGiftHasDate =
		getGiftDate(currentStoreGift?.event?.date) !== undefined

	const activeStartDateChanged = useCallback((date: Date | null) => {
		setActiveStartDate(date)
	}, [])

	const dateChanged = useCallback(
		(value: string, _: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			setDate(new Date(value))
			setHasDateChanged(true)
		},
		[],
	)

	const dateStatus = useCallback(
		(
			tileDate: Date,
			selectedDate: Date,
			initialDate: Date | null,
		): string | undefined => {
			let res = ""
			if (DateHelper.isToday(tileDate)) {
				res = `${res.length > 0 ? " " : ""}today`
			}

			if (DateHelper.isSameDay(selectedDate, tileDate)) {
				res = `${res.length > 0 ? " " : ""}selectedDay`
			}

			if (DateHelper.isBeforeToday(tileDate)) {
				res = `${res.length > 0 ? " " : ""}disabled`
			}

			if (!DateHelper.isSameMonthAs(tileDate, initialDate)) {
				res = `${res.length > 0 ? " " : ""}notInStartingMonth`
			}

			return res
		},
		[],
	)

	const shouldDisable = useCallback((date: Date) => {
		return DateHelper.isBeforeToday(date)
	}, [])

	const formatWeekday = useCallback(
		(_: string | undefined, date: Date) => {
			const weekDays = [
				intl.formatMessage({
					description: "defaultWeekdaySun",
					defaultMessage: "DIM",
				}),
				intl.formatMessage({
					description: "defaultWeekdayMon",
					defaultMessage: "LUN",
				}),
				intl.formatMessage({
					description: "defaultWeekdayTue",
					defaultMessage: "MAR",
				}),
				intl.formatMessage({
					description: "defaultWeekdayWed",
					defaultMessage: "MER",
				}),
				intl.formatMessage({
					description: "defaultWeekdayThu",
					defaultMessage: "JEU",
				}),
				intl.formatMessage({
					description: "defaultWeekdayFri",
					defaultMessage: "VEN",
				}),
				intl.formatMessage({
					description: "defaultWeekdaySat",
					defaultMessage: "SAM",
				}),
			]

			return weekDays[date.getDay()][0]
		},
		[intl],
	)

	const updateDate = useCallback(async () => {
		try {
			const giftEventInfos: GiftEventInfos = {
				giftId: currentStoreGift?.id!,
				date: date,
				receivers: currentStoreGift?.event?.receivers,
				sendingMode: currentStoreGift?.event?.sendingMode,
				occasion: currentStoreGift?.event?.occasion,
			}
			await updateGiftEventInfos(currentProjectId, giftEventInfos)
			setUpdateError(null)
			return true
		} catch (err) {
			setUpdateError(
				(err as Error)?.message ??
					intl.formatMessage(
						{
							description: "configureLinkGiftUpdateError",
							defaultMessage:
								"Une erreur s'est produite lors de {eventDesc}.",
						},
						{ eventDesc: "la mise à jour de la date" },
					),
			)
			return false
		}
	}, [
		currentProjectId,
		currentStoreGift?.event?.occasion,
		currentStoreGift?.event?.receivers,
		currentStoreGift?.event?.sendingMode,
		currentStoreGift?.id,
		date,
		intl,
		updateGiftEventInfos,
	])

	return {
		// event date
		hasDateChanged,
		activeStartDate,
		updateError,
		date,

		currentStoreGiftHasDate,
		activeStartDateChanged,
		dateChanged,
		updateDate,
		shouldDisable,
		dateStatus,
		// format
		formatWeekday,
		// navigation
		currentProjectId,
		currentStoreGift,
	}
}
