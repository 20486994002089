import { isBefore, isSameMonth as sameMonth } from "date-fns"

export class DateHelper {
	static isSameDay(first: Date, second: Date): boolean {
		return (
			first.getDate() === second.getDate() &&
			first.getMonth() === second.getMonth() &&
			first.getFullYear() === second.getFullYear()
		)
	}

	static isToday(date: Date): boolean {
		const today = new Date()
		return DateHelper.isSameDay(date, today)
	}

	static isBeforeToday(date: Date): boolean {
		const today = new Date()
		return isBefore(date, today) && !DateHelper.isSameDay(date, today)
	}

	static isSameMonthAsToday(date: Date): boolean {
		const today = new Date()
		return sameMonth(date, today)
	}

	static isSameMonthAs(date: Date, dateToCompare: Date | null): boolean {
		return dateToCompare !== null && sameMonth(dateToCompare, date)
	}
}
