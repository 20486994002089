import { TextLevel } from "../domain/text"
import { Template } from "../domain/template"

export class LayoutHelper {
	static fontSelector: Record<TextLevel, (template: Template) => string> = {
		TITLE: (template) => template.titleFont,
		SMALL_TITLE: (template) => template.titleFont,
		SUBTITLE: (template) => template.titleFont,
		PARAGRAPH: (template) => template.bodyFont,
		DESCRIPTION: (template) => template.bodyFont,
	}

	static getFontWeight(font: string) {
		const groups = /.+?(?<weight>normal|bold|semibold|semi-bold)/i.exec(
			font,
		)?.groups
		return groups &&
			groups.weight &&
			["semibold", "semi-bold"].includes(
				groups.weight.toLocaleLowerCase(),
			)
			? 500
			: groups?.weight ?? "normal"
	}

	static getFontFamily(font: string) {
		if (font.toLocaleLowerCase().includes("semi-bold")) {
			return font
				.substring(0, font.toLocaleLowerCase().indexOf("semi-bold"))
				.trim()
		}

		if (font.toLocaleLowerCase().includes("bold")) {
			return font
				.substring(0, font.toLocaleLowerCase().indexOf("bold"))
				.trim()
		}
		return font
	}

	static getFontSize(template: Template, level: TextLevel): string[] {
		const sizeSelector: Record<TextLevel, string[]> = {
			TITLE: template.titleSizes,
			SMALL_TITLE: template.subtitleSizes,
			SUBTITLE: template.subtitleSizes,
			DESCRIPTION: template.bodySizes,
			PARAGRAPH: template.bodySizes,
		}
		return sizeSelector[level]
	}

	static getTemplateFontFamily(template: Template, level: TextLevel) {
		return LayoutHelper.getFontFamily(
			LayoutHelper.fontSelector[level](template),
		)
	}

	static getTemplateFontWeight(template: Template, level: TextLevel) {
		return LayoutHelper.getFontWeight(
			LayoutHelper.fontSelector[level](template),
		)
	}

	static getTemplateFontSize(template: Template, level: TextLevel): string[] {
		const sizeSelector: Record<TextLevel, string[]> = {
			TITLE: template.titleSizes,
			SMALL_TITLE: template.smallTitleSizes,
			SUBTITLE: template.subtitleSizes,
			DESCRIPTION: template.bodySizes,
			PARAGRAPH: template.bodySizes,
		}
		return sizeSelector[level]
	}

	// TEMP isolate opening part from non opening one
	// scope: computeFontFamily, computeFontWeight, computeFontSize
	static computeFontFamily(
		template: Template,
		level: TextLevel,
		isOpening: boolean,
	) {
		if (!isOpening) {
			return level === "PARAGRAPH"
				? LayoutHelper.getFontFamily(template.bodyFont)
				: LayoutHelper.getFontFamily(template.titleFont)
		}
		return LayoutHelper.getTemplateFontFamily(template, level)
	}

	static computeFontWeight(
		template: Template,
		level: TextLevel,
		isOpening: boolean,
	) {
		if (!isOpening) {
			return level === "PARAGRAPH"
				? LayoutHelper.getFontWeight(template.bodyFont)
				: LayoutHelper.getFontWeight(template.titleFont)
		}
		return LayoutHelper.getTemplateFontWeight(template, level)
	}

	static computeFontSize(
		template: Template,
		level: TextLevel,
		isOpening: boolean,
	) {
		if (!isOpening) {
			return level === "TITLE"
				? "18px"
				: LayoutHelper.getFontSize(template, level)
		}
		return LayoutHelper.getTemplateFontSize(template, level)
	}
}
