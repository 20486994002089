import { useGiftStoreDependencies } from "../components/hooks/useGiftStoreDependencies"
import { useTitle } from "../components/hooks/useTitle"
import { useDescription } from "../components/hooks/useDescription"
import { useLink } from "../components/hooks/useLink"
import { ConfigurationHelper } from "../helpers/configurationHelper"
import { useFileUpload } from "../components/hooks/useFileUpload"
import { useIntl } from "react-intl"
import { useCallback } from "react"

export default function useConfigureLinkGiftUseCase() {
	const intl = useIntl()
	const { currentStoreGift, currentProjectId } = useGiftStoreDependencies()

	const {
		title,
		titleLoading,
		titleUpdateError,
		changeTitle,
		validateTitle,
		updateTitle,
		hasTitleChanged,
	} = useTitle(currentStoreGift?.title || "")

	const {
		description,
		descriptionLoading,
		descriptionUpdateError,
		changeDescription,
		validateDescription,
		updateDescription,
		hasDescriptionChanged,
	} = useDescription(currentStoreGift?.description || "")

	const {
		link,
		linkLoading,
		linkUpdateError,
		changeLink,
		validateLink,
		updateLink,
		hasLinkChanged,
	} = useLink(currentStoreGift?.link || "")

	const existingPictureUrl =
		currentStoreGift?.picturePath !== undefined
			? `${
					process.env.REACT_APP_API_URL
			  }/${currentStoreGift?.picturePath.replaceAll("\\", "/")}`
			: undefined
	const { maxImageWeightInMb } = ConfigurationHelper.readConfiguration()
	const sizeErrorMessage = intl.formatMessage(
		{
			description: "configurePdfGiftSizeErrorMsg",
			defaultMessage: "Votre fichier ne peut pas excéder {maxSizeMo}mo",
		},
		{ maxSizeMo: maxImageWeightInMb },
	)
	const formatErrorMessage = intl.formatMessage({
		description: "configurePdfGiftFormatErrorMsg",
		defaultMessage: "Votre fichier doit être aux format pdf",
	})
	const unknownErrorMessage = intl.formatMessage({
		description: "generalUnknownErrorMessage",
		defaultMessage: "Une erreur s'est produite.",
	})
	const {
		fileContent: pictureContent,
		handleFileUpload: handlePictureUpload,
		fileLoading: pictureLoading,
		fileUploadError: pictureUploadError,
	} = useFileUpload({
		initialValue: existingPictureUrl || "",
		propertyToUpdateOnUpload: "picture",
		maxFileWeightInMb: maxImageWeightInMb,
		formatErrorMessage,
		sizeErrorMessage,
		unknownErrorMessage,
	})

	const checkAllFields = useCallback(
		() =>
			[link, title, description, pictureContent].find(
				(str) => str.length === 0,
			) === undefined,
		[link, title, description, pictureContent],
	)

	return {
		// title
		title,
		titleLoading,
		titleUpdateError,
		changeTitle,
		validateTitle,
		updateTitle,
		hasTitleChanged,
		// description
		description,
		descriptionLoading,
		descriptionUpdateError,
		changeDescription,
		validateDescription,
		updateDescription,
		hasDescriptionChanged,
		// link
		link,
		linkLoading,
		linkUpdateError,
		changeLink,
		validateLink,
		updateLink,
		hasLinkChanged,
		// fileUpload
		pictureContent,
		handlePictureUpload,
		pictureLoading,
		pictureUploadError,
		// other
		currentStoreGift,
		currentProjectId,
		checkAllFields,
		loading:
			titleLoading || descriptionLoading || linkLoading || pictureLoading,
		updateError:
			titleUpdateError ||
			descriptionUpdateError ||
			linkUpdateError ||
			pictureUploadError,
	}
}
