import CommandClient from "../domain/adapters/secondary/commandClient"
import { useCallback, useState } from "react"
import { useIntl } from "react-intl"
import GiftClient from "../domain/adapters/secondary/giftClient"
import { useGiftStoreDependencies } from "../components/hooks/useGiftStoreDependencies"

export default function useReserveGiftUseCase(
	commandClient: CommandClient,
	giftClient: GiftClient,
) {
	const intl = useIntl()
	const { currentProjectId, currentStoreGift } = useGiftStoreDependencies()

	const [reserveError, setReserveError] = useState<string | null>("")
	const [isReserveLoading, setIsReserveLoading] = useState(false)

	const reserveGift = commandClient.reserveGift
	const setCurrentGiftCommand = giftClient.setCurrentGiftCommand

	const reserveGiftOnCommand = useCallback(
		async (projectId: number, giftId: number, commandId: number) => {
			const reserveError = intl.formatMessage({
				description: "reserveGiftErrorMessage",
				defaultMessage:
					"Une erreur s'est produite en sauvegardant les données d'emballage",
			})
			let success = false
			try {
				setIsReserveLoading(true)
				if (currentStoreGift?.id === undefined) {
					console.error(
						"[reserveGiftUseCase][reserveGiftOnCommand]missing current gift",
					)
					setReserveError(reserveError)
					setIsReserveLoading(false)
					return
				}
				const updatedCommand = await reserveGift(
					projectId,
					giftId,
					commandId,
				)
				await setCurrentGiftCommand(
					currentProjectId,
					currentStoreGift.id,
					updatedCommand,
				)
				setReserveError(null)
				setIsReserveLoading(false)
				success = true
			} catch {
				setReserveError(reserveError)
				setIsReserveLoading(false)
			}
			return success
		},
		[
			currentProjectId,
			currentStoreGift?.id,
			intl,
			reserveGift,
			setCurrentGiftCommand,
		],
	)

	return {
		reserveError,
		isReserveLoading,
		reserveGift: reserveGiftOnCommand,
	}
}
