import useProjectStore from "../../stores/projectStore"

export function useGiftStoreDependencies() {
	const currentProjectId = useProjectStore((state) => state.currentProjectId)
	const currentStoreGift = useProjectStore(
		(state) =>
			state.projects.find((proj) => proj.id === state.currentProjectId)
				?.gifts[0],
	)
	const giftFileNameById = useProjectStore(
		(state) => state.giftPictureFileNameById,
	)
	const updateGiftInfo = useProjectStore((state) => state.updateGiftInfos)

	const currentGiftReceiversChanged = useProjectStore(
		(state) => state.currentGiftReceiversChanged,
	)

	const updateGiftEventInfos = useProjectStore(
		(state) => state.updateGiftEventInfos,
	)

	return {
		currentProjectId,
		currentStoreGift,
		giftFileNameById,
		updateGiftInfo,
		currentGiftReceiversChanged,
		updateGiftEventInfos,
	}
}
