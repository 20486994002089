import React from "react"
import { Image } from "@chakra-ui/react"

export function CustomLinkIcon() {
	return (
		<Image
			src="/linkTypeLink.png"
			w="56px"
			h="56px"
			mx="auto"
			position="relative"
			top="-24px"
			alt="linkLinkType"
		/>
	)
}

export function PdfIcon() {
	return (
		<Image
			src="/linkTypePdf.png"
			w="56px"
			h="56px"
			mx="auto"
			position="relative"
			top="-24px"
			alt="pdfLinkType"
		/>
	)
}

export function MessageIcon() {
	return (
		<Image
			src="/linkTypeMessage.png"
			w="56px"
			h="56px"
			mx="auto"
			position="relative"
			top="-24px"
			alt="messageLinkType"
		/>
	)
}
