import useProjectStore, { GiftEventInfos } from "../stores/projectStore"
import GiftClient from "../domain/adapters/secondary/giftClient"
import { useCallback, useState } from "react"
import { Kind, Receiver } from "../domain/receiver"
import { z } from "zod"
import ProjectClient from "../domain/adapters/secondary/projectClient"
import Gift from "../domain/gift"

interface FieldsValidation {
	name: boolean
	firstname: boolean
	email: boolean
	kind: boolean
}

export default function useFinalizeUniqueReceiverUseCase(
	giftClient: GiftClient,
	projectClient: ProjectClient,
) {
	const currentProjectId = useProjectStore((state) => state.currentProjectId)
	const currentStoreGift = useProjectStore(
		(state) =>
			state.projects.find((proj) => proj.id === currentProjectId)
				?.gifts[0],
	)
	const updateGiftEventInfos = giftClient.updateGiftEventInfos

	const [triedToSubmit, setTriedToSubmit] = useState(false)

	const [receiverInfos, setReceiverInfos] = useState(
		(currentStoreGift?.event?.sendingMode === "UNIQUE" &&
			(currentStoreGift?.event?.receivers ?? [])[0]) ||
			({
				name: "",
				firstname: "",
				email: "",
				kind: "" as Kind,
			} as Receiver),
	)

	const [receiverValidatedField, setReceiverValidatedField] = useState({
		name: false,
		firstname: false,
		email: false,
		kind: false,
	})

	const finalizeAttempted = useCallback(
		(hasTried: boolean) => setTriedToSubmit(hasTried),
		[],
	)

	const receiverUpdated = useCallback(
		(receiver: Receiver) => setReceiverInfos(receiver),
		[],
	)

	const isFormNotComplete = useCallback(
		() => Object.values(receiverInfos).some((value) => value === ""),
		[receiverInfos],
	)

	const refreshCurrentGift = projectClient.refreshCurrentGift
	const refreshCurrentStoreGift = useCallback(
		() => refreshCurrentGift(),
		[refreshCurrentGift],
	)

	const updateReceiverInfos = useCallback(
		async (receiverInfos: Receiver, currentStoreGift: Gift) => {
			function updateReceiverValidatedField(
				receiver: Receiver,
			): FieldsValidation {
				const validatedFields = {
					name: z.string().min(1).max(50).safeParse(receiver.name)
						.success,
					firstname: z
						.string()
						.min(1)
						.max(50)
						.safeParse(receiver.firstname).success,
					email: z.string().email().safeParse(receiver.email).success,
					kind: z
						.enum(["Man", "Woman", "Other"])
						.safeParse(receiver.kind).success,
				}
				setReceiverValidatedField(validatedFields)
				return validatedFields
			}

			function isFormValid(validatedFields: FieldsValidation): boolean {
				return Object.values(validatedFields).every((value) => value)
			}

			setTriedToSubmit(true)
			const validatedFields = updateReceiverValidatedField(receiverInfos)
			if (!isFormValid(validatedFields)) return

			const eventInfos: GiftEventInfos = {
				receivers: [{ ...receiverInfos }],
				sendingMode: currentStoreGift.event?.sendingMode!,
				giftId: currentStoreGift.id!,
			}

			if (
				currentStoreGift?.event?.occasion != null &&
				currentStoreGift?.event?.occasion?.length > 0
			) {
				eventInfos.occasion = currentStoreGift.event.occasion
				eventInfos.category = currentStoreGift.event.category
			}

			await updateGiftEventInfos(currentProjectId, eventInfos)
		},
		[currentProjectId, updateGiftEventInfos],
	)

	if (currentStoreGift?.id === undefined) {
		throw new Error("Missing gift")
	}

	return {
		// receiver
		triedToSubmit,
		receiverInfos,
		finalizeAttempted,
		receiverUpdated,
		isFormNotComplete,
		receiverValidatedField,
		updateReceiverInfos,
		// navigation
		currentProjectId,
		currentStoreGift,
		// project opening
		refreshCurrentStoreGift,
	}
}
