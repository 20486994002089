import { useCallback, useState } from "react"
import { Template } from "../domain/template"
import { useGiftStoreDependencies } from "../components/hooks/useGiftStoreDependencies"
import { useIntl } from "react-intl"
import TemplateClient from "../domain/adapters/secondary/templateClient"

export default function useChangeTemplateUseCase(
	templateClient: TemplateClient,
) {
	const intl = useIntl()

	const chooseTemplate = templateClient.chooseTemplate
	const getTemplates = templateClient.getTemplates
	const getLayoutPacks = templateClient.getLayoutPacks

	const [templates, setTemplates] = useState([] as Template[])
	const [templatesLoadingError, setTemplatesLoadingError] = useState<
		string | null
	>(null)

	const { currentProjectId, currentStoreGift } = useGiftStoreDependencies()

	if (currentStoreGift === undefined || currentStoreGift.id === undefined) {
		throw new Error("missing current gift!")
	}

	const loadTemplates = useCallback(async () => {
		try {
			const [templates] = await Promise.all([getTemplates()])
			setTemplates(templates.filter((t) => t.name !== "PresentGreen"))
		} catch {
			const errorMessage = intl.formatMessage({
				description: "templateSelectionTemplateLoadingErrorMessage",
				defaultMessage:
					"Une erreur s'est produite lors de la récupération des modèles",
			})
			setTemplatesLoadingError(errorMessage)
		}
	}, [getTemplates, intl])

	const loadLayoutPacks = useCallback(async () => {
		await getLayoutPacks()
	}, [getLayoutPacks])

	const selectTemplate = useCallback(
		async (templateId: number) => {
			// no error can happen within chooseTemplate, hence we don't need to deal with it.
			try {
				await chooseTemplate(templateId)
			} finally {
			}
		},
		[chooseTemplate],
	)

	const currentStoreTemplateId = templateClient.currentTemplateId

	return {
		// template
		templates,
		templatesLoadingError,
		selectTemplate,
		loadTemplates,
		loadLayoutPacks,
		currentStoreTemplateId,

		// navigation
		currentProjectId,
		currentStoreGift,
	}
}
