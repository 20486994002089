import React from "react"

export default function Icon(props: {
	name: string
	color: string
	width: string
	height: string
}): React.JSX.Element {
	// div+mask pour pas polluer le code avec des SVGs et pouvoir modifier
	// leur couleur (ce que ne permet pas <img src="*.svg">)
	return (
		<div
			style={{
				mask: `url(/${props.name}.svg) no-repeat center`,
				backgroundColor: props.color,
				width: props.width,
				height: props.height,
			}}
		></div>
	)
}
