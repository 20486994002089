import React from "react"
import { useNavigate, Link as DomLink } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import useProjectStore from "../stores/projectStore"
import Page from "../components/Page"
import SmallText from "../components/SmallText"
import ProjectBreadcrumb, { Step } from "../components/ProjectBreadcrumb"
import Header2 from "../components/Header2"
import { getSteps } from "./breadcrumbStepsHelper"
import {
	Button,
	Flex,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react"
import { DownloadIcon } from "@chakra-ui/icons"
import PrimaryButton from "../components/PrimaryButton"
import SecondaryButton from "../components/SecondaryButton"
import SlicesHelper from "../stores/slices/helpers/slicesHelper"
import useSaveReceiversUseCase from "../useCases/saveReceiversUseCase"

export default function PackagingTable(): React.JSX.Element {
	const navigate = useNavigate()

	const projectClient = useProjectStore(SlicesHelper.getProjectClient)
	const {
		// save receivers
		receivers,
		save,
		isSaveLoading,

		// navigation
		currentProjectId,
		currentStoreGift,
	} = useSaveReceiversUseCase(projectClient)

	const steps: Step[] = getSteps("Emballage", currentStoreGift?.reachedStep)

	return (
		<Page>
			<ProjectBreadcrumb
				steps={steps}
				pb="63.5px"
				justifyContent="center"
				projectId={currentProjectId.toString()}
				giftId={currentStoreGift?.id?.toString()}
			/>
			<Header2 alignSelf="center" mb="4px">
				<FormattedMessage
					description="packagingTitleLabel"
					defaultMessage="Lier le projet à une commande"
				/>
			</Header2>
			<SmallText mb="24px">
				<FormattedMessage
					description="packagingSubtitle"
					defaultMessage="Sélectionnez la commande lié à cette expérience Présent pour charger les noeuds."
				/>
			</SmallText>
			<Flex flexDir="column" gap="32px">
				<Flex flexDir="column" gap="16px">
					<TableContainer
						w="750px"
						padding="12px"
						alignSelf="center"
						borderRadius="12px"
						border="1px solid #E2E8F0"
						bgColor="white"
					>
						<Table
							variant="simple"
							cellPadding="24px"
							className="tableReceivers"
						>
							<Thead
								fontSize="md"
								fontFamily="Nunito Sans"
								fontWeight="800"
								lineHeight="16px"
								letterSpacing="0.7px"
							>
								<Tr textTransform="uppercase">
									<Th>
										<FormattedMessage
											description="packagingNameHeader"
											defaultMessage="nom"
										/>
									</Th>
									<Th>
										<FormattedMessage
											description="packagingFirstnameHeader"
											defaultMessage="prénom"
										/>
									</Th>
									<Th>
										<FormattedMessage
											description="packagingKnotNumberHeader"
											defaultMessage="numero du noeud"
										/>
									</Th>
								</Tr>
							</Thead>
							<Tbody
								fontFamily="Nunito Sans"
								fontSize="md"
								fontWeight="400"
								lineHeight="20px"
							>
								{receivers.map((receiver, idx) => (
									<Tr key={idx} textAlign="right">
										<Td>{receiver.name}</Td>
										<Td>{receiver.firstname}</Td>
										<Td>{idx}</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</TableContainer>
					<Button
						variant="link"
						color="#17444D"
						padding="0 24px"
						size="lg"
						fontFamily="Nunito Sans"
						fontWeight="600"
						onClick={async () => {
							await save(15, 15, 170, 650)
						}}
						isLoading={isSaveLoading}
					>
						<Flex
							justifyContent="center"
							alignItems="center"
							gap="8px"
						>
							<DownloadIcon></DownloadIcon>
							<FormattedMessage
								description="packagingTableDownload"
								defaultMessage="Télécharger le tableau"
							/>
						</Flex>
					</Button>
				</Flex>
				<Flex justifyContent="center" gap="12px">
					<SecondaryButton
						as={DomLink}
						variant="outline"
						onClick={(e) => {
							e.preventDefault()
							navigate(
								`/projects/${currentProjectId}/gifts/${
									currentStoreGift!.id
								}/packaging`,
							)
						}}
					>
						<FormattedMessage
							description="generalReturn"
							defaultMessage="Retour"
						/>
					</SecondaryButton>
					<PrimaryButton
						width="fit-content"
						onClick={(e) => {
							e.preventDefault()
							navigate(`/home/projectCreated`)
						}}
					>
						<FormattedMessage
							description="generalFinish"
							defaultMessage="Terminer mon présent"
						/>
					</PrimaryButton>
				</Flex>
			</Flex>
		</Page>
	)
}
