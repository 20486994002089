import SlicesHelper from "../stores/slices/helpers/slicesHelper"
import useProjectStore from "../stores/projectStore"

import "./ExperienceSlide1.css"
import { getFormattedName } from "../domain/template"
import { useEffect } from "react"

export default function Slide1() {
	const giftClient = useProjectStore(SlicesHelper.getGiftClient)

	const layoutTitle = giftClient.openedGift?.template?.layouts.find(
		(layout) => layout.role === "TITLE",
	)

	const style = {
		backgroundColor: giftClient.openedGift?.template?.solidBackground1,
		backgroundImage:
			layoutTitle?.pictures?.find(
				(p) => p.isBackground && (p.path?.length ?? 0) > 0,
			) !== undefined
				? `url('/${getFormattedName(
						giftClient.openedGift?.template,
				  )}_slide1.png')`
				: undefined,
	}

	return (
		<main className={`container-1`} style={style}>
			<h1>{layoutTitle?.texts?.find((t) => t.position === 1)?.text}</h1>
			<h2>{layoutTitle?.texts.find((t) => t.position === 2)?.text}</h2>
		</main>
	)
}
