import React from "react"
import { Image, ImageProps } from "@chakra-ui/react"

export default function Sm2Image({
	...imgProps
}: ImageProps): React.JSX.Element {
	return (
		<Image
			px="0px"
			ml="130px"
			position="relative"
			top="-15%"
			{...imgProps}
		/>
	)
}
