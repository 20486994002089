import { BoxProps, Heading } from "@chakra-ui/react"
import React from "react"

export type TitleHeadingProps<ElementType extends HTMLElement> = BoxProps & {
	text: string
	typedIndex: number
	onClicked: ((event: React.MouseEvent<ElementType>) => void) | undefined
}

export function TitleHeading({
	typedIndex,
	onClicked,
	text,
	...boxProps
}: TitleHeadingProps<HTMLElement>): React.JSX.Element {
	return (
		<Heading
			as="h1"
			key={`text-${typedIndex}`}
			onClick={onClicked}
			{...boxProps}
		>
			{text}
		</Heading>
	)
}
