export type LayoutItemType = "TEXT" | "PICTURE"

export interface UpdateLayoutItemCommand {
	projectId: number
	giftId: number
	layoutId: number
	itemType: LayoutItemType
	layoutItemId: number
	value: string
}

export class UpdateLayoutItemCommandBuilder {
	private _command: UpdateLayoutItemCommand = {} as UpdateLayoutItemCommand

	withProjectId(projectId: number): UpdateLayoutItemCommandBuilder {
		this._command = { ...this._command, projectId }
		return this
	}

	withGiftId(giftId: number): UpdateLayoutItemCommandBuilder {
		this._command = { ...this._command, giftId }
		return this
	}

	withLayoutId(layoutId: number): UpdateLayoutItemCommandBuilder {
		this._command = { ...this._command, layoutId }
		return this
	}

	withItemType(itemType: LayoutItemType): UpdateLayoutItemCommandBuilder {
		this._command = { ...this._command, itemType }
		return this
	}

	withLayoutItemId(layoutItemId: number): UpdateLayoutItemCommandBuilder {
		this._command = { ...this._command, layoutItemId }
		return this
	}

	withValue(value: string): UpdateLayoutItemCommandBuilder {
		this._command = { ...this._command, value }
		return this
	}

	build(): UpdateLayoutItemCommand {
		if (Object.values(this._command).some((v) => v == null)) {
			throw new Error(
				`invalid command missing some fields: ${Object.keys(
					this._command,
				)
					.filter(
						(k) =>
							this._command[k as keyof UpdateLayoutItemCommand] ==
							null,
					)
					.join(",")}`,
			)
		}

		return this._command
	}
}
