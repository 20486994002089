import { Heading } from "@chakra-ui/react"
import React from "react"
import { TitleHeadingProps } from "./TitleHeading"

export function SmallTitleHeading({
	typedIndex,
	onClicked,
	text,
	...boxProps
}: TitleHeadingProps<HTMLElement>): React.JSX.Element {
	return (
		<Heading
			as="h2"
			key={`text-${typedIndex}`}
			onClick={onClicked}
			{...boxProps}
		>
			{text}
		</Heading>
	)
}
