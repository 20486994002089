import { useGiftStoreDependencies } from "../components/hooks/useGiftStoreDependencies"
import { useMemo } from "react"
import { orderLayoutsByRole } from "../domain/layout"
import TemplateClient from "../domain/adapters/secondary/templateClient"

export default function useOrderLayoutsUseCase(templateClient: TemplateClient) {
	const { currentStoreGift } = useGiftStoreDependencies()

	const currentStoreTemplateId = templateClient.currentTemplateId
	const currentTemplateId =
		currentStoreGift?.template?.id ?? currentStoreTemplateId

	const currentTemplate = templateClient.templates.find(
		(template) => template.id === currentTemplateId,
	)

	const layouts = currentStoreGift?.template?.layouts

	if (currentStoreGift === undefined) {
		throw new Error("missing current gift!")
	}
	if (currentTemplateId === -1 || currentTemplate === undefined) {
		throw new Error("invalid template selected!")
	}
	if (layouts === undefined) {
		throw new Error(
			`cannot find layouts for template ${currentTemplateId}!`,
		)
	}

	const orderedLayouts = useMemo(() => orderLayoutsByRole(layouts), [layouts])

	return {
		// layout
		orderedLayouts,

		// navigation
		currentStoreGift,
		currentTemplate,
	}
}
