import ProjectBreadcrumb, { Step } from "../components/ProjectBreadcrumb"
import { getSteps } from "./breadcrumbStepsHelper"
import Header2 from "../components/Header2"
import { FormattedMessage } from "react-intl"
import React, { useEffect } from "react"
import { Grid, GridItem, useDisclosure } from "@chakra-ui/react"
import TemplatePreview from "../components/TemplatePreview"
import useProjectStore from "../stores/projectStore"
import Page from "../components/Page"

import "./HideMainNavbar.css"
import ConfirmTemplate from "../components/ConfirmTemplate"
import SlicesHelper from "../stores/slices/helpers/slicesHelper"
import useChangeTemplateUseCase from "../useCases/changeTemplateUseCase"

export default function TemplateSelection(): React.JSX.Element {
	const templateClient = useProjectStore(SlicesHelper.getTemplateClient)
	const {
		// template
		templates,
		templatesLoadingError,
		selectTemplate,
		loadTemplates,
		loadLayoutPacks,
		currentStoreTemplateId,

		// navigation
		currentProjectId,
		currentStoreGift,
	} = useChangeTemplateUseCase(templateClient)

	const currentTemplateId =
		currentStoreGift?.template?.id ?? -1 ?? currentStoreTemplateId

	const {
		isOpen: isConfirmTemplateOpen,
		onOpen: onConfirmTemplateOpen,
		onClose: onCloseConfirmTemplate,
	} = useDisclosure()

	useEffect(() => {
		loadTemplates().then(loadLayoutPacks)
	}, [loadTemplates, loadLayoutPacks])

	const formatClassName = (name: string) => {
		return name.replaceAll(" ", "_").replaceAll("'", "").replaceAll("`", "")
	}

	const steps: Step[] = getSteps("Édition", currentStoreGift?.reachedStep)
	return (
		<Page>
			<style>{`.selected-template { border: 1px solid #2E7B72; }`}</style>
			<ProjectBreadcrumb
				steps={steps}
				pb="63.5px"
				justifyContent="center"
				projectId={currentProjectId.toString()}
				giftId={currentStoreGift?.id?.toString()}
			/>
			<Header2
				alignSelf="center"
				textAlign="center"
				mb="24px"
				fontSize={[20, 22, 24, 28]}
			>
				<FormattedMessage
					description="templateSelectionTitleLabel"
					defaultMessage="Sélection du modèle"
				/>
			</Header2>
			<Grid
				w="full"
				h="full"
				bgColor="var(--var-page-bg)"
				templateColumns="repeat(2, min-content)"
				gridGap="24px 35px"
				margin="0px auto"
				width={"fit-content"}
			>
				{templates.map((template, idx) => (
					<GridItem key={idx}>
						<TemplatePreview
							template={template}
							className={`template-preview ${formatClassName(
								template.name.toLocaleLowerCase(),
							)} ${
								template.id === currentTemplateId
									? "selected-template"
									: ""
							}`}
							onClick={async () => {
								await selectTemplate(template.id)
								onConfirmTemplateOpen()
							}}
						/>
					</GridItem>
				))}
				{templatesLoadingError && (
					<GridItem colSpan={2} color="red.500">
						{templatesLoadingError}
					</GridItem>
				)}
			</Grid>
			<ConfirmTemplate
				isOpen={isConfirmTemplateOpen}
				onClose={onCloseConfirmTemplate}
			/>
		</Page>
	)
}
