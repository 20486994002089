import OccasionClient from "../../../domain/adapters/secondary/occasionClient"
import { fetchApi } from "../../storeHelper"
import OccasionsByCategory from "../../../models/occasionsByCategory"
import { StateCreator } from "zustand"
import { ProjectStoreState } from "../../projectStore"

export const createOccasionSlice: StateCreator<
	ProjectStoreState,
	[["zustand/immer", never]],
	[],
	OccasionClient
> = (set, get, store) => ({
	occasionsByCategory: { occasions: { categories: {} } },

	getOccasions: async () => {
		const data = await fetchApi<OccasionsByCategory>("occasions", "GET")
		const newOccasions = Object.assign({}, data)
		set((state) => {
			state.occasionsByCategory = newOccasions
		})
		return newOccasions
	},
})
