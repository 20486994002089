import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useNavigate } from "react-router-dom"
import ical, { ICalCalendarMethod } from "ical-generator"
import SlicesHelper from "../stores/slices/helpers/slicesHelper"
import useProjectStore from "../stores/projectStore"
import useRetrieveGiftUseCase from "../useCases/retrieveGiftUseCase"

import "./ExperienceOpeningDate.css"

export default function ExperienceOpeningDate(): React.ReactElement {
	const [remaining, setRemaining] = useState(0)
	const giftClient = useProjectStore(SlicesHelper.getGiftClient)
	const projectClient = useProjectStore(SlicesHelper.getProjectClient)
	const { projectId, giftId, nSub } = useRetrieveGiftUseCase(
		giftClient,
		projectClient,
	)
	const navigate = useNavigate()

	function onReminderButtonClick(): void {
		if (!giftClient?.openedGift?.openingDate) return

		const calendar = ical({ name: "Un cadeau t’attend !" })
		const giftUrl = `https://${window.location.hostname}/#/projects/${projectId}/gifts/${giftId}/loading?nSub=${nSub}`
		calendar.method(ICalCalendarMethod.REQUEST)
		calendar.createEvent({
			allDay: true,
			start: new Date(giftClient.openedGift.openingDate),
			summary: "Un cadeau Présent t’attend !",
			description: giftUrl,
			url: `/projects/${projectId}/gifts/${giftId}/loading?nSub=${nSub}`,
		})

		window.open("data:text/calendar;charset=utf8," + calendar.toString())
	}

	function leaveOpeningDate(): void {
		if (
			giftClient?.openedGift?.openingGame &&
			!projectClient.isOpeningGamePassed
		) {
			navigate(
				`/projects/${projectId}/gifts/${giftId}/loading/game/?nSub=${nSub}`,
			)
		} else {
			navigate(
				`/projects/${projectId}/gifts/${giftId}/loading/swipe/?nSub=${nSub}`,
			)
		}
	}

	function formatNumberToReadable(x: number): string {
		const floor = Math.floor(x)
		return floor < 10 ? `0${floor}` : `${floor}`
	}

	function updateTimer(openingDate: Date): void {
		const remaining = openingDate.getTime() - new Date().getTime()
		if (remaining <= 0) {
			leaveOpeningDate()
		}
		setRemaining(remaining)
	}

	useEffect(() => {
		if (!giftClient?.openedGift?.openingDate) {
			leaveOpeningDate()
			return
		}
		const openingDate = new Date(giftClient.openedGift.openingDate)
		updateTimer(openingDate)
		const timeUpdateInterval = setInterval(() => {
			updateTimer(openingDate)
		}, 1000)
		return () => {
			clearInterval(timeUpdateInterval)
		}
	})

	return (
		<main
			className={`container-date`}
			style={{
				backgroundColor:
					giftClient.openedGift?.template?.solidBackground3,
			}}
		>
			<section>
				{Math.floor(remaining / (1000 * 60 * 60 * 24)) > 0 && (
					<article>
						<h2>
							{formatNumberToReadable(
								remaining / (1000 * 60 * 60 * 24),
							)}
						</h2>
						<h3>Jours</h3>
					</article>
				)}
				<article>
					<h2>
						{formatNumberToReadable(
							(remaining % (1000 * 60 * 60 * 24)) /
								(1000 * 60 * 60),
						)}
					</h2>
					<h3>Heures</h3>
				</article>
				<article>
					<h2>
						{formatNumberToReadable(
							(remaining % (1000 * 60 * 60)) / (1000 * 60),
						)}
					</h2>
					<h3>Minutes</h3>
				</article>
				{Math.floor(remaining / (1000 * 60 * 60 * 24)) <= 0 && (
					<article>
						<h2>
							{formatNumberToReadable(
								(remaining % (1000 * 60)) / 1000,
							)}
						</h2>
						<h3>Secondes</h3>
					</article>
				)}
			</section>
			<p>
				<FormattedMessage
					description="experienceOpeningDateMessage1"
					defaultMessage="Ce présent ne peut pas encore être ouvert. Rendez-vous le "
				/>
				{giftClient?.openedGift?.openingDate && (
					<strong>{` ${new Date(
						giftClient.openedGift.openingDate,
					).toLocaleDateString(undefined, {
						dateStyle: "full",
					})} `}</strong>
				)}
				<FormattedMessage
					description="experienceOpeningDateMessage2"
					defaultMessage="à"
				/>
				{giftClient?.openedGift?.openingDate && (
					<strong>{` ${new Date(
						giftClient.openedGift.openingDate,
					).toLocaleTimeString(undefined, {
						timeStyle: "short",
					})} `}</strong>
				)}
				<FormattedMessage
					description="experienceOpeningDateMessage3"
					defaultMessage="pour découvrir ce qu'il renferme"
				/>
			</p>
			<footer>
				<button
					type={"button"}
					className={"btn-primary"}
					onClick={onReminderButtonClick}
					style={{
						backgroundColor:
							giftClient.openedGift?.template
								?.callToActionBackgroundColor,
					}}
				>
					<FormattedMessage
						description="experienceOpeningDateAddReminder"
						defaultMessage="Ajouter un rappel"
					/>
				</button>
				<img src={"/present_noir.svg"} alt={"Logo Présent"} />
			</footer>
		</main>
	)
}
