import { Picture } from "./picture"
import { Text } from "./text"
import Gift from "./gift"

export type LayoutRole =
	| "INTRODUCTION"
	| "TITLE"
	| "MESSAGE"
	| "PREVIEW"
	| "ANNONCE"
export type LayoutKind = "REAL" | "TEMPLATE"

export interface Layout {
	id: number
	name: string
	role: LayoutRole
	kind: LayoutKind
	textCount: number
	pictureCount: number
	texts: Text[]
	pictures: Picture[]
	cssPath: string
	usesDarkMode: boolean

	createdAt: Date
	updatedAt: Date
}

export type RenderItemType = "text" | "picture"

export type RenderItem = (Text | Picture) & {
	type: RenderItemType
	typeIndex: number
	isEditable: boolean
}

export function getPreviewRenderItems(gift: Gift): RenderItem[] {
	const title: Text | RenderItem = {
		id: -99,
		text:
			gift.title ?? "Un grand merci pour cette année haute en couleurs.",
		level: "TITLE",
		position: 1,
		type: "text",
		typeIndex: 0,
		isEditable: true,
	}

	const description: Text | RenderItem = {
		id: -98,
		text: gift.description ?? "",
		level: "PARAGRAPH",
		position: 3,
		type: "text",
		typeIndex: 1,
		isEditable: true,
	}

	const picture: Picture | RenderItem = {
		id: -97,
		path:
			gift.picturePath ??
			"public_assets/-1/pictures/-1/annonce_preview_img1.png",
		containerStyle: "style1",
		imgSize: "xl",
		containerSize: "xl",
		isBackground: true,
		position: 2,
		type: "picture",
		typeIndex: 0,
		isEditable: true,
	}

	return [title, picture, description].filter((item) =>
		gift.title === "" ? item.type !== "text" && item.typeIndex !== 1 : true,
	)
}

export function getRenderItems(layout: Layout): RenderItem[] {
	const cntByType: Record<string, number> = {
		text: 0,
		picture: 0,
	}

	return [
		...layout.texts.map((text) => {
			return {
				...text,
				type: "text" as RenderItemType,
				isEditable: false,
			}
		}),
		...layout.pictures.map((pict) => {
			return {
				...pict,
				type: "picture" as RenderItemType,
				isEditable: false,
			}
		}),
	]
		.sort((first, second) => (first.position ?? 1) - (second.position ?? 1))
		.map((item) => ({
			...item,
			typeIndex: cntByType[item.type]++,
		}))
}

export function orderLayoutsByRole(layouts: Layout[]) {
	const orderedRoles: LayoutRole[] = [
		"INTRODUCTION",
		"TITLE",
		"MESSAGE",
		"PREVIEW",
	]

	const byRoleOrderAsc = (layout1: Layout, layout2: Layout) =>
		orderedRoles.indexOf(layout1.role) - orderedRoles.indexOf(layout2.role)

	return [...layouts].sort(byRoleOrderAsc)
}
