import { useCallback, useState } from "react"
import { ProjectWithKey } from "../stores/inMemory/inMemoryProjectStore"
import ProjectClient from "../domain/adapters/secondary/projectClient"

export default function useLoadProjectsUseCase(projectClient: ProjectClient) {
	const [projects, setProjects] = useState<ProjectWithKey[]>([])
	const [loadProjectsStatus, setLoadProjectsStatus] = useState<
		"OK" | "KO" | null
	>(null)

	const retrieveProjects = projectClient.getAll
	const loadProjects = useCallback(async () => {
		try {
			const projects = await retrieveProjects()
			setProjects(projects.map((proj) => ({ ...proj, key: proj.id! })))
			setLoadProjectsStatus("OK")
		} catch {
			setLoadProjectsStatus("KO")
		}
	}, [retrieveProjects])

	const resetLoadProjectsStatus = useCallback(() => {
		setLoadProjectsStatus(null)
	}, [])

	const projectSelected = projectClient.projectSelected
	const resetCurrentProject = useCallback(() => {
		projectSelected(-1)
	}, [projectSelected])

	return {
		// projects
		projects,
		loadProjectsStatus,
		loadProjects,
		resetCurrentProject,
		resetLoadProjectsStatus,
	}
}
