import { GiftInfos } from "../stores/projectStore"
import Gift from "../domain/gift"
import { Receiver } from "../domain/receiver"

export class GiftInfosHelper {
	static pickGiftInfos(g: GiftInfos) {
		return {
			id: g.id,
			description: g.description,
			title: g.title,
			path: g.link,
			picture: g.picture,
		}
	}

	static updateProperty(
		gift: GiftInfos,
		property: keyof GiftInfos,
		newValue: string | Blob | Receiver[],
	): GiftInfos {
		return Object.assign(gift, {
			...GiftInfosHelper.pickGiftInfos(gift!),
			[property]: newValue,
		})
	}

	static makeGiftInfos(gift: Gift): GiftInfos {
		return Object.assign({}, gift, {
			picturePath: undefined,
			pdf: undefined,
			picture: undefined,
			fileName: undefined,
			pictureFileName: undefined,
			pdfFileName: undefined,
		})
	}
}
