import React from "react"
import Page from "../components/Page"
import { Flex, Heading } from "@chakra-ui/react"
import useProjectStore from "../stores/projectStore"
import { FormattedMessage } from "react-intl"
import { Link as DomLink, useNavigate } from "react-router-dom"
import PrimaryButton from "../components/PrimaryButton"
import SecondaryButton from "../components/SecondaryButton"
import ProjectBreadcrumb, { Step } from "../components/ProjectBreadcrumb"
import { getSteps } from "./breadcrumbStepsHelper"
import EditLayoutsStack from "../components/EditLayoutStack"
import ImageEditorPopup from "../components/ImageEditorPopup"
import { ImageEditorProvider } from "../components/contexts/imageEditorContext"

import "./HideMainNavbar.css"
import SlicesHelper from "../stores/slices/helpers/slicesHelper"
import useOrderLayoutsUseCase from "../useCases/orderLayoutsUseCase"

export default function EditMessageLayoutPage(): React.JSX.Element {
	const navigate = useNavigate()

	const templateClient = useProjectStore(SlicesHelper.getTemplateClient)
	const {
		// layout
		orderedLayouts,

		// navigation
		currentStoreGift,
		currentTemplate,
	} = useOrderLayoutsUseCase(templateClient)

	const steps: Step[] = getSteps("Édition", currentStoreGift?.reachedStep)

	return (
		<Page gap="24px">
			<ProjectBreadcrumb
				steps={steps}
				pb="63.5px"
				justifyContent="center"
				projectId={currentStoreGift!.project!.id!.toString()}
				giftId={currentStoreGift?.id?.toString()}
			/>

			<Heading
				as={"h2"}
				size="normal"
				fontWeight="normal"
				alignSelf="center"
			>
				<FormattedMessage
					description="EditMessageLayoutPageTitle"
					defaultMessage="Appuyez sur une zone de texte ou d’image pour la modifier"
				/>
			</Heading>

			<ImageEditorProvider>
				<EditLayoutsStack
					template={currentTemplate}
					layouts={orderedLayouts}
					sender={currentStoreGift.sender!}
					excludedRole="PREVIEW"
					cardWidth="320px"
				/>

				<Flex direction="column">
					<ImageEditorPopup />
				</Flex>
			</ImageEditorProvider>

			<Flex
				w="full"
				justifyContent="center"
				gap="24px"
				mt="80px"
				mb="24px"
				className="navBar"
			>
				<SecondaryButton
					as={DomLink}
					variant="outline"
					onClick={(e) => {
						e.preventDefault()
						navigate(
							`/projects/${currentStoreGift?.project
								?.id!}/gifts/${currentStoreGift!.id}/template/${
								currentStoreGift!.template!.id
							}/layout`,
						)
					}}
				>
					<FormattedMessage
						description="generalReturn"
						defaultMessage="Retour"
					/>
				</SecondaryButton>
				<PrimaryButton
					mx="12px"
					onClick={async (
						e: React.MouseEvent<HTMLButtonElement>,
					): Promise<void> => {
						e.preventDefault()
						navigate(
							`/projects/${currentStoreGift?.project
								?.id!}/gifts/${currentStoreGift?.id!}/openingMode`,
						)
					}}
				>
					<FormattedMessage
						description="generalNext"
						defaultMessage="Suivant"
					/>
				</PrimaryButton>
			</Flex>
		</Page>
	)
}
