import { useState } from "react"
import { FormattedMessage } from "react-intl"
import SlicesHelper from "../stores/slices/helpers/slicesHelper"
import useProjectStore from "../stores/projectStore"
import { Drawer } from "./ExperienceDrawer"

import "./ExperienceGifts.css"

export default function Gifts() {
	const [showDrawer, setShowDrawer] = useState(false)

	const giftClient = useProjectStore(SlicesHelper.getGiftClient)

	return (
		<>
			<main
				className="container-4"
				onClick={() => {
					setShowDrawer(true)
				}}
				style={{
					backgroundColor:
						giftClient.openedGift?.template?.solidBackground3,
				}}
			>
				<h1>{giftClient.openedGift?.title}</h1>
				<h2>{giftClient.openedGift?.description}</h2>
				<div className={"img-container-1"}>
					<img
						alt="Cadeau"
						src={`${process.env.REACT_APP_API_URL}/${giftClient.openedGift?.picturePath}`}
					/>
				</div>
				<p>
					<FormattedMessage
						description="giftsMessage"
						defaultMessage="Je tenais à t'envoyer un immense merci pour cette année de folie
					que nous avons vécue ensemble."
					/>
				</p>
				<Drawer isOpen={showDrawer} />
			</main>
		</>
	)
}
