import React, { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import ProjectBreadcrumb, { Step } from "../components/ProjectBreadcrumb"
import {
	BoxProps,
	Flex,
	FormControl,
	FormErrorMessage,
	Input,
} from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import Header2 from "../components/Header2"
import SmallText from "../components/SmallText"
import { useIntl } from "react-intl"

import { Link as DomLink } from "react-router-dom"

import "./CreateProject.css"
import SecondaryButton from "../components/SecondaryButton"
import PrimaryButton from "../components/PrimaryButton"
import Page from "../components/Page"
import { getSteps } from "./breadcrumbStepsHelper"
import PresentAlert from "../components/PresentAlert"

import "./HideMainNavbar.css"
import useNameProjectUseCase from "../useCases/nameProjectUseCase"
import ProjectClient from "../domain/adapters/secondary/projectClient"
import TemplateClient from "../domain/adapters/secondary/templateClient"
import SlicesHelper from "../stores/slices/helpers/slicesHelper"
import useOpenProjectUseCase from "../useCases/openProjectUseCase"
import useProjectStore from "../stores/projectStore"
export default function CreateProject(_: BoxProps): React.JSX.Element {
	const intl = useIntl()
	const [urlSearchParams] = useSearchParams()

	const openedProjectId: number | undefined = Number(
		urlSearchParams.get("id"),
	)

	const projectClient: ProjectClient = useProjectStore(
		SlicesHelper.getProjectClient,
	)

	const templateClient: TemplateClient = useProjectStore(
		SlicesHelper.getTemplateClient,
	)

	const { currentProject, openProjectError, openProject } =
		useOpenProjectUseCase(projectClient, templateClient)

	const {
		isProjectChanging,
		projectName,
		createProjectError,
		isUniqueProjectName,
		isValidProjectName,
		isValid,

		createProject,
		projectNameChanged,
		renameProject,
	} = useNameProjectUseCase(projectClient)

	useEffect(() => {
		if (
			Number.isNaN(openedProjectId) ||
			openedProjectId === undefined ||
			openedProjectId <= 0
		) {
			return
		}

		openProject(openedProjectId)
	}, [openProject, openedProjectId])

	if (openProjectError !== null) {
		console.warn(openProjectError)
	}

	const navigate = useNavigate()

	const steps: Step[] = getSteps(
		"Cadeaux",
		currentProject?.gifts[0]?.reachedStep,
	)

	return (
		<Page>
			<ProjectBreadcrumb
				steps={steps}
				pb="63.5px"
				justifyContent="center"
				projectId={(currentProject?.id ?? openedProjectId).toString()}
			/>
			<Header2 alignSelf="center" mb="4px">
				<FormattedMessage
					description="createProjectNameLabel"
					defaultMessage="Saisissez un nom"
				/>
			</Header2>
			<SmallText mb="24px">
				<FormattedMessage
					description="createProjectNameSubtitle"
					defaultMessage="Donnez un nom à votre projet pour pouvoir le retrouver plus tard"
				/>
			</SmallText>
			<FormControl
				isRequired
				w="554px"
				alignSelf="center"
				bgColor="rgba(57, 119, 121, 0.04)"
				borderRadius="6px"
				className={!isValid ? "invactiveFc" : "activeFc"}
				isInvalid={!isValid}
			>
				<Input
					size="lg"
					className={!isValid ? "inactiveInput" : "activeInput"}
					maxLength={50}
					placeholder={intl.formatMessage({
						description: "createProjectNamePlaceHolder",
						defaultMessage: "Nom du projet (50 caractères max)",
					})}
					fontWeight="600"
					color="var(--var-text-color)"
					onChange={(e) => projectNameChanged(e.target.value)}
					borderRadius="6px"
					value={
						projectName.length > 0
							? projectName
							: currentProject?.name ?? ""
					}
				/>
				{!isUniqueProjectName ? (
					<FormErrorMessage>
						<FormattedMessage
							description="createProjectDuplicatedNameErrorMessage"
							defaultMessage="Ce nom de projet existe déjà"
						/>
					</FormErrorMessage>
				) : (
					<></>
				)}
				{!isValidProjectName ? (
					<FormErrorMessage>
						<FormattedMessage
							description="createProjectNameErrorMessage"
							defaultMessage={
								"Le nom du projet doit faire entre 3 et 50 caractères inclus." +
								" Les seuls caractères spéciaux autorisés sont '-', '_' et les lettres accentuées"
							}
						/>
					</FormErrorMessage>
				) : (
					<></>
				)}
			</FormControl>
			<Flex
				w="full"
				justifyContent="center"
				gap="24px"
				mt="48px"
				mb="24px"
			>
				<SecondaryButton
					as={DomLink}
					onClick={(e) => {
						e.preventDefault()
						navigate("/home")
					}}
				>
					<FormattedMessage
						description="generalCancel"
						defaultMessage="Annuler"
					/>
				</SecondaryButton>
				<PrimaryButton
					isDisabled={!isValid}
					isLoading={isProjectChanging}
					onClick={async (
						e: React.MouseEvent<HTMLButtonElement>,
					): Promise<void> => {
						e.preventDefault()

						if (currentProject?.name === projectName) {
							navigate(
								`/projects/${currentProject?.id!}/gifts/creation`,
							)
							return
						}

						const hasLoadedProject = (currentProject?.id ?? 0) > 0
						if (hasLoadedProject) {
							await renameProject(currentProject?.id!)
							navigate(
								`/projects/${currentProject?.id!}/gifts/creation`,
							)
							return
						}
						const newProjectId = await createProject()
						navigate(`/projects/${newProjectId!}/gifts/creation`)
					}}
				>
					<FormattedMessage
						description="generalNext"
						defaultMessage="Suivant"
					/>
				</PrimaryButton>
			</Flex>
			{createProjectError !== null && (
				<PresentAlert
					status={"error"}
					title={intl.formatMessage({
						description: "createProjectServerErrorTitle",
						defaultMessage: "Erreur",
					})}
					description={createProjectError}
				/>
			)}
		</Page>
	)
}
