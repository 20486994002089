import { useCallback, useState } from "react"
import ProjectClient from "../domain/adapters/secondary/projectClient"
import { LinkType } from "../components/LinkType"
import { CustomLinkIcon, MessageIcon, PdfIcon } from "../views/CreateGiftIcons"
import { useIntl } from "react-intl"
import { GiftType } from "../domain/gift"
import GiftClient from "../domain/adapters/secondary/giftClient"

export default function useChangeLinkTypeUseCase(
	projectClient: ProjectClient,
	giftClient: GiftClient,
) {
	const intl = useIntl()

	const currentProject = projectClient.projects.find(
		(proj) => proj.id === projectClient.currentProjectId,
	)
	const hasCurrentProject = currentProject !== undefined
	const hasGifts = (currentProject?.gifts?.length ?? 0) > 0
	const currentGift =
		currentProject && hasGifts ? currentProject.gifts[0] : undefined
	const initialSelectedLinkType =
		hasCurrentProject && hasGifts ? currentProject!.gifts[0].type : "NONE"

	const initialLinkTypes: LinkType[] = [
		{
			id: 1,
			type: "LINK" as GiftType,
			icon: CustomLinkIcon,
			title: intl.formatMessage({
				description: "createGiftLinkTypeLinkTitle",
				defaultMessage: "Bon cadeau - Lien",
			}),
			smallText: intl.formatMessage({
				description: "createGiftLinkTypeLinkSubtitle",
				defaultMessage:
					"Joindre une URL comprenant votre cadeau: url web, youtube, viméo",
			}),
		},
		{
			id: 2,
			type: "PDF" as GiftType,
			icon: PdfIcon,
			title: intl.formatMessage({
				description: "createGiftLinkTypePdfTitle",
				defaultMessage: "Bon cadeau - PDF",
			}),
			smallText: intl.formatMessage({
				description: "createGiftLinkTypePdfSubtitle",
				defaultMessage:
					"Joindre un e-book ou tout document téléchargeable",
			}),
		},
		{
			id: 3,
			type: "MSG" as GiftType,
			icon: MessageIcon,
			title: intl.formatMessage({
				description: "createGiftLinkTypeMessageTitle",
				defaultMessage: "Message",
			}),
			smallText: intl.formatMessage({
				description: "createGiftLinkTypeMessageSubtitle",
				defaultMessage:
					"Parce que les mots peuvent êtres les plus beaux des cadeaux",
			}),
		},
	].map((a) => ({ ...a, isSelected: initialSelectedLinkType === a.type }))

	const [selectedLinkType, setSelectedLinkType] = useState(
		initialSelectedLinkType,
	)

	const [linkTypes, setLinkTypes] = useState(initialLinkTypes)
	const [updateError, setUpdateError] = useState<string | null>(null)
	const [isValidSelection, setIsValidSelection] = useState(
		initialSelectedLinkType !== "NONE",
	)

	const linkTypeSelected = useCallback(
		async (lt: LinkType) => {
			try {
				const hasSelectedLinkType =
					linkTypes.length > lt.id - 1 && !lt.isSelected
				const selectedLinkType = hasSelectedLinkType
					? linkTypes[lt.id - 1].type
					: "NONE"
				const shouldUpdate =
					hasSelectedLinkType &&
					(currentProject?.gifts?.length ?? 0) > 0
				setSelectedLinkType(selectedLinkType)
				setIsValidSelection(selectedLinkType !== "NONE")
				setLinkTypes(
					initialLinkTypes.map((lnkType) => ({
						...lnkType,
						isSelected: !lt.isSelected && lnkType.id === lt.id,
					})),
				)

				if (!shouldUpdate) {
					await giftClient.addGift(projectClient.currentProjectId, {
						type: lt.type,
					})
				} else if (
					currentProject != null &&
					currentProject.gifts[0].type !== selectedLinkType
				) {
					await giftClient.updateGift(
						projectClient.currentProjectId,
						currentProject.gifts[0].id!,
						{ type: lt.type },
					)
				}
				setUpdateError(null)
			} catch (err) {
				setSelectedLinkType("NONE")
				setLinkTypes(
					initialLinkTypes.map((lnkType) => ({
						...lnkType,
						isSelected: false,
					})),
				)
				setUpdateError(
					intl.formatMessage({
						description: "createLinkServerErrorDesc",
						defaultMessage: "une erreur est survenue",
					}),
				)
			}
		},
		[
			currentProject,
			giftClient,
			initialLinkTypes,
			intl,
			linkTypes,
			projectClient.currentProjectId,
		],
	)

	return {
		currentProject,
		currentGift,
		linkTypes,
		selectedLinkType,
		isValidSelection,
		updateError,

		linkTypeSelected,
	}
}
