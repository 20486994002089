import useProjectStore from "../stores/projectStore"
import { useCallback, useState } from "react"
import { OpeningMode } from "../domain/gift"
import GiftClient from "../domain/adapters/secondary/giftClient"
import OpeningGame from "../domain/openingGame"

export default function useChangeOpeningModeUseCase(giftClient: GiftClient) {
	const currentProjectId = useProjectStore((state) => state.currentProjectId)
	const currentStoreGift = useProjectStore(
		(state) =>
			state.projects.find((proj) => proj.id === currentProjectId)
				?.gifts[0],
	)
	const updateGiftOpeningMode = giftClient.updateGiftOpeningMode

	const [selectedMode, setSelectedMode] = useState(
		currentStoreGift?.openingMode,
	)

	const openingModeUpdated = useCallback(
		async (
			projectId: number,
			giftId: number,
			openingMode: OpeningMode,
			openingDate?: Date | undefined,
			openingGame?: OpeningGame | undefined,
		) => {
			setSelectedMode(openingMode)

			return await updateGiftOpeningMode(
				projectId,
				giftId,
				openingMode,
				openingDate,
				openingGame,
			)
		},
		[updateGiftOpeningMode],
	)

	const modesByOpeningMode: Record<OpeningMode | "GAME_AND_DATE", string[]> =
		{
			DATE: ["DATE"],
			GAME: ["GAME"],
			GAME_AND_DATE: ["GAME", "DATE"],
			NONE: [],
		}

	if (currentStoreGift?.id === undefined) {
		throw new Error("Missing gift")
	}

	return {
		// openingMode
		selectedMode,
		openingModeUpdated,
		modesByOpeningMode,

		// navigation
		currentProjectId,
		currentStoreGift,
	}
}
