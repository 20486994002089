import React from "react"
import ProjectBreadcrumb, { Step } from "../components/ProjectBreadcrumb"
import Header2 from "../components/Header2"
import { FormattedMessage } from "react-intl"
import SmallText from "../components/SmallText"
import Page from "../components/Page"
import {
	Alert,
	AlertDescription,
	AlertIcon,
	Box,
	CloseButton,
	Flex,
	Link,
	useDisclosure,
} from "@chakra-ui/react"
import SecondaryButton from "../components/SecondaryButton"
import { Link as DomLink, useNavigate } from "react-router-dom"
import PrimaryButton from "../components/PrimaryButton"
import { getSteps } from "./breadcrumbStepsHelper"
import { CsvFileUpload } from "../components/CsvFileUpload"

import "./HideMainNavbar.css"
import useProjectStore from "../stores/projectStore"
import useChangeReceiversUseCase from "../useCases/changeReceiversUseCase"
import SlicesHelper from "../stores/slices/helpers/slicesHelper"

export default function Receivers(): React.JSX.Element {
	const navigate = useNavigate()

	const giftClient = useProjectStore(SlicesHelper.getGiftClient)

	const {
		// csv upload
		csvFileName,
		csvChooseFileLabel,
		csvFileList,
		isCsvLoading,
		isCsvSuccess,
		maxCsvFileWeightInMb,
		updateIsCsvSuccess,
		updateIsCsvLoading,
		handleCsvPreview,
		handleCsvFormatValidation,
		handleCsvFileChange,
		// validation
		csvError,
		// navigation
		currentProjectId,
		currentStoreGift,
		// open project mode
		hasReceivers,
	} = useChangeReceiversUseCase(giftClient)

	const { isOpen: isWarnVisible, onClose: OnCloseWarn } = useDisclosure({
		defaultIsOpen: true,
	})

	const steps: Step[] = getSteps("Évènement", currentStoreGift?.reachedStep)

	return (
		<Page>
			<ProjectBreadcrumb
				steps={steps}
				pb="63.5px"
				justifyContent="center"
				projectId={currentProjectId.toString()}
				giftId={currentStoreGift?.id?.toString()}
			/>
			<Header2 alignSelf="center" mb="4px">
				<FormattedMessage
					description="receiversTitleLabel"
					defaultMessage="À qui est destiné ce cadeau ?"
				/>
			</Header2>
			<SmallText mb="24px">
				<FormattedMessage
					description="receiversSubtitle"
					defaultMessage="Importez votre liste de destinataires avec le bon template"
				/>
			</SmallText>

			{hasReceivers && isWarnVisible && (
				<Alert
					status={"warning"}
					w={"auto"}
					mb={"24px"}
					alignSelf={"center"}
				>
					<AlertIcon />
					<Box>
						<AlertDescription>
							<FormattedMessage
								description="receiversAlreadyHasReceivers"
								defaultMessage="Il y a déjà des destinataires importés pour ce projet. Passez à l'écran suivant pour les visualiser."
							/>
						</AlertDescription>
					</Box>
					<CloseButton
						alignSelf="flex-start"
						position="relative"
						right={-1}
						top={-1}
						onClick={OnCloseWarn}
					/>
				</Alert>
			)}

			<CsvFileUpload
				maxCsvFileWeightInMb={maxCsvFileWeightInMb}
				setIsLoading={updateIsCsvLoading}
				setIsSuccess={updateIsCsvSuccess}
				csvChooseFileLabel={csvChooseFileLabel}
				csvError={csvError}
				csvFileList={csvFileList}
				csvFileName={csvFileName}
				handleCsvFileChange={handleCsvFileChange}
				handleCsvFormatValidation={handleCsvFormatValidation}
				handleCsvPreview={handleCsvPreview}
				fontWeight="600"
				color="var(--var-text-color)"
				bgColor="rgba(57, 119, 121, 0.04)"
				_hover={{
					borderRadius: "6px",
					border: "1.5px solid rgba(23, 68, 77, 0.50)",
					background: "rgba(57, 119, 121, 0.04)",
				}}
				_focus={{
					borderRadius: "6px",
					border: "1.5px solid rgba(23, 68, 77, 0.50)",
					background: "rgba(57, 119, 121, 0.04)",
					outline: "0px",
				}}
			/>

			<Flex
				w="full"
				justifyContent="center"
				gap="24px"
				mt="48px"
				mb="24px"
			>
				<SecondaryButton
					as={DomLink}
					variant="outline"
					onClick={(e) => {
						e.preventDefault()
						navigate(
							`/projects/${currentProjectId}/gifts/${
								currentStoreGift!.id
							}/receiversMode`,
						)
					}}
				>
					<FormattedMessage
						description="generalReturn"
						defaultMessage="Retour"
					/>
				</SecondaryButton>
				<PrimaryButton
					isDisabled={!isCsvSuccess && !hasReceivers}
					isLoading={isCsvLoading}
					onClick={(e: React.MouseEvent<HTMLButtonElement>): void => {
						e.preventDefault()

						navigate(
							`/projects/${currentProjectId}/gifts/${currentStoreGift?.id!}/receiversDetails`,
						)
					}}
				>
					<FormattedMessage
						description="generalNext"
						defaultMessage="Suivant"
					/>
				</PrimaryButton>
			</Flex>
			<Flex
				w="full"
				h="40px"
				justifyContent="center"
				gap="8px"
				borderRadius="6px"
				fontFamily="Nunito Sans"
				fontSize="sm"
				fontWeight="600"
				lineHeight="28px"
				color="var(--var-text-color)"
				mb="24px"
			>
				<Link
					href="/ReceiversTemplate.csv"
					download="Template.csv"
					target="_blank"
					rel="noreferrer"
				>
					<FormattedMessage
						description="receiversDownloadCsvTemplate"
						defaultMessage="Télécharger le template csv"
					/>
				</Link>
			</Flex>
		</Page>
	)
}
