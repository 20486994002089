import { createIcon } from "@chakra-ui/icons"
import React from "react"

export function DownloadIcon() {
	return createIcon({
		displayName: "event",
		viewBox: "0 0 23 17",
		path: [
			<svg
				width="23"
				height="17"
				viewBox="0 0 23 17"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M7.5 3H1.5V16H21.5V3H15.5"
					stroke="#6AA5FD"
					fill="transparent"
					strokeWidth="1.5"
				/>
				<path
					d="M11.5 0V12M11.5 12L6.5 7.76489M11.5 12L16.5 7.76489"
					stroke="#6AA5FD"
					fill="transparent"
					strokeWidth="1.5"
				/>
			</svg>,
		],
	})
}
