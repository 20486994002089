import React, { ReactNode } from "react"
import { Text, BoxProps } from "@chakra-ui/react"

export default function SmallText(
	props: BoxProps & { children: ReactNode },
): React.JSX.Element {
	return (
		<Text
			fontFamily="Nunito Sans"
			fontWeight="400"
			fontSize="16px"
			lineHeight="130%"
			h="21px"
			textAlign="center"
			color="#17444D"
			opacity="0.8"
			flex="none"
			{...props}
		>
			{props.children}
		</Text>
	)
}
