import FrMessages from "./Messages.fr.json"
import EnMessages from "./Messages.en.json"

export const LOCALES = {
	ENGLISH: "en-US",
	FRENCH: "fr-FR",
}

export const MESSAGES = {
	[LOCALES.ENGLISH]: EnMessages,
	[LOCALES.FRENCH]: FrMessages,
}
