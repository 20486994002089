import React from "react"
import { getRealPicturePath, getTextColor, Template } from "../domain/template"
import {
	BoxProps,
	Button,
	Flex,
	HStack,
	Text as ChakraText,
} from "@chakra-ui/react"
import { LayoutHelper } from "../helpers/layoutHelper"

export type TemplatePreviewProps = BoxProps & {
	template: Template
}

export default function TemplatePreview({
	template,
	...props
}: TemplatePreviewProps): React.JSX.Element {
	if ((template?.layouts?.length ?? 0) === 0) {
		throw new Error("missing layouts for template")
	}

	const titleLayout = template.layouts.find(
		(layout) => layout.role === "TITLE",
	)
	if (titleLayout === undefined) {
		throw new Error("missing title layout")
	}

	return (
		<Flex
			minW={[240, null, 332]}
			maxW={[240, null, 332]}
			minH={500}
			flexDir="column"
			borderRadius="12px"
			border="1.036px solid rgba(0, 0, 0, 0.10)"
			// backgroundImage={`url("${
			// 	process.env.REACT_APP_API_URL
			// }/${getRealPicturePath(template, titleLayout.usesDarkMode)}")`}
			// backgroundRepeat="no-repeat"
			// backgroundPosition="center"
			// backgroundSize="cover"
			backgroundColor={template.solidBackground2}
			alignItems="stretch"
			justifyContent="flex-end"
			p="8px 8px 8px 12px"
			{...props}
		>
			<ChakraText
				color={getTextColor(template, template.layouts[0].usesDarkMode)}
				fontFamily={LayoutHelper.getFontFamily(template.titleFont)}
				fontSize="24px"
				textAlign="center"
				lineHeight="130%"
				my="auto"
			>
				{
					template.layouts
						.find((l) => l.role === "TITLE")
						?.texts?.find((t) => t.level === "TITLE")?.text
				}
			</ChakraText>

			{/*<link*/}
			{/*	rel="stylesheet"*/}
			{/*	href={`${process.env.REACT_APP_API_URL}/${template.layouts.find(*/}
			{/*		(l) => l.role === "TITLE",*/}
			{/*	)?.cssPath}`}*/}
			{/*/>*/}

			<HStack justifyContent="space-between" p="8px, 8px, 8px, 12px">
				<ChakraText
					fontFamily="Nunito Sans"
					fontWeight={500}
					lineHeight="20.8px"
					fontSize="16px"
				>
					{template.name}
				</ChakraText>

				<Button
					background={"none"}
					w="33px"
					h="33px"
					p="8px"
					_hover={{
						outline: "none",
						background: "none",
					}}
				>
					<svg
						width="34"
						height="34"
						viewBox="0 0 34 34"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g filter="url(#filter0_b_1007_648)">
							<rect
								x="0.279297"
								y="0.279053"
								width="33.7209"
								height="33.7209"
								rx="8.43023"
								fill="black"
								fillOpacity="0.2"
							/>
							<path
								d="M23.4622 15.032V10.8169H19.2471"
								stroke="white"
								strokeWidth="1.05378"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M10.8164 19.2472V23.4623H15.0315"
								stroke="white"
								strokeWidth="1.05378"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M23.4623 10.8169L18.1934 16.0858"
								stroke="white"
								strokeWidth="1.05378"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M16.0853 18.1934L10.8164 23.4623"
								stroke="white"
								strokeWidth="1.05378"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</g>
						<defs>
							<filter
								id="filter0_b_1007_648"
								x="-3.93582"
								y="-3.93606"
								width="42.1509"
								height="42.1512"
								filterUnits="userSpaceOnUse"
								colorInterpolationFilters="sRGB"
							>
								<feFlood
									floodOpacity="0"
									result="BackgroundImageFix"
								/>
								<feGaussianBlur
									in="BackgroundImageFix"
									stdDeviation="2.10756"
								/>
								<feComposite
									in2="SourceAlpha"
									operator="in"
									result="effect1_backgroundBlur_1007_648"
								/>
								<feBlend
									mode="normal"
									in="SourceGraphic"
									in2="effect1_backgroundBlur_1007_648"
									result="shape"
								/>
							</filter>
						</defs>
					</svg>
				</Button>
			</HStack>
		</Flex>
	)
}
