import { ButtonProps, forwardRef } from "@chakra-ui/react"
import React from "react"
import PrimaryButton from "./PrimaryButton"

import "./SecondaryButton.css"
const secondaryBtn = forwardRef<ButtonProps, "button">((props, ref) => {
	return (
		<PrimaryButton
			backgroundOverride={"var(--var-btn-secondary-bg)"}
			color="var(--var-btn-secondary-color)"
			{...props}
			ref={ref}
			_hover={{
				bgColor: "gray.50",
			}}
		>
			{props.children}
		</PrimaryButton>
	)
})

export default secondaryBtn
