import { ProjectStoreState } from "../../projectStore"
import ProjectClient from "../../../domain/adapters/secondary/projectClient"
import TemplateClient from "../../../domain/adapters/secondary/templateClient"
import GiftClient from "../../../domain/adapters/secondary/giftClient"
import OccasionClient from "../../../domain/adapters/secondary/occasionClient"
import LayoutClient from "../../../domain/adapters/secondary/layoutClient"
import AnswerClient from "../../../domain/adapters/secondary/answerClient"
import ClientClient from "../../../domain/adapters/secondary/clientClient"
import CommandClient from "../../../domain/adapters/secondary/commandClient"

export default class SlicesHelper {
	static getProjectClient(state: ProjectStoreState): ProjectClient {
		return {
			currentProjectId: state.currentProjectId,
			openedProject: state.openedProject,
			isOpeningGamePassed: state.isOpeningGamePassed,
			updateOpeningGameState: state.updateOpeningGameState,

			projects: state.projects,
			createProject: state.createProject,
			renameProject: state.renameProject,
			projectSelected: state.projectSelected,
			refreshCurrentGift: state.refreshCurrentGift,
			getAll: state.getAll,
			getProject: state.getProject,
		}
	}

	static getTemplateClient(state: ProjectStoreState): TemplateClient {
		return {
			templates: state.templates,
			layoutPacks: state.layoutPacks,
			currentTemplateId: state.currentTemplateId,

			getTemplates: state.getTemplates,
			chooseTemplate: state.chooseTemplate,
			getLayoutPacks: state.getLayoutPacks,
			applyLayoutPackToGift: state.applyLayoutPackToGift,
		}
	}

	static getGiftClient(state: ProjectStoreState): GiftClient {
		return {
			giftPictureFileNameById: state.giftPictureFileNameById,
			currentGiftReceivers: state.currentGiftReceivers,
			currentReceiverTrackingId: state.currentReceiverTrackingId,
			openedGift: state.openedGift,

			addGift: state.addGift,
			updateGift: state.updateGift,
			initGiftEvent: state.initGiftEvent,
			updateGiftSendingMode: state.updateGiftSendingMode,
			currentGiftReceiversChanged: state.currentGiftReceiversChanged,
			updateGiftEventInfos: state.updateGiftEventInfos,
			updateGiftOpeningMode: state.updateGiftOpeningMode,
			retrieveGift: state.retrieveGift,
			setCurrentReceiver: state.setCurrentReceiver,
			sendGiftByEmail: state.sendGiftByEmail,
			setCurrentGiftCommand: state.setCurrentGiftCommand,
			notifyCreatorGiftOpening: state.notifyCreatorGiftOpening,
			getReceiver: state.getReceiver,
		}
	}

	static getOccasionSlice(state: ProjectStoreState): OccasionClient {
		return {
			occasionsByCategory: state.occasionsByCategory,

			getOccasions: state.getOccasions,
		}
	}

	static getLayoutSlice(state: ProjectStoreState): LayoutClient {
		return {
			uploadLayoutPicture: state.uploadLayoutPicture,
			updateLayoutItem: state.updateLayoutItem,
		}
	}

	static getAnswerSlice(state: ProjectStoreState): AnswerClient {
		return {
			updateAnswer: state.updateAnswer,
		}
	}

	static getClientSlice(state: ProjectStoreState): ClientClient {
		return {
			getAllClients: state.getAllClients,
		}
	}

	static getCommandSlice(state: ProjectStoreState): CommandClient {
		return {
			getClientCommands: state.getClientCommands,
			reserveGift: state.reserveGift,
		}
	}
}
