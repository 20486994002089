export interface UploadLayoutPictureCommand {
	projectId: number
	giftId: number
	layoutId: number
	pictureId: number
	picture: Blob
}

export class UploadLayoutPictureCommandBuilder {
	private _command: UploadLayoutPictureCommand =
		{} as UploadLayoutPictureCommand

	withProjectId(projectId: number): UploadLayoutPictureCommandBuilder {
		this._command = { ...this._command, projectId }
		return this
	}

	withGiftId(giftId: number): UploadLayoutPictureCommandBuilder {
		this._command = { ...this._command, giftId }
		return this
	}

	withLayoutId(layoutId: number): UploadLayoutPictureCommandBuilder {
		this._command = { ...this._command, layoutId }
		return this
	}

	withPictureId(pictureId: number): UploadLayoutPictureCommandBuilder {
		this._command = { ...this._command, pictureId }
		return this
	}

	withPicture(picture: Blob): UploadLayoutPictureCommandBuilder {
		this._command = { ...this._command, picture }
		return this
	}

	build(): UploadLayoutPictureCommand {
		if (Object.values(this._command).some((v) => v == null)) {
			throw new Error(
				`invalid command missing some fields: ${Object.keys(
					this._command,
				)
					.filter(
						(k) =>
							this._command[
								k as keyof UploadLayoutPictureCommand
							] == null,
					)
					.join(",")}`,
			)
		}

		return this._command
	}
}
