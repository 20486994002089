import React from "react"
import { Text } from "../domain/text"
import LayoutTextBlock from "./LayoutTextBlock"
import { LayoutHelper } from "../helpers/layoutHelper"
import { getTextColor, Template } from "../domain/template"
import { Picture } from "../domain/picture"
import { Box, BoxProps, Image, ImageProps } from "@chakra-ui/react"
import { Layout, RenderItem } from "../domain/layout"

import "./TextOrPicture.css"
import ImgContainer, { Size, Style } from "./ImgContainer"
import XlImage from "./XlImage"
import Lg1Image from "./Lg1Image"
import Md1Image from "./Md1Image"
import Md2Image from "./Md2Image"
import Sm1Image from "./Sm1Image"
import Sm2Image from "./Sm2Image"
import Xs1Image from "./Xs1Image"
import Xs2Image from "./Xs2Image"
import Xxs1Image from "./Xxs1Image"

export type TextOrPictureProps = BoxProps & {
	item: RenderItem
	template: Template
	layout: Layout
	index: number
	onItemClicked?: (newItem: RenderItem) => void
	animationImgClass?: string
	animationTextClass?: string
	// TEMP isolate changes for opening part (YLA)
	isOpening?: boolean
}

export function TextOrPicture({
	item,
	template,
	layout,
	index,
	onItemClicked,
	animationImgClass,
	animationTextClass,
	isOpening,
}: TextOrPictureProps): React.JSX.Element {
	const renderText = (renderItem: RenderItem, idx: number) => {
		const text = renderItem as Text
		return (
			<Box
				className={`item item-text${
					renderItem.typeIndex + 1
				}  ${animationTextClass}`}
				mb="16px"
				p={"8px"}
				borderRadius={"4px"}
				key={idx}
			>
				<LayoutTextBlock
					className={`textArea-${renderItem.typeIndex + 1}`}
					typedIndex={renderItem.typeIndex}
					text={text}
					fontFamily={LayoutHelper.computeFontFamily(
						template,
						text.level,
						isOpening ?? false,
					)}
					fontWeight={LayoutHelper.computeFontWeight(
						template,
						text.level,
						isOpening ?? false,
					)}
					fontSize={LayoutHelper.computeFontSize(
						template,
						text.level,
						isOpening ?? false,
					)}
					color={getTextColor(template, layout.usesDarkMode)}
					onClicked={() => onItemClicked && onItemClicked(item)}
					isOpening={isOpening}
				/>
			</Box>
		)
	}

	const renderPicture = (renderItem: RenderItem, idx: number) => {
		const picture = renderItem as Picture
		return picture.isBackground ? (
			<Box
				className={`item ${picture.containerSize} ${
					picture.containerStyle
				} item-picture${renderItem.typeIndex + 1} ${animationImgClass}`}
				w="full"
				backgroundImage={`url('${process.env.REACT_APP_API_URL}/${picture.path}')`}
				backgroundSize={"cover"}
				backgroundPosition={"center center"}
				h={"full"}
				key={idx}
			></Box>
		) : (
			<Box
				className={`item ${picture.containerSize} ${
					picture.containerStyle
				} item-picture${renderItem.typeIndex + 1} ${animationImgClass}`}
				w="full"
				key={idx}
			>
				<Image
					src={`${process.env.REACT_APP_API_URL}/${picture.path}`}
					key={`img-${renderItem.typeIndex}`}
					className={`picture picture-${
						picture.imgSize
					} pictureArea-${renderItem.typeIndex + 1}`}
					onClick={() => onItemClicked && onItemClicked(item)}
				/>
			</Box>
		)
	}

	type SizeKind =
		| "xl"
		| "lg1"
		| "md1"
		| "md2"
		| "sm1"
		| "sm2"
		| "xs1"
		| "xs2"
		| "xxs1"
	const imgSelector: Record<
		SizeKind,
		(imgProps: ImageProps) => React.ReactNode
	> = {
		xl: (imgProps) => <XlImage {...imgProps} />,
		lg1: (imgProps) => <Lg1Image {...imgProps} />,
		md1: (imgProps) => <Md1Image {...imgProps} />,
		md2: (imgProps) => <Md2Image {...imgProps} />,
		sm1: (imgProps) => <Sm1Image {...imgProps} />,
		sm2: (imgProps) => <Sm2Image {...imgProps} />,
		xs1: (imgProps) => <Xs1Image {...imgProps} />,
		xs2: (imgProps) => <Xs2Image {...imgProps} />,
		xxs1: (imgProps) => <Xxs1Image {...imgProps} />,
	}

	const getImgKind = (picture: Picture): SizeKind => {
		const sizeSelector: Record<Size, (size: string) => SizeKind> = {
			xl: () => "xl",
			lg: () => "lg1",
			md: (style) => (style.includes("1") ? "md1" : "md2"),
			sm: (style) => (style.includes("1") ? "sm1" : "sm2"),
			xs: (style) => (style.includes("1") ? "xs1" : "xs2"),
			"2xs": () => "xxs1",
		}
		return sizeSelector[picture.containerSize as Size](
			picture.containerStyle,
		)
	}

	const renderOpeningPicture = (renderItem: RenderItem, _: number) => {
		const picture = renderItem as Picture
		return picture.isBackground ? (
			<ImgContainer
				backgroundImage={`url('${process.env.REACT_APP_API_URL}/${picture.path}')`}
				backgroundSize={"cover"}
				backgroundPosition={"center center"}
				containerSize={picture.containerSize as Size}
				containerStyle={picture.containerStyle as Style}
			/>
		) : (
			<ImgContainer
				containerSize={picture.containerSize as Size}
				containerStyle={picture.containerStyle as Style}
				className={animationImgClass}
				w="full"
			>
				{imgSelector[getImgKind(picture)]({
					src: `${process.env.REACT_APP_API_URL}/${picture.path}`,
					key: `img-${renderItem.typeIndex}`,
					className: `picture picture-${
						picture.imgSize
					} pictureArea-${renderItem.typeIndex + 1}`,
					onClick: () => onItemClicked && onItemClicked(item),
				})}
			</ImgContainer>
		)
	}

	const renderer: Record<
		string,
		(renderItem: RenderItem, idx: number) => React.JSX.Element
	> = {
		text: renderText,
		picture: (renderItem, idx) =>
			isOpening
				? renderOpeningPicture(renderItem, idx)
				: renderPicture(renderItem, idx),
	}

	return renderer[item.type](item, index)
}
