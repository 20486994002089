import GiftClient from "../domain/adapters/secondary/giftClient"
import { useGiftStoreDependencies } from "../components/hooks/useGiftStoreDependencies"
import { useCallback, useState } from "react"
import { getGiftDate } from "../domain/gift"

export default function useChooseOpeningGameAnswersUseCase(
	giftClient: GiftClient,
) {
	const { currentProjectId, currentStoreGift } = useGiftStoreDependencies()

	const updateGiftOpeningMode = giftClient.updateGiftOpeningMode

	const [answersValid, setAnswersValid] = useState(true)
	const [answerInput, setAnswerInput] = useState("")
	const [answers, setAnswers] = useState(
		currentStoreGift?.openingGame?.answers.filter(
			(answer) => !answer.startsWith("blank"),
		) ?? ([] as string[]),
	)

	const answersChanged = useCallback((answers: string[]) => {
		setAnswers(answers)
	}, [])

	const answersValidChanged = useCallback((answersValid: boolean) => {
		setAnswersValid(answersValid)
	}, [])

	const isAnswersValid = useCallback((answers: string[]) => {
		return (
			answers.length <= 10 &&
			answers.every((a) => {
				const isComma = a[a.length - 1] === ","
				const min = isComma ? 4 : 3
				return (
					/^[0-9a-zA-ZÀ-ÿ!?, ]*$/.test(a) &&
					a.length >= min &&
					a.length <= 50
				)
			})
		)
	}, [])

	const updateAnswers = useCallback(
		(value: string) => {
			const formattedAnswer = value.slice(0, -1).trim().toLowerCase()
			if (!answers.includes(formattedAnswer)) {
				setAnswers(answers.concat(formattedAnswer))
			}
		},
		[answers],
	)

	const inputChanged = useCallback(
		(input: string) => {
			let value = input

			if (value.length === 0) {
				setAnswersValid(true)
				setAnswerInput("")
				return
			}

			if (answers.length === 0) {
				setAnswersValid(isAnswersValid([value]))
			}

			const localAnswers = [value].concat(answers)
			const localIsValid = isAnswersValid(localAnswers)
			const isComma = value[value.length - 1] === ","
			if (isComma && localIsValid) {
				updateAnswers(value)
				input = ""
			} else {
				setAnswersValid(localIsValid)
			}
			setAnswerInput(input)
		},
		[answers, isAnswersValid, updateAnswers],
	)

	const updateOpeningGameAnswers = useCallback(async () => {
		await updateGiftOpeningMode(
			currentProjectId,
			currentStoreGift?.id!,
			currentStoreGift?.openingMode!,
			getGiftDate(currentStoreGift?.openingDate!),
			{
				question: currentStoreGift?.openingGame?.question!,
				answers:
					answers.length === 0
						? [answerInput]
						: answers.filter((a) => a.trim() !== ""),
			},
		)
	}, [
		answerInput,
		answers,
		currentProjectId,
		currentStoreGift,
		updateGiftOpeningMode,
	])

	return {
		currentStoreGift,
		currentProjectId,

		inputChanged,
		answersValid,
		answerInput,
		answers,

		answersChanged,
		answersValidChanged,

		isAnswersValid,
		updateOpeningGameAnswers,
	}
}
