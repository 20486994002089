import { create } from "zustand"

export type UserStore = {
	accessToken: string
	updateAccessToken: (newToken: string) => void
}

const useUserStore = create<UserStore>()((set) => ({
	accessToken: "",
	updateAccessToken(newToken: string) {
		set((state) => Object.assign({}, state, { accessToken: newToken }))
	},
}))

export default useUserStore
