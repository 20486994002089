import { StateCreator } from "zustand"
import { ProjectStoreState } from "../../projectStore"
import ClientClient from "../../../domain/adapters/secondary/clientClient"
import { fetchApi } from "../../storeHelper"
import { Client } from "../../../domain/client"

export const createClientSlice: StateCreator<
	ProjectStoreState,
	[["zustand/immer", never]],
	[],
	ClientClient
> = (set) => ({
	getAllClients: async () => {
		return await fetchApi<Client[]>("clients", "GET")
	},
})
