import { useLocation, useParams } from "react-router-dom"
import { useIntl } from "react-intl"
import { useCallback, useState } from "react"
import Gift from "../domain/gift"
import Project from "../domain/project"
import GiftClient from "../domain/adapters/secondary/giftClient"
import ProjectClient from "../domain/adapters/secondary/projectClient"

export default function useRetrieveGiftUseCase(
	giftClient: GiftClient,
	projectClient: ProjectClient,
) {
	const intl = useIntl()

	type RouteParameters = {
		projectId: string
		giftId: string
	}

	const retrieveGift = giftClient.retrieveGift
	const activateCurrentReceiver = giftClient.setCurrentReceiver
	const getProject = projectClient.getProject
	const projectSelected = projectClient.projectSelected

	const params = useParams<RouteParameters>()
	const search = useLocation().search
	const nSub = new URLSearchParams(search).get("nSub")

	const { projectId, giftId } = {
		projectId: Number(params.projectId),
		giftId: Number(params.giftId),
	}

	const [retrieveGiftError, setRetrieveGiftError] = useState(
		null as string | null,
	)
	const [localGift, setLocalGift] = useState(null as Gift | null)
	const [project, setProject] = useState(null as Project | null)

	const loadGift = useCallback(
		async (projectId: number, giftId: number) => {
			const retrieveError = intl.formatMessage({
				description: "openingPageErrorOpeningGiftMessage",
				defaultMessage:
					"Une erreur s'est produite à l'ouverture du cadeau.",
			})
			try {
				const gift = await retrieveGift(projectId, giftId)
				setLocalGift(gift)

				if (gift == null) {
					console.warn("this gift does not exist!")
					setRetrieveGiftError(retrieveError)
					return
				}
				projectSelected(gift.project.id!)

				const project = await getProject(projectId)
				setProject(project)
				await activateCurrentReceiver(nSub!)
				setRetrieveGiftError(null)
			} catch {
				setRetrieveGiftError(retrieveError)
			}
		},
		[
			activateCurrentReceiver,
			getProject,
			intl,
			nSub,
			projectSelected,
			retrieveGift,
		],
	)

	if (Number.isNaN(projectId)) {
		throw new Error("Invalid projectId!")
	}

	if (Number.isNaN(giftId)) {
		throw new Error("Invalid giftId!")
	}

	if ((nSub?.length ?? 0) === 0) {
		throw new Error("Invalid link!")
	}

	return {
		// gift
		project,
		localGift,
		retrieveGiftError,
		loadGift,

		// navigation
		projectId,
		giftId,
		nSub,
	}
}
