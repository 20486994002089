import React, { useEffect } from "react"
import { ButtonGroup, Flex, Heading, Button } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import { Link as DomLink } from "react-router-dom"

import useProjectStore from "../stores/projectStore"
import SlicesHelper from "../stores/slices/helpers/slicesHelper"

import useLoadProjectsUseCase from "../useCases/loadProjectsUseCase"

import PrimaryButton from "../components/PrimaryButton"
import DataContainer from "../components/DataContainer"
import ProjectsTable from "../components/ProjectsTable"

import "./Home.css"

export default function Home(): React.JSX.Element {
	const projectClient = useProjectStore(SlicesHelper.getProjectClient)
	const {
		projects,
		loadProjects,
		resetCurrentProject,
		resetLoadProjectsStatus,
	} = useLoadProjectsUseCase(projectClient)

	useEffect(() => {
		resetCurrentProject()
		loadProjects().then()
	}, [loadProjects, resetCurrentProject, resetLoadProjectsStatus])

	return (
		<Flex gap="32px" flexDirection="column" padding="80px" h="100%">
			<style>{`#root div.main-navbar { display: flex; }`}</style>
			<Flex justifyContent="space-between" w="100%">
				<Heading fontFamily="DM Serif Display" fontWeight="400">
					<FormattedMessage
						description="homePageTitle1"
						defaultMessage="Plateforme de création"
					/>
					<br />
					<span style={{ color: "#428880" }}>
						<FormattedMessage
							description="homePageTitle2"
							defaultMessage="d’expérience présent"
						/>
					</span>
				</Heading>
				<ButtonGroup>
					<PrimaryButton
						as={DomLink}
						to="/project/creation"
						size="lg"
					>
						<FormattedMessage
							description="homePageCreatePresentButton"
							defaultMessage="Créer un Présent"
						></FormattedMessage>
					</PrimaryButton>
				</ButtonGroup>
			</Flex>

			<Flex flexDirection="column" gap="8px">
				<Heading
					fontFamily="DM Serif Display"
					fontWeight="400"
					fontSize="20px"
				>
					<FormattedMessage
						description="homePageImpactSectionTitle"
						defaultMessage="Mon impact 🌱"
					/>
				</Heading>
				<Flex gap="8px">
					<DataContainer
						color="#632BD9"
						backgroundColor="#632BD914"
						titleElem={<>{`${"-"}kg CO₂`}</>}
						valueElem={
							<FormattedMessage
								description="homePageImpactCO2"
								defaultMessage="EMPREINTE CARBONE ESTIMÉE"
							></FormattedMessage>
						}
					></DataContainer>
					<DataContainer
						color="#007AFF"
						backgroundColor="#007AFF14"
						titleElem={<>{`${"-"}%`}</>}
						valueElem={
							<FormattedMessage
								description="homePageOpenGifts"
								defaultMessage="DES CADEAU OUVERTS"
							></FormattedMessage>
						}
					></DataContainer>
				</Flex>
			</Flex>

			<Flex flexDirection="column" gap="8px">
				<Heading
					fontFamily="DM Serif Display"
					fontWeight="400"
					fontSize="20px"
				>
					<FormattedMessage
						description="homePageProjectsSectionTitle"
						defaultMessage="Mes projets récents"
					/>
				</Heading>

				<ProjectsTable projects={projects} limit={3} />

				<Button
					variant="outline"
					colorScheme="teal"
					w="fit-content"
					margin="6px auto"
					as={DomLink}
					to="/projects"
				>
					<FormattedMessage
						description="homePageProjectsSeeAll"
						defaultMessage="Voir tout mes projets"
					/>
				</Button>
			</Flex>
		</Flex>
	)
}
