import React from "react"
import { Button, ButtonProps, forwardRef } from "@chakra-ui/react"

import "./PrimaryButton.css"
import * as CSS from "csstype"

export type PrimaryButtonProps = ButtonProps & {
	isDisabled?: boolean
	backgroundOverride?: CSS.Property.Color
}

const primaryBtn = forwardRef<PrimaryButtonProps, "button">((props, ref) => {
	const bgOverride = props.backgroundOverride
	const propagatedProps = Object.assign({}, props, {
		backgroundOverride: undefined,
	})
	delete propagatedProps["backgroundOverride"]
	return (
		<Button
			fontFamily="Nunito Sans"
			fontStyle="normal"
			fontWeight={600}
			lineHeight="28px"
			textAlign="center"
			bgColor={bgOverride ?? "var(--var-btn-primary-active-bg)"}
			border="var(--var-btn-primary-border)"
			color="var(--var-btn-primary-active-color)"
			borderRadius="6px"
			padding="0px 24px"
			gap="8px"
			w="169px"
			className={`primaryBtn${
				props.isDisabled ? " primaryBtnDisabled" : ""
			}`}
			_hover={{
				bg: "var(--var-btn-primary-active-bg--hover)",
			}}
			{...propagatedProps}
			ref={ref}
		>
			{props.children}
		</Button>
	)
})

export default primaryBtn
