import React from "react"
import { Image, ImageProps } from "@chakra-ui/react"

export default function Xs1Image({
	...imgProps
}: ImageProps): React.JSX.Element {
	return (
		<Image
			mx="16px"
			mr="auto"
			position="relative"
			top="-60%"
			{...imgProps}
		/>
	)
}
