import Project from "../domain/project"
import OccasionsByCategory from "../models/occasionsByCategory"
import { Template } from "../domain/template"
import { LayoutPack } from "../domain/layoutPack"
import { Command } from "../domain/command"

export default function getProjects(now: Date): Project[] {
	return [
		{
			name: "First Project lorem ipsum dolor lorem ipsum dolor lor",
			status: "IN_PROGRESS",
			id: 1,
			createdAt: now,
			updatedAt: now,
			gifts: [],
			creator: {},
		},
		{
			name: "Second Project",
			status: "DONE",
			id: 2,
			createdAt: now,
			updatedAt: now,
			creator: {},
			gifts: [
				{
					id: 1,
					name: "Week-end en Thalasso",
					type: "LINK",
					title: "Un super week-end à Ogeux-les-bains",
					description:
						"un week-end en Thalasso à Ogeux-les-bains, all inclusive",
					link: "https://www.google.com?query=week-end%20en%20thalasso",
					pdf: undefined,
					picturePath: "/public_assets/pictures/noPictureYet.png",
					createdAt: now.toISOString(),
					updatedAt: now.toISOString(),
					step: "GIFT_INFOS",
					project: {
						id: 2,
					},
					event: undefined,
					openingMode: "NONE",
					openingGame: undefined,
					visualizationLink: "",
					hasBeenOpened: false
				},
				{
					id: 2,
					name: "Racing de monaco",
					type: "PDF",
					title: "Racing de monaco",
					description: "un week-end au racing de monaco",
					link: undefined,
					pdf: "titi.pdf",
					picturePath: "/public_assets/pictures/noPictureYet.png",
					createdAt: now.toISOString(),
					updatedAt: now.toISOString(),
					step: "GIFT_INFOS",
					project: {
						id: 2,
					},
					event: undefined,
					openingMode: "GAME",
					openingGame: {
						question:
							"Quelle est la date à laquelle nous avons fêter les 20 ans de l'entreprise ?",
						answers: ["12/07/2018", "12 juillet 2018"],
					},
					visualizationLink: "",
					hasBeenOpened: false
				},
				{
					id: 3,
					name: "Concert de Florent Pany",
					type: "PDF",
					title: "Concert de Florent Pany",
					description:
						"deux places au premier rang pour le concert de Florent pagny au Zenith",
					link: undefined,
					pdf: "2_tickets_zenith_2245.pdf",
					picturePath: "/public_assets/pictures/noPictureYet.png",
					createdAt: now.toISOString(),
					updatedAt: now.toISOString(),
					step: "GIFT_INFOS",
					project: {
						id: 2,
					},
					event: {
						id: 1,
						date: new Date(2024, 1, 1, 21, 0, 0).toISOString(),
						receivers: undefined,
						occasion: "anniversaire d'entreprise",
						sendingMode: "MULTIPLE",
					},
					openingMode: "DATE",
					openingGame: undefined,
					visualizationLink: "",
					hasBeenOpened: false
				},
				{
					id: 4,
					name: "Visite du musée de Barcelone",
					type: "PDF",
					title: "Visite du musée de Barcelone",
					description:
						"Une visite pour 4 personnes au musée de Barcelone",
					link: undefined,
					pdf: "barcelone_musee_4_entries.pdf",
					picturePath: "/public_assets/pictures/noPictureYet.png",
					createdAt: now.toISOString(),
					updatedAt: now.toISOString(),
					step: "GIFT_INFOS",
					project: {
						id: 2,
					},
					event: {
						id: 1,
						date: new Date(2024, 7, 1, 9, 0, 0).toISOString(),
						receivers: undefined,
						occasion: "anniversaire d'entreprise",
						sendingMode: "MULTIPLE",
					},
					openingMode: "GAME_AND_DATE",
					openingGame: {
						question:
							"Quel est l'artiste ayant réalisé une exposition sur le thème des transgressions céramique en 2022 ?",
						answers: ["Llorens Artigas"],
					},
					visualizationLink: "",
					hasBeenOpened: false
				},
				{
					id: 5,
					name: "Plongée aux seychelles",
					type: "PDF",
					title: "Plongée aux seychelles",
					description:
						"2 places pour une plongée sous marine mémorable aux seychelles, avec padi",
					link: undefined,
					pdf: "padi_seychelles_plongee_2_entrees.pdf",
					picturePath: "/public_assets/pictures/noPictureYet.png",
					createdAt: now.toISOString(),
					updatedAt: now.toISOString(),
					step: "GIFT_INFOS",
					project: {
						id: 2,
					},
					event: {
						id: 1,
						date: new Date(2023, 7, 19, 10, 0, 0).toISOString(),
						receivers: undefined,
						occasion: "anniversaire d'un collaborateur",
						sendingMode: "MULTIPLE",
					},
					openingMode: "GAME_AND_DATE",
					openingGame: {
						question:
							"Quel est le spot de plongée le plus connu des seychelles ?",
						answers: ["l’île de Mahé", "île de Mahé", "Mahé"],
					},
					visualizationLink: "",
					hasBeenOpened: false
				},
				{
					id: 6,
					name: "Plongée aux seychelles",
					type: "PDF",
					title: "Plongée aux seychelles",
					description:
						"2 places pour une plongée sous marine mémorable aux seychelles, avec padi",
					link: undefined,
					pdf: "padi_seychelles_plongee_2_entrees.pdf",
					picturePath: "/public_assets/pictures/noPictureYet.png",
					createdAt: now.toISOString(),
					updatedAt: now.toISOString(),
					step: "GIFT_INFOS",
					project: {
						id: 2,
					},
					event: {
						id: 1,
						date: new Date(2022, 7, 19, 10, 0, 0).toISOString(),
						receivers: undefined,
						occasion: "anniversaire d'un collaborateur",
						sendingMode: "MULTIPLE",
					},
					openingMode: "GAME_AND_DATE",
					openingGame: {
						question:
							"Quel est le spot de plongée le plus connu des seychelles ?",
						answers: ["l’île de Mahé", "île de Mahé", "Mahé"],
					},
					visualizationLink: "",
					hasBeenOpened: false
				},
			],
		},
	]
}

export function fakeOccasions(): OccasionsByCategory {
	return {
		occasions: {
			categories: {
				féliciter: ["pot d'arrivée", "pot de départ", "lancement"],
				célébrer: [
					"anniversaire d'un collaborateur",
					"anniversaire d'entreprise",
				],
				soutenir: [
					"évènement d'entreprise",
					"séminaire d'entreprise",
					"team building",
				],
				remercier: [
					"fêtes de fin d'année",
					"remerciement",
					"levée de fonds",
				],
				personnaliser: ["autre évènement"],
			},
		},
	}
}

export function fakeTemplates(now: Date): Template[] {
	return [
		{
			id: 1,
			name: "Bulle",
			createdAt: now,
			updatedAt: now,
			picturePath: "public_assets/-1/pictures/-1/Bulle_bg.png",
			layouts: [
				{
					id: 1,
					name: "Bulle_introduction",
					kind: "TEMPLATE",
					updatedAt: now,
					createdAt: now,
					role: "INTRODUCTION",
					textCount: 1,
					pictureCount: 0,
					cssPath: "public_assets/1/styles/1/Bulle_loading_style.css",
					usesDarkMode: false,
					texts: [
						{
							id: 1,
							text: "découvrez ce qu'il contient",
							level: "SUBTITLE",
							position: 1,
						},
					],
					pictures: [],
				},
				{
					id: 2,
					name: "Bulle_title",
					kind: "TEMPLATE",
					updatedAt: now,
					createdAt: now,
					role: "TITLE",
					textCount: 2,
					pictureCount: 0,
					cssPath: "public_assets/1/styles/1/Bulle_title_style.css",
					usesDarkMode: false,
					texts: [
						{
							id: 2,
							text: "Merci pour cette année haute en couleurs",
							level: "TITLE",
							position: 1,
						},
						{
							id: 3,
							text: "Voilà un présent pour te remercier de cette année passée ensemble",
							level: "SUBTITLE",
							position: 2,
						},
					],
					pictures: [],
				},
				{
					id: 3,
					name: "Bulle_message",
					kind: "TEMPLATE",
					updatedAt: now,
					createdAt: now,
					role: "MESSAGE",
					textCount: 2,
					pictureCount: 0,
					cssPath: "public_assets/1/styles/1/Bulle_message_style.css",
					usesDarkMode: false,
					texts: [
						{
							id: 4,
							text: "Souvenir de quelques moments forts",
							level: "TITLE",
							position: 1,
						},
						{ id: 5, text: "...", level: "PARAGRAPH", position: 2 },
					],
					pictures: [],
				},
				{
					id: 4,
					name: "Bulle_preview",
					kind: "TEMPLATE",
					updatedAt: now,
					createdAt: now,
					role: "PREVIEW",
					textCount: 1,
					pictureCount: 3,
					cssPath:
						"public_assets/1/styles/1/Bulle_experience_style.css",
					usesDarkMode: false,
					texts: [
						{
							id: 6,
							text: "Voici ton cadeau",
							level: "TITLE",
							position: 1,
						},
					],
					pictures: [
						{
							id: 7,
							path: "public_assets/1/pictures/1/bulle_exp_1.jpg",
							position: 2,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
						{
							id: 8,
							path: "public_assets/1/pictures/1/bulle_exp_2.jpg",
							position: 3,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
						{
							id: 9,
							path: "public_assets/1/pictures/1/bulle_exp_3.jpg",
							position: 4,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
					],
				},
			],
			bodyFont: "Nunito sans",
			titleFont: "Nunito sans bold",
			callToActionBackgroundColor: "green",
			textColor: "purple",
			darkTextColor: "lightblue",
			titleSizes: ["32px"],
			smallTitleSizes: ["24px"],
			subtitleSizes: ["18px"],
			bodySizes: ["14px"],
			titleLineHeights: ["105%"],
			smallTitleLineHeights: ["110%"],
			subtitleLineHeights: ["120%"],
			bodyLineHeights: ["135%"],
			holdImage: "public_assets/1/styles/1/bulle_hold.png",
			knotImage: "public_assets/1/styles/1/bulle_knot.png",
			solidBackground1: "#000",
			solidBackground2: "#000",
			solidBackground3: "#000",
			signatureBackgroundImage: "",
		},
		{
			id: 2,
			name: "Gradient",
			createdAt: now,
			updatedAt: now,
			picturePath: "public_assets/-1/pictures/-1/Gradient_bg.png",
			callToActionBackgroundColor: "green",
			textColor: "purple",
			darkTextColor: "lightblue",
			layouts: [
				{
					id: 5,
					name: "Gradient_introduction",
					kind: "TEMPLATE",
					updatedAt: now,
					createdAt: now,
					role: "INTRODUCTION",
					textCount: 1,
					pictureCount: 0,
					cssPath:
						"public_assets/1/styles/1/Gradient_loading_style.css",
					usesDarkMode: false,
					texts: [
						{
							id: 10,
							text: "découvrez ce qu'il contient",
							level: "SUBTITLE",
							position: 1,
						},
					],
					pictures: [],
				},
				{
					id: 6,
					name: "Gradient_title",
					kind: "TEMPLATE",
					updatedAt: now,
					createdAt: now,
					role: "TITLE",
					textCount: 2,
					pictureCount: 0,
					cssPath:
						"public_assets/1/styles/1/Gradient_title_style.css",
					usesDarkMode: false,
					texts: [
						{
							id: 11,
							text: "Merci pour cette année haute en couleurs",
							level: "TITLE",
							position: 1,
						},
						{
							id: 12,
							text: "Voilà un présent pour te remercier de cette année passée ensemble",
							level: "SUBTITLE",
							position: 1,
						},
					],
					pictures: [],
				},
				{
					id: 7,
					name: "Gradient_message",
					kind: "TEMPLATE",
					updatedAt: now,
					createdAt: now,
					role: "MESSAGE",
					textCount: 2,
					pictureCount: 0,
					cssPath:
						"public_assets/1/styles/1/Gradient_message_style.css",
					usesDarkMode: false,
					texts: [
						{
							id: 13,
							text: "Souvenir de quelques moments forts",
							level: "TITLE",
							position: 1,
						},
						{
							id: 14,
							text: "...",
							level: "PARAGRAPH",
							position: 1,
						},
					],
					pictures: [],
				},
				{
					id: 8,
					name: "Gradient_preview",
					kind: "TEMPLATE",
					updatedAt: now,
					createdAt: now,
					role: "PREVIEW",
					textCount: 1,
					pictureCount: 3,
					cssPath:
						"public_assets/1/styles/1/Gradient_experience_style.css",
					usesDarkMode: false,
					texts: [
						{
							id: 15,
							text: "Voici ton cadeau",
							level: "TITLE",
							position: 1,
						},
					],
					pictures: [
						{
							id: 16,
							path: "public_assets/1/pictures/1/gradient_exp_1.jpg",
							position: 2,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
						{
							id: 17,
							path: "public_assets/1/pictures/1/gradient_exp_2.jpg",
							position: 3,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
						{
							id: 18,
							path: "public_assets/1/pictures/1/gradient_exp_3.jpg",
							position: 4,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
					],
				},
			],
			bodyFont: "Nunito sans",
			titleFont: "Nunito sans bold",
			titleSizes: ["32px"],
			smallTitleSizes: ["24px"],
			subtitleSizes: ["18px"],
			bodySizes: ["14px"],
			titleLineHeights: ["105%"],
			smallTitleLineHeights: ["110%"],
			subtitleLineHeights: ["120%"],
			bodyLineHeights: ["135%"],
			holdImage: "public_assets/1/styles/1/bulle_hold.png",
			knotImage: "public_assets/1/styles/1/bulle_knot.png",
			solidBackground1: "#000",
			solidBackground2: "#000",
			solidBackground3: "#000",
			signatureBackgroundImage: "",
		},
		{
			id: 3,
			name: "Abstrait",
			createdAt: now,
			updatedAt: now,
			picturePath: "public_assets/-1/pictures/-1/Abstrait_bg.png",
			callToActionBackgroundColor: "green",
			textColor: "purple",
			darkTextColor: "lightblue",
			layouts: [
				{
					id: 9,
					name: "Abstrait_introduction",
					kind: "TEMPLATE",
					updatedAt: now,
					createdAt: now,
					role: "INTRODUCTION",
					textCount: 1,
					pictureCount: 0,
					cssPath:
						"public_assets/1/styles/1/Abstrait_loading_style.css",
					usesDarkMode: false,
					texts: [
						{
							id: 19,
							text: "découvrez ce qu'il contient",
							level: "SUBTITLE",
							position: 1,
						},
					],
					pictures: [],
				},
				{
					id: 10,
					name: "Abstrait_title",
					kind: "TEMPLATE",
					updatedAt: now,
					createdAt: now,
					role: "TITLE",
					textCount: 2,
					pictureCount: 0,
					cssPath:
						"public_assets/1/styles/1/Abstrait_title_style.css",
					usesDarkMode: false,
					texts: [
						{
							id: 20,
							text: "Merci pour cette année haute en couleurs",
							level: "TITLE",
							position: 1,
						},
						{
							id: 21,
							text: "Voilà un présent pour te remercier de cette année passée ensemble",
							level: "SUBTITLE",
							position: 2,
						},
					],
					pictures: [],
				},
				{
					id: 11,
					name: "Abstrait_message",
					kind: "TEMPLATE",
					updatedAt: now,
					createdAt: now,
					role: "MESSAGE",
					textCount: 2,
					pictureCount: 0,
					cssPath:
						"public_assets/1/styles/1/Abstrait_message_style.css",
					usesDarkMode: false,
					texts: [
						{
							id: 22,
							text: "Souvenir de quelques moments forts",
							level: "TITLE",
							position: 1,
						},
						{
							id: 23,
							text: "...",
							level: "PARAGRAPH",
							position: 2,
						},
					],
					pictures: [],
				},
				{
					id: 12,
					name: "Abstrait_preview",
					kind: "TEMPLATE",
					updatedAt: now,
					createdAt: now,
					role: "PREVIEW",
					textCount: 1,
					pictureCount: 3,
					cssPath:
						"public_assets/1/styles/1/Abstrait_experience_style.css",
					usesDarkMode: false,
					texts: [
						{
							id: 24,
							text: "Voici ton cadeau",
							level: "TITLE",
							position: 1,
						},
					],
					pictures: [
						{
							id: 25,
							path: "public_assets/1/pictures/1/abstrait_exp_1.jpg",
							position: 2,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
						{
							id: 26,
							path: "public_assets/1/pictures/1/abstrait_exp_2.jpg",
							position: 3,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
						{
							id: 27,
							path: "public_assets/1/pictures/1/abstrait_exp_3.jpg",
							position: 4,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
					],
				},
			],
			bodyFont: "Nunito sans",
			titleFont: "Nunito sans bold",
			titleSizes: ["32px"],
			smallTitleSizes: ["24px"],
			subtitleSizes: ["18px"],
			bodySizes: ["14px"],
			titleLineHeights: ["105%"],
			smallTitleLineHeights: ["110%"],
			subtitleLineHeights: ["120%"],
			bodyLineHeights: ["135%"],
			holdImage: "public_assets/1/styles/1/bulle_hold.png",
			knotImage: "public_assets/1/styles/1/bulle_knot.png",
			solidBackground1: "#000",
			solidBackground2: "#000",
			solidBackground3: "#000",
			signatureBackgroundImage: "",
		},
		{
			id: 4,
			name: "Grace",
			createdAt: now,
			updatedAt: now,
			picturePath: "public_assets/-1/pictures/-1/Grace_ng.png",
			callToActionBackgroundColor: "green",
			textColor: "purple",
			darkTextColor: "lightblue",
			layouts: [
				{
					id: 13,
					name: "Grace_introduction",
					kind: "TEMPLATE",
					updatedAt: now,
					createdAt: now,
					role: "INTRODUCTION",
					textCount: 1,
					pictureCount: 0,
					cssPath: "public_assets/1/styles/1/Grace_loading_style.css",
					usesDarkMode: false,
					texts: [
						{
							id: 28,
							text: "découvrez ce qu'il contient",
							level: "SUBTITLE",
							position: 1,
						},
					],
					pictures: [],
				},
				{
					id: 14,
					name: "Grace_title",
					kind: "TEMPLATE",
					updatedAt: now,
					createdAt: now,
					role: "TITLE",
					textCount: 2,
					pictureCount: 0,
					cssPath: "public_assets/1/styles/1/Grace_title_style.css",
					usesDarkMode: false,
					texts: [
						{
							id: 29,
							text: "Merci pour cette année haute en couleurs",
							level: "TITLE",
							position: 1,
						},
						{
							id: 30,
							text: "Voilà un présent pour te remercier de cette année passée ensemble",
							level: "SUBTITLE",
							position: 2,
						},
					],
					pictures: [],
				},
				{
					id: 15,
					name: "Grace_message",
					kind: "TEMPLATE",
					updatedAt: now,
					createdAt: now,
					role: "MESSAGE",
					textCount: 2,
					pictureCount: 0,
					cssPath: "public_assets/1/styles/1/Grace_message_style.css",
					usesDarkMode: false,
					texts: [
						{
							id: 31,
							text: "Souvenir de quelques moments forts",
							level: "TITLE",
							position: 1,
						},
						{
							id: 32,
							text: "...",
							level: "PARAGRAPH",
							position: 2,
						},
					],
					pictures: [],
				},
				{
					id: 16,
					name: "Grace_preview",
					kind: "TEMPLATE",
					updatedAt: now,
					createdAt: now,
					role: "PREVIEW",
					textCount: 1,
					pictureCount: 3,
					cssPath:
						"public_assets/1/styles/1/Grace_experience_style.css",
					usesDarkMode: false,
					texts: [
						{
							id: 33,
							text: "Voici ton cadeau",
							level: "TITLE",
							position: 1,
						},
					],
					pictures: [
						{
							id: 34,
							path: "public_assets/1/pictures/1/grace_exp_1.jpg",
							position: 2,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
						{
							id: 35,
							path: "public_assets/1/pictures/1/grace_exp_2.jpg",
							position: 3,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
						{
							id: 36,
							path: "public_assets/1/pictures/1/grace_exp_3.jpg",
							position: 4,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
					],
				},
			],
			bodyFont: "Nunito sans",
			titleFont: "Nunito sans bold",
			titleSizes: ["32px"],
			smallTitleSizes: ["24px"],
			subtitleSizes: ["18px"],
			bodySizes: ["14px"],
			titleLineHeights: ["105%"],
			smallTitleLineHeights: ["110%"],
			subtitleLineHeights: ["120%"],
			bodyLineHeights: ["135%"],
			holdImage: "public_assets/1/styles/1/bulle_hold.png",
			knotImage: "public_assets/1/styles/1/bulle_knot.png",
			solidBackground1: "#000",
			solidBackground2: "#000",
			solidBackground3: "#000",
			signatureBackgroundImage: "",
		},
		{
			id: 5,
			name: "Violette",
			createdAt: now,
			updatedAt: now,
			picturePath: "public_assets/-1/pictures/-1/Violette_bg.png",
			callToActionBackgroundColor: "green",
			textColor: "purple",
			darkTextColor: "lightblue",
			layouts: [
				{
					id: 17,
					name: "Violette_introduction",
					kind: "TEMPLATE",
					updatedAt: now,
					createdAt: now,
					role: "INTRODUCTION",
					textCount: 1,
					pictureCount: 0,
					cssPath:
						"public_assets/1/styles/1/Violette_loading_style.css",
					usesDarkMode: false,
					texts: [
						{
							id: 37,
							text: "découvrez ce qu'il contient",
							level: "SUBTITLE",
							position: 1,
						},
					],
					pictures: [],
				},
				{
					id: 18,
					name: "Violette_title",
					kind: "TEMPLATE",
					updatedAt: now,
					createdAt: now,
					role: "TITLE",
					textCount: 2,
					pictureCount: 0,
					cssPath:
						"public_assets/1/styles/1/Violette_title_style.css",
					usesDarkMode: false,
					texts: [
						{
							id: 38,
							text: "Merci pour cette année haute en couleurs",
							level: "TITLE",
							position: 1,
						},
						{
							id: 39,
							text: "Voilà un présent pour te remercier de cette année passée ensemble",
							level: "SUBTITLE",
							position: 2,
						},
					],
					pictures: [],
				},
				{
					id: 19,
					name: "Violette_message",
					kind: "TEMPLATE",
					updatedAt: now,
					createdAt: now,
					role: "MESSAGE",
					textCount: 2,
					pictureCount: 0,
					cssPath:
						"public_assets/1/styles/1/Violette_message_style.css",
					usesDarkMode: false,
					texts: [
						{
							id: 40,
							text: "Souvenir de quelques moments forts",
							level: "TITLE",
							position: 1,
						},
						{
							id: 41,
							text: "...",
							level: "PARAGRAPH",
							position: 2,
						},
					],
					pictures: [],
				},
				{
					id: 20,
					name: "Violette_preview",
					kind: "TEMPLATE",
					updatedAt: now,
					createdAt: now,
					role: "PREVIEW",
					textCount: 1,
					pictureCount: 3,
					cssPath:
						"public_assets/1/styles/1/Violette_experience_style.css",
					usesDarkMode: false,
					texts: [
						{
							id: 42,
							text: "Voici ton cadeau",
							level: "TITLE",
							position: 1,
						},
					],
					pictures: [
						{
							id: 43,
							path: "public_assets/1/pictures/1/violette_exp_1.jpg",
							position: 2,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
						{
							id: 44,
							path: "public_assets/1/pictures/1/violette_exp_2.jpg",
							position: 3,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
						{
							id: 45,
							path: "public_assets/1/pictures/1/violette_exp_3.jpg",
							position: 4,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
					],
				},
			],
			bodyFont: "Nunito sans",
			titleFont: "Nunito sans bold",
			titleSizes: ["32px"],
			smallTitleSizes: ["24px"],
			subtitleSizes: ["18px"],
			bodySizes: ["14px"],
			titleLineHeights: ["105%"],
			smallTitleLineHeights: ["110%"],
			subtitleLineHeights: ["120%"],
			bodyLineHeights: ["135%"],
			holdImage: "public_assets/1/styles/1/bulle_hold.png",
			knotImage: "public_assets/1/styles/1/bulle_knot.png",
			solidBackground1: "#000",
			solidBackground2: "#000",
			solidBackground3: "#000",
			signatureBackgroundImage: "",
		},
		{
			id: 6,
			name: "Floral",
			createdAt: now,
			updatedAt: now,
			picturePath: "public_assets/-1/pictures/-1/Floral_bg.png",
			callToActionBackgroundColor: "green",
			textColor: "purple",
			darkTextColor: "lightblue",
			layouts: [
				{
					id: 21,
					name: "Floral_introduction",
					kind: "TEMPLATE",
					updatedAt: now,
					createdAt: now,
					role: "INTRODUCTION",
					textCount: 1,
					pictureCount: 0,
					cssPath:
						"public_assets/1/styles/1/Floral_loading_style.css",
					usesDarkMode: false,
					texts: [
						{
							id: 46,
							text: "découvrez ce qu'il contient",
							level: "SUBTITLE",
							position: 1,
						},
					],
					pictures: [],
				},
				{
					id: 22,
					name: "Floral_title",
					kind: "TEMPLATE",
					updatedAt: now,
					createdAt: now,
					role: "TITLE",
					textCount: 2,
					pictureCount: 0,
					cssPath: "public_assets/1/styles/1/Floral_title_style.css",
					usesDarkMode: false,
					texts: [
						{
							id: 47,
							text: "Merci pour cette année haute en couleurs",
							level: "TITLE",
							position: 1,
						},
						{
							id: 48,
							text: "Voilà un présent pour te remercier de cette année passée ensemble",
							level: "SUBTITLE",
							position: 2,
						},
					],
					pictures: [],
				},
				{
					id: 23,
					name: "Floral_message",
					kind: "TEMPLATE",
					updatedAt: now,
					createdAt: now,
					role: "MESSAGE",
					textCount: 2,
					pictureCount: 0,
					cssPath:
						"public_assets/1/styles/1/Floral_message_style.css",
					usesDarkMode: false,
					texts: [
						{
							id: 49,
							text: "Souvenir de quelques moments forts",
							level: "TITLE",
							position: 1,
						},
						{
							id: 50,
							text: "...",
							level: "PARAGRAPH",
							position: 2,
						},
					],
					pictures: [],
				},
				{
					id: 24,
					name: "Floral_preview",
					kind: "TEMPLATE",
					updatedAt: now,
					createdAt: now,
					role: "PREVIEW",
					textCount: 1,
					pictureCount: 3,
					cssPath:
						"public_assets/1/styles/1/Floral_experience_style.css",
					usesDarkMode: false,
					texts: [
						{
							id: 51,
							text: "Voici ton cadeau",
							level: "TITLE",
							position: 1,
						},
					],
					pictures: [
						{
							id: 52,
							path: "public_assets/1/pictures/1/floral_exp_1.jpg",
							position: 2,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
						{
							id: 53,
							path: "public_assets/1/pictures/1/floral_exp_2.jpg",
							position: 3,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
						{
							id: 54,
							path: "public_assets/1/pictures/1/floral_exp_3.jpg",
							position: 4,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
					],
				},
			],
			bodyFont: "Nunito sans",
			titleFont: "Nunito sans bold",
			titleSizes: ["32px"],
			smallTitleSizes: ["24px"],
			subtitleSizes: ["18px"],
			bodySizes: ["14px"],
			titleLineHeights: ["105%"],
			smallTitleLineHeights: ["110%"],
			subtitleLineHeights: ["120%"],
			bodyLineHeights: ["135%"],
			holdImage: "public_assets/1/styles/1/bulle_hold.png",
			knotImage: "public_assets/1/styles/1/bulle_knot.png",
			solidBackground1: "#000",
			solidBackground2: "#000",
			solidBackground3: "#000",
			signatureBackgroundImage: "",
		},
	]
}

export function fakeLayoutPacks(now: Date): LayoutPack[] {
	return [
		{
			id: 1,
			name: "souvenir",
			layouts: [
				{
					id: 1,
					name: "souvenir_introduction",
					role: "INTRODUCTION",
					kind: "TEMPLATE",
					cssPath:
						"public_assets/-1/styles/-1/souvenir_introduction.css",
					textCount: 2,
					pictureCount: 0,
					usesDarkMode: false,
					texts: [
						{
							id: 55,
							text: "Un grand merci pour cette année haute en couleurs[INTRO]",
							level: "TITLE",
							position: 1,
						},
						{
							id: 56,
							text: "Je tenais à t'envoyer un immense merci pour cette année de folie que nous avons vécue ensemble. Sérieusement, qui aurait pensé que notre vie serait aussi pleine de rebondissements et de surprises ? Certainement pas moi, mais je suis tellement reconnaissant que tu aies fait partie de cette aventure avec moi.",
							level: "SUBTITLE",
							position: 2,
						},
					],
					pictures: [],
					createdAt: now,
					updatedAt: now,
				},
				{
					id: 2,
					name: "souvenir_title",
					role: "TITLE",
					kind: "TEMPLATE",
					cssPath: "public_assets/-1/styles/-1/souvenir_title.css",
					usesDarkMode: false,
					textCount: 1,
					pictureCount: 1,
					texts: [
						{
							id: 57,
							text: "Un grand merci pour cette année haute en couleurs[TITLE]",
							level: "TITLE",
							position: 1,
						},
					],
					pictures: [
						{
							id: 58,
							path: "public_assets/-1/pictures/-1/souvenir_title_img1.png",
							position: 2,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
					],
					createdAt: now,
					updatedAt: now,
				},
				{
					id: 3,
					name: "souvenir_message",
					role: "MESSAGE",
					kind: "TEMPLATE",
					cssPath: "public_assets/-1/styles/-1/souvenir_message.css",
					textCount: 1,
					pictureCount: 3,
					usesDarkMode: false,
					texts: [
						{
							id: 59,
							text: "Un grand merci pour cette année haute en couleurs[MESSAGE]",
							level: "TITLE",
							position: 1,
						},
					],
					pictures: [
						{
							id: 60,
							path: "public_assets/-1/pictures/-1/souvenir_message_img1.png",
							position: 2,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
						{
							id: 61,
							path: "public_assets/-1/pictures/-1/souvenir_message_img2.png",
							position: 3,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
						{
							id: 62,
							path: "public_assets/-1/pictures/-1/souvenir_message_img3.png",
							position: 4,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
						{
							id: 63,
							path: "public_assets/-1/pictures/-1/souvenir_message_img4.png",
							position: 5,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
					],
					createdAt: now,
					updatedAt: now,
				},
				{
					id: 3,
					name: "souvenir_preview",
					role: "PREVIEW",
					kind: "TEMPLATE",
					cssPath: "public_assets/-1/styles/-1/souvenir_preview.css",
					textCount: 1,
					pictureCount: 3,
					usesDarkMode: false,
					texts: [
						{
							id: 64,
							text: "Un grand merci pour cette année haute en couleurs[PREVIEW]",
							level: "TITLE",
							position: 1,
						},
					],
					pictures: [
						{
							id: 65,
							path: "public_assets/-1/pictures/-1/souvenir_preview_img1.png",
							position: 2,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
						{
							id: 66,
							path: "public_assets/-1/pictures/-1/souvenir_preview_img2.png",
							position: 3,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
						{
							id: 67,
							path: "public_assets/-1/pictures/-1/souvenir_preview_img3.png",
							position: 4,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
						{
							id: 68,
							path: "public_assets/-1/pictures/-1/souvenir_preview_img4.png",
							position: 5,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
					],
					createdAt: now,
					updatedAt: now,
				},
			],
			createdAt: now,
			updatedAt: now,
		},
		{
			id: 2,
			name: "communication",
			layouts: [
				{
					id: 4,
					name: "communication_introduction",
					role: "INTRODUCTION",
					kind: "TEMPLATE",
					cssPath:
						"public_assets/-1/styles/-1/communication_introduction.css",
					usesDarkMode: false,
					textCount: 1,
					pictureCount: 0,
					texts: [
						{
							id: 69,
							text: "Merci pour cette année haute en couleurs",
							level: "TITLE",
							position: 1,
						},
					],
					pictures: [],
					createdAt: now,
					updatedAt: now,
				},
				{
					id: 5,
					name: "communication_title",
					role: "TITLE",
					kind: "TEMPLATE",
					cssPath:
						"public_assets/-1/styles/-1/communication_title.css",
					usesDarkMode: false,
					textCount: 2,
					pictureCount: 0,
					texts: [
						{
							id: 70,
							text: "Un grand merci pour cette année haute en couleurs",
							level: "TITLE",
							position: 1,
						},
						{
							id: 71,
							text: "Je tenais à t'envoyer un immense merci pour cette année de folie que nous avons vécue ensemble[TITLE]. Sérieusement, qui aurait pensé que notre vie serait aussi pleine de rebondissements et de surprises ? Certainement pas moi, mais je suis tellement reconnaissant que tu aies fait partie de cette aventure avec moi.",
							level: "SUBTITLE",
							position: 2,
						},
					],
					pictures: [],
					createdAt: now,
					updatedAt: now,
				},
				{
					id: 6,
					name: "communication_messsage",
					role: "MESSAGE",
					kind: "TEMPLATE",
					cssPath:
						"public_assets/-1/styles/-1/communication_message.css",
					usesDarkMode: false,
					textCount: 1,
					pictureCount: 0,
					texts: [
						{
							id: 72,
							text: "Je tenais à t'envoyer un immense merci pour cette année de folie que nous avons vécue ensemble[MESSAGE]. Sérieusement, qui aurait pensé que notre vie serait aussi pleine de rebondissements et de surprises ? Certainement pas moi, mais je suis tellement reconnaissant que tu aies fait partie de cette aventure avec moi.",
							level: "SUBTITLE",
							position: 2,
						},
					],
					pictures: [],
					createdAt: now,
					updatedAt: now,
				},
				{
					id: 6,
					name: "communication_messsage",
					role: "PREVIEW",
					kind: "TEMPLATE",
					cssPath:
						"public_assets/-1/styles/-1/communication_message.css",
					usesDarkMode: false,
					textCount: 1,
					pictureCount: 0,
					texts: [
						{
							id: 73,
							text: "PREVIEW test",
							level: "TITLE",
							position: 2,
						},
					],
					pictures: [],
					createdAt: now,
					updatedAt: now,
				},
			],
			createdAt: now,
			updatedAt: now,
		},
		{
			id: 3,
			name: "annonce",
			layouts: [
				{
					id: 7,
					name: "annonce_messsage",
					role: "INTRODUCTION",
					kind: "TEMPLATE",
					cssPath:
						"public_assets/-1/styles/-1/annonce_introduction.css",
					usesDarkMode: false,
					textCount: 1,
					pictureCount: 0,
					texts: [
						{
							id: 74,
							text: "Un grand merci pour cette année haute en couleurs",
							level: "TITLE",
							position: 1,
						},
					],
					pictures: [],
					createdAt: now,
					updatedAt: now,
				},
				{
					id: 8,
					name: "annonce_title",
					role: "TITLE",
					kind: "TEMPLATE",
					cssPath: "public_assets/-1/styles/-1/annonce_title.css",
					usesDarkMode: false,
					textCount: 1,
					pictureCount: 0,
					texts: [
						{
							id: 75,
							text: "Je tenais à t'envoyer un immense merci pour cette année de folie que nous avons vécue ensemble. Sérieusement, qui aurait pensé que notre vie serait aussi pleine de rebondissements et de surprises ? Certainement pas moi, mais je suis tellement reconnaissant que tu aies fait partie de cette aventure avec moi.",
							level: "SUBTITLE",
							position: 1,
						},
					],
					pictures: [],
					createdAt: now,
					updatedAt: now,
				},
				{
					id: 9,
					name: "annonce_messsage",
					role: "MESSAGE",
					kind: "TEMPLATE",
					cssPath: "public_assets/-1/styles/-1/annonce_message.css",
					usesDarkMode: false,
					textCount: 1,
					pictureCount: 1,
					texts: [
						{
							id: 76,
							text: "Un grand merci pour cette année haute en couleurs.",
							level: "TITLE",
							position: 1,
						},
					],
					pictures: [
						{
							id: 77,
							path: "public_assets/-1/pictures/-1/annonce_message_img1.png",
							position: 2,
							containerSize: "xl",
							containerStyle: "style1",
							imgSize: "lg",
							isBackground: false,
						},
					],
					createdAt: now,
					updatedAt: now,
				},
			],
			createdAt: now,
			updatedAt: now,
		},
	]
}

export function fakeCommands(now: Date): Command[] {
	return [
		{
			id: 1,
			client: {
				id: 1,
				company: "Apple",
				createdAt: now,
				updatedAt: now,
			},
			status: "PENDING",
			knot_quantity: 5,
			remaining_knots: 5,
			order_number: 1289523,
			knot_color: "Bleu",
			knot_size: "Moyen",
			knots: undefined,
			createdAt: now,
			updatedAt: now,
		},
		{
			id: 2,
			client: {
				id: 1,
				company: "Apple",
				createdAt: now,
				updatedAt: now,
			},
			status: "PENDING",
			knot_quantity: 5,
			remaining_knots: 5,
			order_number: 1289523,
			knot_color: "Rouge",
			knot_size: "Moyen",
			knots: undefined,
			createdAt: now,
			updatedAt: now,
		},
		{
			id: 3,
			client: {
				id: 2,
				company: "Microsoft",
				createdAt: now,
				updatedAt: now,
			},
			status: "REGISTERED",
			knot_quantity: 5,
			remaining_knots: 5,
			order_number: 1289523,
			knot_color: "Beige",
			knot_size: "Grand",
			knots: undefined,
			createdAt: now,
			updatedAt: now,
		},
		{
			id: 3,
			client: {
				id: 2,
				company: "Lancia",
				createdAt: now,
				updatedAt: now,
			},
			status: "DISABLED",
			knot_quantity: 5,
			remaining_knots: 5,
			order_number: 1359524,
			knot_color: "Beige",
			knot_size: "Grand",
			knots: undefined,
			createdAt: now,
			updatedAt: now,
		},
	]
}
