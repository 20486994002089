import { useCallback, useState } from "react"
import { Client } from "../domain/client"
import ProjectClient from "../domain/adapters/secondary/projectClient"
import { useIntl } from "react-intl"
import ClientClient from "../domain/adapters/secondary/clientClient"

export default function useSelectClientUseCase(
	clientClient: ClientClient,
	projectClient: ProjectClient,
) {
	const intl = useIntl()

	const currentProjectId = projectClient.currentProjectId
	const currentStoreGift = projectClient.projects.find(
		(proj) => proj.id === currentProjectId,
	)?.gifts[0]
	const getAllClients = clientClient.getAllClients

	const [selectedClient, setSelectedClient] = useState(
		currentStoreGift?.command?.client?.id ?? "",
	)
	const [availableClients, setAvailableClients] = useState([] as Client[])
	const [loadClientsError, setLoadClientsError] = useState<string | null>(
		null,
	)

	const loadClients = useCallback(async () => {
		try {
			const availableClients = await getAllClients()
			setAvailableClients(availableClients)
		} catch (err: unknown) {
			console.error(
				(err as Error)?.message ??
					"une erreur s'est produite lors du chargement des clients",
			)
			setLoadClientsError(
				intl.formatMessage({
					description: "",
					defaultMessage:
						"Une erreur s'est produite lors du chargement des clients.",
				}),
			)
		}
	}, [getAllClients, intl])

	const selectedClientChanged = useCallback((client: string) => {
		setSelectedClient(client)
	}, [])

	if (currentStoreGift?.id === undefined) {
		throw new Error("[selectClientUseCase] missing current gift!")
	}

	return {
		// clients
		availableClients,
		selectedClient,
		loadClientsError,
		loadClients,
		selectedClientChanged,

		// navigation
		currentStoreGift,
		currentProjectId,
	}
}
