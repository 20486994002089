export class ConfigurationHelper {
	static readConfiguration() {
		const maxImageWeightInMb = Number(
			process.env.REACT_APP_MAX_IMAGE_WEIGHT_MB,
		)

		if (Number.isNaN(maxImageWeightInMb)) {
			throw new Error(
				"invalid configuration REACT_APP_MAX_IMAGE_WEIGHT_MB",
			)
		}

		const validMimeTypes =
			process.env.REACT_APP_VALID_MIME_TYPES_IMAGES!.split(";")

		const maxPdfWeightInMb = Number(process.env.REACT_APP_MAX_PDF_WEIGHT_MB)
		if (Number.isNaN(maxPdfWeightInMb)) {
			throw new Error("invalid configuration REACT_APP_MAX_PDF_WEIGHT_MB")
		}

		const maxCsvFileWeightInMb = Number(
			process.env.REACT_APP_MAX_CSV_WEIGHT_MB,
		)
		if (Number.isNaN(maxCsvFileWeightInMb)) {
			throw new Error("invalid configuration REACT_APP_MAX_CSV_WEIGHT_MB")
		}

		return {
			validMimeTypes,
			maxImageWeightInMb,
			maxPdfWeightInMb,
			maxCsvFileWeightInMb,
		}
	}
}
