import React from "react"
import LayoutCard from "./LayoutCard"
import { BoxProps, HStack } from "@chakra-ui/react"
import { Template } from "../domain/template"
import { Layout, LayoutRole } from "../domain/layout"
import * as CSS from "csstype"
import { Client } from "../domain/client"
import Gift from "../domain/gift"

export type LayoutsStackProps = BoxProps & {
	layouts: Layout[]
	gift: Gift
	template: Template
	excludedRole: LayoutRole
	sender: Client
	cardWidth: CSS.Property.Width | number
}

export default function LayoutsStack({
	gift,
	template,
	layouts,
	excludedRole,
	cardWidth,
	...props
}: LayoutsStackProps): React.JSX.Element {
	return (
		<HStack spacing={"24px"} {...props}>
			{layouts
				.filter((layout) => layout.role !== excludedRole)
				.map((layout, idx) => (
					<LayoutCard
						key={idx}
						index={idx}
						gift={gift}
						template={template}
						layout={layout}
						sender={props.sender}
						w={cardWidth}
					/>
				))}
		</HStack>
	)
}
