import { Button, ButtonGroup, Flex, Heading, Text } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import React, { useEffect, useState } from "react"
import { Link as DomLink } from "react-router-dom"

import PrimaryButton from "../components/PrimaryButton"
import ProjectsTable from "../components/ProjectsTable"

import useLoadProjectsUseCase from "../useCases/loadProjectsUseCase"

import useProjectStore from "../stores/projectStore"
import SlicesHelper from "../stores/slices/helpers/slicesHelper"

import Project from "../domain/project"

function ProjectTypeSwitchButton(props: {
	children: React.JSX.Element
	active: boolean
	onClick: () => void
}): React.JSX.Element {
	return (
		<Button
			w="fit-content"
			h="fit-content"
			p="0"
			color={props.active ? "#2E7B72" : "#969FA9"}
			fontFamily="DM Serif Display"
			fontSize="20px"
			fontWeight="400"
			lineHeight="20px"
			_hover={{ backgroundColor: "transparent" }}
			onClick={props.onClick}
		>
			{props.children}
		</Button>
	)
}

export default function Projects() {
	const projectClient = useProjectStore(SlicesHelper.getProjectClient)
	const { projects, loadProjects, resetCurrentProject } =
		useLoadProjectsUseCase(projectClient)

	const [localProjects, setLocalProjects] = useState([] as Project[])

	const [projectsToShow, setProjectsToShow] = useState([] as Project[])
	const [showingCurrentProjects, setShowingCurrentProjects] = useState(true)

	function getProjectsCurrent(p: Project[]) {
		return p.filter((project) => {
			if (!project?.gifts[0]?.event?.date) return false
			return new Date(project.gifts[0].event.date) >= new Date()
		})
	}

	function getProjectsFinished(p: Project[]) {
		return p.filter((project) => {
			if (!project?.gifts[0]?.event?.date) return false
			return new Date(project.gifts[0].event.date) < new Date()
		})
	}

	useEffect(() => {
		resetCurrentProject()
		loadProjects().then(() => setLocalProjects(projects))
	}, [])

	useEffect(() => {
		setProjectsToShow(getProjectsCurrent(projects))
	}, [localProjects])

	return (
		<Flex gap="40px" flexDirection="column" padding="80px" h="100%">
			<style>{`#root div.main-navbar { display: flex; }`}</style>
			<Flex justifyContent="space-between" w="100%">
				<Heading fontFamily="DM Serif Display" fontWeight="400">
					<FormattedMessage
						description="projectsPageTitle1"
						defaultMessage="Projets"
					/>
					&nbsp;
					<span style={{ color: "#F17937" }}>
						<FormattedMessage
							description="projectsPageTitle2"
							defaultMessage="cadeaux"
						/>
					</span>
				</Heading>
				<ButtonGroup>
					<PrimaryButton
						as={DomLink}
						to="/project/creation"
						size="lg"
					>
						<FormattedMessage
							description="homePageCreatePresentButton"
							defaultMessage="Créer un Présent"
						></FormattedMessage>
					</PrimaryButton>
				</ButtonGroup>
			</Flex>

			<Flex flexDirection="column" gap="14px">
				<Flex gap="16px" alignItems="center">
					<ProjectTypeSwitchButton
						active={showingCurrentProjects}
						onClick={() => {
							setProjectsToShow(getProjectsCurrent(projects))
							setShowingCurrentProjects(true)
						}}
					>
						<FormattedMessage
							description="projectsPageListPendingProjects"
							defaultMessage="Mes projets en cours"
						/>
					</ProjectTypeSwitchButton>
					<Text
						fontFamily="DM Serif Display"
						fontSize="20px"
						fontWeight="400"
						lineHeight="25px"
						color="#02172D"
					>
						•
					</Text>
					<ProjectTypeSwitchButton
						active={!showingCurrentProjects}
						onClick={() => {
							setProjectsToShow(getProjectsFinished(projects))
							setShowingCurrentProjects(false)
						}}
					>
						<FormattedMessage
							description="projectsPageListFinishedProjects"
							defaultMessage="Mes anciens projets"
						/>
					</ProjectTypeSwitchButton>
				</Flex>
				<ProjectsTable
					projects={projectsToShow}
					limit={projects.length}
				/>
			</Flex>
		</Flex>
	)
}
