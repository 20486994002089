import { StateCreator } from "zustand"
import { GiftEventInfos, Opening, ProjectStoreState } from "../../projectStore"
import GiftClient from "../../../domain/adapters/secondary/giftClient"
import Gift, { getGiftDate, OpeningMode } from "../../../domain/gift"
import { fetchApi } from "../../storeHelper"
import { Receiver } from "../../../domain/receiver"
import GiftEvent from "../../../domain/giftEvent"
import OpeningGame from "../../../domain/openingGame"
import { Command } from "../../../domain/command"

export const createGiftSlice: StateCreator<
	ProjectStoreState,
	[["zustand/immer", never]],
	[],
	GiftClient
> = (set, get) => ({
	giftPictureFileNameById: {},
	currentGiftReceivers: [],
	currentReceiverTrackingId: undefined,
	openedGift: undefined,

	addGift: async (projectId: number, gift: Pick<Gift, "type">) => {
		const newGift = await fetchApi<Gift>(
			`projects/${projectId}/gifts`,
			"POST",
			JSON.stringify(gift),
		)
		set((state) => {
			const projIdx = state.projects.findIndex(
				(proj) => proj.id === projectId,
			)
			state.projects[projIdx].gifts.push(newGift)
		})
		return newGift
	},
	updateGift: async (
		projectId: number,
		giftId: number,
		gift: Pick<Gift, "type">,
	) => {
		const updatedGift = await fetchApi<Gift>(
			`projects/${projectId}/gifts/${giftId}/type`,
			"PUT",
			JSON.stringify(gift),
		)
		set((state) => {
			const projIdx = state.projects.findIndex(
				(proj) => proj.id === projectId,
			)
			const giftIdx = state.projects[projIdx].gifts.findIndex(
				(g) => g.id === giftId,
			)
			state.projects[projIdx].gifts[giftIdx].type = gift.type
		})
		return updatedGift
	},
	initGiftEvent: async (projectId: number, giftId: number) => {
		set((state) => {
			const projIdx = state.projects.findIndex(
				(proj) => proj.id === projectId,
			)
			const giftIdx = state.projects[projIdx].gifts.findIndex(
				(g) => g.id === giftId,
			)
			state.projects[projIdx].gifts[giftIdx].event = {
				date: undefined,
				id: undefined,
				occasion: undefined,
				receivers: undefined,
				sendingMode: undefined,
			}
		})
	},
	updateGiftSendingMode: async (
		projectId: number,
		giftId: number,
		sendingMode: "UNIQUE" | "MULTIPLE",
	) => {
		set((state) => {
			const projIdx = state.projects.findIndex(
				(proj) => proj.id === projectId,
			)
			const giftIdx = state.projects[projIdx].gifts.findIndex(
				(g) => g.id === giftId,
			)
			const event = state.projects[projIdx].gifts[giftIdx].event
			if (event !== undefined) {
				event.sendingMode = sendingMode
			}
		})
	},
	currentGiftReceiversChanged: (_: number, receivers: Receiver[]) => {
		set((state) => {
			state.currentGiftReceivers = receivers
		})
	},
	updateGiftEventInfos: async (projectId: number, infos: GiftEventInfos) => {
		if (
			(infos.receivers?.length ?? 0) > 0 &&
			infos.sendingMode === undefined
		) {
			infos.sendingMode =
				(infos.receivers?.length ?? 0) > 1 ? "MULTIPLE" : "UNIQUE"
		}

		const updatedEvent = await fetchApi<GiftEvent>(
			`projects/${projectId}/gifts/${infos.giftId}/event`,
			"PUT",
			JSON.stringify({ event: infos }),
		)

		set((state) => {
			const projIdx = state.projects.findIndex(
				(proj) => proj.id === projectId,
			)
			const giftIdx = state.projects[projIdx].gifts.findIndex(
				(g) => g.id === infos.giftId,
			)
			state.projects[projIdx].gifts[giftIdx].event = updatedEvent
		})
		return updatedEvent
	},
	updateGiftOpeningMode: async (
		projectId: number,
		giftId: number,
		openingMode: OpeningMode,
		openingDate?: Date,
		openingGame?: OpeningGame,
	) => {
		const updateOpeningBody = {
			openingMode,
			openingDate,
			openingGame,
		}
		const opening = await fetchApi<Opening>(
			`projects/${projectId}/gifts/${giftId}/openingMode`,
			"PUT",
			JSON.stringify(updateOpeningBody),
		)
		set((state) => {
			const projIdx = state.projects.findIndex(
				(proj) => proj.id === projectId,
			)
			const giftIdx = state.projects[projIdx].gifts.findIndex(
				(g) => g.id === giftId,
			)
			state.projects[projIdx].gifts[giftIdx].openingMode =
				opening.openingMode
			state.projects[projIdx].gifts[giftIdx].openingDate =
				opening.openingDate
					? getGiftDate(opening.openingDate!)!.toISOString()
					: undefined
			state.projects[projIdx].gifts[giftIdx].openingGame =
				opening.openingGame
		})
		return opening
	},
	retrieveGift: async (projectId: number, giftId: number) => {
		const gift = await fetchApi<Gift>(
			`projects/${projectId}/gifts/${giftId}`,
			"GET",
		)
		set((state) => {
			state.openedGift = gift
		})
		return gift
	},
	setCurrentReceiver: async (receiverTrackingId: string) => {
		set((state) => {
			state.currentReceiverTrackingId = receiverTrackingId
		})
	},
	sendGiftByEmail: async (
		projectId: number,
		giftId: number,
		email: string | undefined,
	) => {
		const receiverTrackingId = get().currentReceiverTrackingId
		if ((receiverTrackingId?.length ?? 0) === 0) {
			throw new Error("invalid current receiver!")
		}
		const { success } = await fetchApi<{
			success: boolean
		}>(
			`projects/${projectId}/gifts/${giftId}/sendByEmail?nSub=${get()
				.currentReceiverTrackingId!}${email ? `&email=${email}` : ""}`,
			"GET",
		)
		return success
	},
	getReceiver: async (projectId: number, giftId: number) => {
		const receiverTrackingId = get().currentReceiverTrackingId
		if ((receiverTrackingId?.length ?? 0) === 0) {
			throw new Error("invalid current receiver!")
		}
		const { receiver } = await fetchApi<{
			receiver: Receiver
		}>(
			`projects/${projectId}/gifts/${giftId}/receiver/${get()
				.currentReceiverTrackingId!}`,
			"GET",
		).catch(() => ({ receiver: undefined }))
		return receiver
	},
	setCurrentGiftCommand: async (
		projectId: number,
		giftId: number,
		command: Command,
	): Promise<void> => {
		set((state) => {
			const projectIdx = state.projects.findIndex(
				(proj) => proj.id === projectId,
			)
			const giftIdx = state.projects[projectIdx].gifts.findIndex(
				(g) => g.id === giftId,
			)
			state.projects[projectIdx].gifts[giftIdx].command = command
		})
	},
	notifyCreatorGiftOpening: async (
		projectId: number,
		giftId: number,
	): Promise<{ success: boolean }> => {
		const receiverTrackingId = get().currentReceiverTrackingId
		if ((receiverTrackingId?.length ?? 0) === 0) {
			throw new Error("invalid current receiver!")
		}

		const { success } = await fetchApi<{ success: boolean }>(
			`projects/${projectId}/gifts/${giftId}/notifyOpening?nSub=${get()
				.currentReceiverTrackingId!}`,
			"GET",
		)
		return { success }
	},
})
