import React from "react"
import { Image, ImageProps } from "@chakra-ui/react"

export default function Xs2Image({
	...imgProps
}: ImageProps): React.JSX.Element {
	return (
		<Image
			mx="16px"
			ml="auto"
			position="relative"
			top="34%"
			{...imgProps}
		/>
	)
}
