import React, { MouseEventHandler } from "react"
import { BoxProps, Heading, Text } from "@chakra-ui/react"
import { Text as ModelText, TextLevel } from "../domain/text"
import { TitleHeading } from "./TitleHeading"
import { SmallTitleHeading } from "./SmallTitleHeading"
import { SubtitleHeading } from "./SubtitleHeading"
import { ParagraphText } from "./ParagraphText"

export type Props = Omit<BoxProps, "text"> & {
	text: ModelText
	typedIndex: number
	onClicked?: MouseEventHandler<HTMLElement>
	// TEMP isolate changes for opening part (YLA)
	isOpening?: boolean
}

export default function LayoutTextBlock({
	text,
	typedIndex,
	onClicked,
	isOpening,
	...boxProps
}: Props): React.JSX.Element {
	const NonOpeningTitleHeading = () => (
		<Heading
			as="h2"
			key={`text-${typedIndex}`}
			onClick={onClicked}
			{...boxProps}
		>
			{text.text}
		</Heading>
	)

	const NonOpeningSubtitleHeading = () => (
		<Heading as="h3" key={`text-${typedIndex}`} {...boxProps}>
			{text.text}
		</Heading>
	)

	const NonOpeningParagraphText = () => (
		<Text key={`text-${typedIndex}`} onClick={onClicked} {...boxProps}>
			{text.text}
		</Text>
	)

	const componentSelector: Record<
		TextLevel,
		(isOpening: boolean) => React.JSX.Element
	> = {
		TITLE: (isOpening) =>
			isOpening ? (
				<TitleHeading
					text={text.text}
					typedIndex={typedIndex}
					onClicked={onClicked}
					{...boxProps}
				/>
			) : (
				<NonOpeningTitleHeading />
			),
		SMALL_TITLE: (isOpening) =>
			isOpening ? (
				<SmallTitleHeading
					text={text.text}
					typedIndex={typedIndex}
					onClicked={onClicked}
					{...boxProps}
				/>
			) : (
				<NonOpeningTitleHeading />
			),
		SUBTITLE: (isOpening) =>
			isOpening ? (
				<SubtitleHeading
					text={text.text}
					typedIndex={typedIndex}
					onClicked={onClicked}
					{...boxProps}
				/>
			) : (
				<NonOpeningSubtitleHeading />
			),
		PARAGRAPH: (isOpening) =>
			isOpening ? (
				<ParagraphText
					text={text.text}
					typedIndex={typedIndex}
					onClicked={onClicked}
					{...boxProps}
				/>
			) : (
				<NonOpeningParagraphText />
			),
		DESCRIPTION: (isOpening) =>
			isOpening ? (
				<ParagraphText
					text={text.text}
					typedIndex={typedIndex}
					onClicked={onClicked}
					{...boxProps}
				/>
			) : (
				<NonOpeningParagraphText />
			),
	}

	return componentSelector[text.level](isOpening ?? false)
}
