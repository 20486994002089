import { GiftType } from "../domain/gift"
import {
	Box,
	Card,
	ComponentWithAs,
	Heading,
	IconProps,
	Text,
} from "@chakra-ui/react"
import React from "react"

import "./LinkType.css"

export type LinkType = {
	id: number
	type: GiftType
	icon: ComponentWithAs<"svg", IconProps>
	title: string
	smallText: string
	isSelected: boolean
}

export default function LinkTypeComp(
	linkType: LinkType,
	hasAnySelection: boolean,
	clickHandler: (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>,
		lt: LinkType,
	) => void,
): React.JSX.Element {
	return (
		<Card
			key={linkType.id}
			className={`${linkType.isSelected ? "selectedLinkType" : ""}${
				hasAnySelection && !linkType.isSelected
					? " unselectedLinkType"
					: ""
			}`}
			bgColor="rgba(255, 255, 255, 0.5)"
			p="4px"
			h="auto"
			borderRadius="24px"
			onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
				clickHandler(e, linkType)
			}
		>
			<Box
				pb="22.9px"
				mx="96px"
				bgColor="linear-gradient(180deg, rgba(255, 255, 255, 0.32) 0%, rgba(255, 255, 255, 0.8) 100%)"
				as={linkType.icon}
				w="fit-content"
				flexGrow="1"
				margin="0 auto"
			/>
			<Heading
				size="sm"
				mb="28px"
				alignSelf="center"
				mx="12px"
				lineHeight="120%"
				color="var(--var-heading-xsmall-color)"
				fontFamily="Nunito Sans"
				fontWeight="700"
				fontStyle="normal"
				textAlign="center"
			>
				{linkType.title}
			</Heading>
			<Text
				fontSize="12px"
				mx="12px"
				mb="28px"
				color="var(--var-text-xxsmall)"
				alignSelf="stretch"
				lineHeight="120%"
				fontFamily="Nunito Sans"
				fontWeight="400"
				fontStyle="normal"
				textAlign="center"
				opacity="0.6"
				w="220px"
			>
				{linkType.smallText}
			</Text>
		</Card>
	)
}
