import { Step } from "../components/ProjectBreadcrumb"
import {
	CustomEditIcon,
	EventIcon,
	GiftIcon,
	OpeningGamesIcon,
	WrappingIcon,
} from "./StepIcons"
import { GiftCompletedStep } from "../domain/gift"

const steps: Step[] = [
	{
		name: "Cadeaux",
		color: "rgba(99, 43, 217, 1)",
		icon: GiftIcon(),
		isActive: false,
		canNavigateTo: false,
		navigateTo: (projectId: string) => `/project/creation?id=${projectId}`,
	},
	{
		name: "Évènement",
		color: "rgba(220, 70, 115, 1)",
		icon: EventIcon(),
		isActive: false,
		canNavigateTo: false,
		navigateTo: (projectId: string, giftId: string) =>
			`/projects/${projectId}/gifts/${giftId}/receiversMode`,
	},
	{
		name: "Édition",
		color: "rgba(0, 122, 255, 1)",
		icon: CustomEditIcon(),
		isActive: false,
		canNavigateTo: false,
		navigateTo: (projectId: string, giftId: string) =>
			`/projects/${projectId}/gifts/${giftId}/template`,
	},
	{
		name: "Jeux d'ouverture",
		color: "rgba(59, 133, 143, 1)",
		icon: OpeningGamesIcon(),
		isActive: false,
		canNavigateTo: false,
		navigateTo: (projectId: string, giftId: string) =>
			`/projects/${projectId}/gifts/${giftId}/openingMode`,
	},
	{
		name: "Emballage",
		color: "rgba(223, 112, 48, 1)",
		icon: WrappingIcon(),
		isActive: false,
		canNavigateTo: false,
		navigateTo: (projectId: string, giftId: string) =>
			`/projects/${projectId}/gifts/${giftId}/packaging`,
	},
]

export type ActiveStep =
	| "Cadeaux"
	| "Évènement"
	| "Édition"
	| "Jeux d'ouverture"
	| "Emballage"

function isSameOrBefore(step: Step, completedStep: GiftCompletedStep) {
	const orderedSteps: ActiveStep[] = [
		"Cadeaux",
		"Évènement",
		"Édition",
		"Jeux d'ouverture",
		"Emballage",
	]
	const orderedCompletedSteps: GiftCompletedStep[] = [
		"GIFT",
		"EVENT",
		"EDITION",
		"OPENING_GAME",
		"PACKAGING",
	]
	return (
		completedStep !== "NONE" &&
		orderedSteps.indexOf(step.name as ActiveStep) <=
			orderedCompletedSteps.indexOf(completedStep)
	)
}
export function getSteps(
	activeStep?: ActiveStep,
	reachedStep?: GiftCompletedStep,
) {
	const hasActiveStep = (activeStep?.length ?? 0) > 0
	return steps.map(
		(s) =>
			({
				...s,
				isActive: hasActiveStep && s.name === activeStep,
				canNavigateTo: reachedStep && isSameOrBefore(s, reachedStep),
			}) as Step,
	)
}
