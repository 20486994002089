import { StateCreator } from "zustand"
import { ProjectStoreState } from "../../projectStore"
import AnswerClient from "../../../domain/adapters/secondary/answerClient"
import { fetchApi } from "../../storeHelper"
import { AnswerCheckResult } from "../../../models/answerCheckResult"

export const createAnswerSlice: StateCreator<
	ProjectStoreState,
	[["zustand/immer", never]],
	[],
	AnswerClient
> = (set) => ({
	updateAnswer: (projectId: number, giftId: number, answer: string) => {
		return fetchApi<AnswerCheckResult>(
			`projects/${projectId}/gifts/${giftId}/check`,
			"PUT",
			JSON.stringify({ answer }),
		)
	},
})
