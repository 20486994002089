import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogCloseButton,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogOverlay,
	Text,
	Image,
	AlertDialogFooter,
	HStack,
} from "@chakra-ui/react"
import React, { useEffect, useRef } from "react"
import { FormattedMessage } from "react-intl"
import ReactCrop, {
	convertToPixelCrop,
	PercentCrop,
	PixelCrop,
} from "react-image-crop"
import { File } from "./FileUpload"
import {
	useImageEditor,
	useImageEditorDispatch,
} from "./contexts/imageEditorContext"

import "react-image-crop/dist/ReactCrop.css"
import ImageUpload from "./ImageUpload"
import { ConfigurationHelper } from "../helpers/configurationHelper"
import SecondaryButton from "./SecondaryButton"
import PrimaryButton from "./PrimaryButton"
import { UploadFile } from "antd"
import { RenderItem } from "../domain/layout"
import { Picture } from "../domain/picture"

export default function ImageEditorPopup(): React.JSX.Element {
	if (process.env.REACT_APP_VALID_MIME_TYPES_IMAGES === undefined) {
		throw new Error("missing config VALID_MIME_TYPES_IMAGES")
	}

	const validImagesFormats =
		process.env.REACT_APP_VALID_MIME_TYPES_IMAGES.split(";")
			.map((mimeType) => mimeType.replaceAll("image/", ""))
			.join(",")

	const imagesMaxSize = Number(process.env.REACT_APP_MAX_IMAGE_WEIGHT_MB)
	if (Number.isNaN(imagesMaxSize)) {
		throw new Error("Invalid or missing config MAX_IMAGE_WEIGHT_MB")
	}

	const imageEditorContext = useImageEditor()
	const imageEditorDispatch = useImageEditorDispatch()

	const closeBtnRef = useRef({} as HTMLButtonElement)
	const imgRef = useRef({} as HTMLImageElement)

	const cropImage = async (crop: PixelCrop): Promise<Blob> => {
		if (
			imageEditorContext === undefined ||
			imageEditorContext?.blob === undefined
		) {
			throw new Error("invalid picture provided (no path found!)")
		}

		const imgBitmap = await createImageBitmap(imageEditorContext.blob)

		// crop
		const scaleX = imgBitmap.width / imgRef.current.width
		const scaleY = imgBitmap.height / imgRef.current.height
		// devicePixelRatio slightly increases sharpness on retina devices
		// at the expense of slightly slower render times and needing to
		// size the image back down if you want to download/upload and be
		// true to the images natural size.
		const pixelRatio = window.devicePixelRatio
		// const pixelRatio = 1

		const canvasWidth = Math.floor(crop.width * scaleX * pixelRatio)
		const canvasHeight = Math.floor(crop.height * scaleY * pixelRatio)

		const offScreenCanvas = new OffscreenCanvas(
			canvasWidth,
			canvasHeight,
		) as OffscreenCanvas
		const drawingCtx = offScreenCanvas.getContext(
			"2d",
		) as OffscreenCanvasRenderingContext2D

		drawingCtx.scale(pixelRatio, pixelRatio)
		drawingCtx.imageSmoothingQuality = "high"

		const cropX = crop.x * scaleX
		const cropY = crop.y * scaleY

		const centerX = imgBitmap.width / 2
		const centerY = imgBitmap.height / 2

		drawingCtx.save()

		// 5) Move the crop origin to the canvas origin (0,0)
		drawingCtx.translate(-cropX, -cropY)
		// 4) Move the origin to the center of the original position
		drawingCtx.translate(centerX, centerY)
		// 1) Move the center of the image to the origin (0,0)
		drawingCtx.translate(-centerX, -centerY)
		drawingCtx.drawImage(
			imgBitmap,
			0,
			0,
			imgBitmap.width,
			imgBitmap.height,
			0,
			0,
			imgBitmap.width,
			imgBitmap.height,
		)

		drawingCtx.restore()

		return await offScreenCanvas.convertToBlob()
	}

	let cropGenTimeout = useRef(0)

	const onCropChanged = (pixelCrop: PixelCrop, _: PercentCrop) => {
		imageEditorDispatch!({
			type: "UPDATE",
			payload: {
				crop: pixelCrop,
				isCropPopupOpened: true,
				imageMode: "CROP",
				isUploadValid: true,
			},
		})
	}

	const onCropCompleted = async (crop: PixelCrop) => {
		try {
			if (cropGenTimeout.current > 0) {
				window.clearTimeout(cropGenTimeout.current)
			}

			cropGenTimeout.current = window.setTimeout(async () => {
				if (imageEditorContext!.completedCrop === undefined) {
					onImageLoad(
						{
							currentTarget: {
								width: imgRef.current.width,
								height: imgRef.current.height,
							},
						} as unknown as React.SyntheticEvent<HTMLImageElement>,
						imageEditorContext?.initialItem! as Picture,
					)
				}

				if (
					imageEditorContext!.completedCrop !== undefined &&
					crop.x === imageEditorContext!.completedCrop.x &&
					crop.y === imageEditorContext!.completedCrop.y
				) {
					return
				}

				if (crop.width === 0 || crop.height === 0) {
					return
				}

				onCropChanged(crop, undefined as unknown as PercentCrop)
				imageEditorDispatch!({ type: "START_CROP" })
				const croppedImgBlob = await cropImage(crop)
				imageEditorDispatch!({ type: "STOP_CROP" })
				imageEditorDispatch!({
					type: "COMPLETE",
					payload: {
						completedCrop: crop,
						dataUrl: URL.createObjectURL(croppedImgBlob),
						isCropPopupOpened: true,
						imageMode: "CROP",
						isUploadValid: true,
					},
				})
			}, 300)
		} catch {
			// silence error
		}
	}

	const dataUrlForCrop =
		(imageEditorContext?.blob &&
			URL.createObjectURL(imageEditorContext.blob)) ||
		undefined

	const { validMimeTypes, maxImageWeightInMb } =
		ConfigurationHelper.readConfiguration()

	const closePopup = () => {
		imageEditorDispatch!({ type: "CLOSE_CROP_POPUP" })
	}

	const computeCroppedWidth = (picture: Picture) => {
		const widthSelector: Record<string, number> = {
			"2xs": 189,
			xs: 204,
			sm: 208,
		}
		return picture.containerSize in widthSelector
			? widthSelector[picture.containerSize]
			: 254.38
	}

	const computeCroppedHeight = (picture: Picture) => {
		const heightSelector: Record<string, number> = {
			"2xs": 196,
			xs: 215,
			sm: 218,
		}
		return picture.containerSize in heightSelector
			? heightSelector[picture.containerSize]
			: 254.38
	}

	const makeCrop = (
		mediaWidth: number,
		mediaHeight: number,
		picture: Picture,
	) => ({
		unit: "px" as "px" | "%",
		x: mediaWidth / 2 - computeCroppedWidth(picture) / 2,
		y: mediaHeight / 2 - computeCroppedHeight(picture) / 2,
		width: computeCroppedWidth(picture),
		height: computeCroppedHeight(picture),
	})

	const onImageLoad = (
		e: React.SyntheticEvent<HTMLImageElement>,
		picture: Picture,
	) => {
		const { width, height } = e.currentTarget
		imageEditorDispatch!({
			type: "INIT_CROP",
			payload: {
				crop: makeCrop(width, height, picture),
				isCropPopupOpened: false,
				imageMode: "CROP",
				isUploadValid: true,
			},
		})
	}

	const validateUpload = (uploadedFile: UploadFile<File>) => {
		// uploadedFile.current!.response!.preview => blob
		// content.name => file name (full name with extension)

		imageEditorDispatch!({
			type: "IMAGE_MODE_CROP",
			payload: {
				blob: uploadedFile.response?.preview,
				isCropPopupOpened: true,
				imageMode: "UPLOAD",
				isUploadValid: true,
			},
		})
	}

	useEffect(() => {
		return () => {
			if (dataUrlForCrop !== undefined) {
				URL.revokeObjectURL(dataUrlForCrop)
			}
		}
	}, [dataUrlForCrop])

	return (
		<AlertDialog
			leastDestructiveRef={closeBtnRef}
			isOpen={imageEditorContext!.isCropPopupOpened}
			onClose={() => imageEditorDispatch!({ type: "CLOSE_CROP_POPUP" })}
			closeOnOverlayClick={false}
		>
			<AlertDialogOverlay />
			<AlertDialogContent>
				<AlertDialogHeader
					fontSize="lg"
					fontWeight="bold"
					textAlign="center"
				>
					<FormattedMessage
						description="ImageEditorPopupTitle"
						defaultMessage="Importez votre image"
					/>
				</AlertDialogHeader>
				<AlertDialogCloseButton
					ref={closeBtnRef}
					disabled={imageEditorContext?.isImageCropLoading ?? false}
				/>
				<AlertDialogBody>
					<Text>
						<FormattedMessage
							description="ImageEditorPopupAcceptedFormats"
							defaultMessage="formats acceptés: {formats}"
							values={{
								formats: validImagesFormats,
							}}
						/>
					</Text>
					<Text>
						<FormattedMessage
							description="ImageEditorPopupImageMaxSize"
							defaultMessage="Maximum: {maxSize}Mo"
							values={{
								maxSize: imagesMaxSize,
							}}
						/>
					</Text>
					{imageEditorContext?.imageMode === "UPLOAD" && (
						<ImageUpload
							validMimeTypes={validMimeTypes}
							maxWeightInMB={maxImageWeightInMb}
							onUpload={validateUpload}
						/>
					)}
					{imageEditorContext?.imageMode === "CROP" && (
						<ReactCrop
							crop={imageEditorContext?.crop!}
							minWidth={imageEditorContext?.crop?.width!}
							maxWidth={imageEditorContext?.crop?.width!}
							minHeight={imageEditorContext?.crop?.height!}
							maxHeight={imageEditorContext?.crop?.height!}
							aspect={
								imageEditorContext?.crop?.width! /
								imageEditorContext?.crop?.height!
							}
							onChange={onCropChanged}
							onComplete={onCropCompleted}
						>
							<Image
								src={dataUrlForCrop}
								ref={imgRef}
								onLoad={async () => {
									await onCropCompleted(
										convertToPixelCrop(
											imageEditorContext?.crop!,
											imgRef.current.width,
											imgRef.current.height,
										),
									)
								}}
							/>
						</ReactCrop>
					)}
				</AlertDialogBody>
				<AlertDialogFooter>
					<HStack>
						<SecondaryButton onClick={closePopup}>
							<FormattedMessage
								description="generalCancel"
								defaultMessage="Annuler"
							/>
						</SecondaryButton>
						<PrimaryButton
							isDisabled={
								imageEditorContext?.isUploadValid &&
								(imageEditorContext.isImageCropLoading ?? false)
							}
							isLoading={
								imageEditorContext?.isImageCropLoading ?? false
							}
							onClick={async () => {
								imageEditorContext?.onItemValidated &&
									(await imageEditorContext.onItemValidated({
										...imageEditorContext!.initialItem,
										croppedImgDataUrl:
											imageEditorContext!.dataUrl!,
									} as RenderItem))
								closePopup()
							}}
						>
							<FormattedMessage
								description="generalConfirm"
								defaultMessage="Confirmer"
							/>
						</PrimaryButton>
					</HStack>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}
