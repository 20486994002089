import React, { useMemo, useState } from "react"
import { Text, TextLevel } from "../domain/text"
import { Picture } from "../domain/picture"
import { Box, BoxProps, Image } from "@chakra-ui/react"
import { RenderItem } from "../domain/layout"
import InputWithValidation from "./InputWithValidation"
import { useIntl } from "react-intl"
import { useImageEditorDispatch } from "./contexts/imageEditorContext"

import "./TextOrPicture.css"

export type EditTextOrPictureProps = Omit<BoxProps, "onBlur"> & {
	item: RenderItem
	onItemValidated: (updatedItem: RenderItem) => Promise<void>
	onBlur: (value: string) => void
}

export function EditTextOrPicture({
	item,
	onItemValidated,
	onBlur,
	fontFamily,
	fontWeight,
	fontSize,
	color,
}: EditTextOrPictureProps): React.JSX.Element {
	const intl = useIntl()

	const textMaxLengthsByLevel: Record<TextLevel, number> = useMemo(
		() => ({
			TITLE: 40,
			SMALL_TITLE: 50,
			SUBTITLE: 50,
			PARAGRAPH: 250,
			DESCRIPTION: 150,
		}),
		[],
	)

	const textPlaceholdersByLevel: Record<TextLevel, string> = {
		TITLE: intl.formatMessage({
			description: "EditTextOrPictureTextTitlePlaceHolder",
			defaultMessage: "Votre titre",
		}),
		SMALL_TITLE: intl.formatMessage({
			description: "EditTextOrPictureTextTitlePlaceHolder",
			defaultMessage: "Votre titre",
		}),
		SUBTITLE: intl.formatMessage({
			description: "EditTextOrPictureTextSubtitlePlaceholder",
			defaultMessage: "Votre sous-titre",
		}),
		PARAGRAPH: intl.formatMessage(
			{
				description: "",
				defaultMessage:
					"Votre description ({maxLength} caractères maximum)",
			},
			{ maxLength: textMaxLengthsByLevel.PARAGRAPH },
		),
		DESCRIPTION: intl.formatMessage({
			description: "",
			defaultMessage:
				"Votre description ({maxLength} caractères maximum)",
		}),
	}

	const labelByLevel: Record<TextLevel, string> = useMemo(
		() => ({
			TITLE: intl.formatMessage({
				description: "EditTextOrPictureTitleLabel",
				defaultMessage: "titre",
			}),
			SMALL_TITLE: intl.formatMessage({
				description: "EditTextOrPictureTitleLabel",
				defaultMessage: "titre",
			}),
			SUBTITLE: intl.formatMessage({
				description: "EditTextOrPictureSubtitleLabel",
				defaultMessage: "sous-titre",
			}),
			PARAGRAPH: intl.formatMessage({
				description: "EditTextOrPictureParagraphLabel",
				defaultMessage: "texte",
			}),
			DESCRIPTION: intl.formatMessage({
				description: "EditTextOrPictureParagraphLabel",
				defaultMessage: "texte",
			}),
		}),
		[intl],
	)

	const [editedText, setEditedText] = useState(
		item.type === "text" ? (item as Text).text : "",
	)
	const [isValid, setIsValid] = useState(true)
	const hasEditedText = useMemo(
		() => (editedText?.length ?? 0) > 0,
		[editedText?.length],
	)

	const imageEditorDispatch = useImageEditorDispatch()

	const error = useMemo(
		() =>
			isValid
				? ""
				: intl.formatMessage(
						{
							description: "EditTextOrPictureErrorTextMessage",
							defaultMessage:
								"Votre {texte} ne doit pas dépasser les 40 caractères, sans caractères spéciaux",
						},
						{
							texte: labelByLevel[(item as Text).level],
							maxLength:
								textMaxLengthsByLevel[(item as Text).level],
						},
				  ),
		[isValid, intl, item, labelByLevel, textMaxLengthsByLevel],
	)

	const validateText = (value: string) => {
		const success =
			/[0-9a-zA-ZÀ-ÿ!? ]+$/.test(value) && (value?.length ?? 0) > 0
		setEditedText(value)
		setIsValid(success)
	}

	const renderEditText = (renderItem: RenderItem, idx: number) => {
		const text = renderItem as Text
		return (
			<Box
				className={`item item-text${renderItem.typeIndex + 1}`}
				data-value={editedText}
				_after={{
					content: `attr(data-value) " "`,
					visibility: "hidden",
					whiteSpace: "pre-wrap",
					fontFamily: fontFamily,
					fontSize: fontSize,
					fontWeight: fontWeight,
					color: color,
				}}
				my={"4px"}
			>
				<style>{`
					.item-text${renderItem.typeIndex + 1} { display: inline-grid; }
					.item-text${renderItem.typeIndex + 1}::after { grid-area: 1/1; }
					.item-text${renderItem.typeIndex + 1} .textArea-${
						renderItem.typeIndex + 1
					} {grid-area: 1/1; }
					.item-text${
						renderItem.typeIndex + 1
					} .chakra-form-control { height: 100%; margin-bottom: 0px; }
					.item-text${renderItem.typeIndex + 1} .chakra-input__group { height: 100%; }
					.item-text${
						renderItem.typeIndex + 1
					} textArea { padding: 0px; outline: none; border: none; height: 100%; }
					.item-text${
						renderItem.typeIndex + 1
					} textArea:focus { padding: 0px; outline: none; border: none; }
					`}</style>
				<InputWithValidation
					inputAs={"textarea"}
					autoHeight={["PARAGRAPH", "DESCRIPTION"].includes(
						text.level,
					)}
					defaultValue={editedText}
					isValid={isValid}
					error={error}
					maxLength={textMaxLengthsByLevel[text.level]}
					placeHolder={
						hasEditedText
							? editedText
							: textPlaceholdersByLevel[text.level]
					}
					onChange={validateText}
					onBlur={async () => {
						if (!isValid) {
							return
						}
						await onItemValidated({ ...item, text: editedText })
					}}
					onBlurEvenUnchangedOrInvalid={onBlur}
					index={idx}
					width="auto"
					p={"0px"}
					className={`textArea-${renderItem.typeIndex + 1}`}
					fontFamily={fontFamily}
					fontWeight={fontWeight}
					fontSize={fontSize}
					textAlign={"center"}
					overflow={"hidden"}
					textWrap={"balance"}
					color={color}
					borderRadius={"4px"}
					border={"1px dashed rgba(0, 0, 0, 0.30)"}
					containerBg={"rgba(255, 255, 255, 0.10)"}
					resize={"none"}
					background={"none"}
					appearance={"none"}
					showCheck={false}
					showPlaceholderOnFocus={false}
				/>
			</Box>
		)
	}

	const renderEditPicture = (renderItem: RenderItem, idx: number) => {
		const picture = renderItem as Picture
		return (
			<Box
				className={`item ${picture.containerSize} ${
					picture.containerStyle
				} item-picture${renderItem.typeIndex + 1}`}
				w="full"
			>
				<Image
					src={`${process.env.REACT_APP_API_URL}/${picture.path}`}
					key={`img-${idx}`}
					className={`picture picture-${
						picture.imgSize
					} pictureArea-${idx + 1}`}
					onClick={(e) => {
						imageEditorDispatch!({
							type: "OPEN_CROP_POPUP",
							payload: {
								onItemValidated,
								initialItem: renderItem,
								imageMode: "UPLOAD",
								isUploadValid: false,
								isCropPopupOpened: true,
							},
						})
					}}
				/>
			</Box>
		)
	}

	const renderer: Record<
		string,
		(renderItem: RenderItem, idx: number) => React.JSX.Element
	> = {
		text: renderEditText,
		picture: renderEditPicture,
	}

	return renderer[item.type](item, item.typeIndex)
}
