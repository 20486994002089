import GiftClient from "../domain/adapters/secondary/giftClient"
import { useIntl } from "react-intl"
import { useGiftStoreDependencies } from "../components/hooks/useGiftStoreDependencies"
import { useCallback, useState } from "react"
import { getGiftLocalDateHours, getGiftLocalDateMinutes } from "../domain/gift"

export default function useChooseOpeningHourUseCase(giftClient: GiftClient) {
	const intl = useIntl()

	const { currentProjectId, currentStoreGift } = useGiftStoreDependencies()

	const updateGiftOpeningMode = giftClient.updateGiftOpeningMode

	const [hour, setHour] = useState(
		getGiftLocalDateHours(currentStoreGift?.openingDate) ?? 0,
	)
	const [minute, setMinute] = useState(
		getGiftLocalDateMinutes(currentStoreGift?.openingDate) ?? 0,
	)

	const [updateError, setUpdateError] = useState(
		undefined as string | undefined,
	)

	const updateGiftOpeningDateTime = useCallback(
		async (hour: number, minute: number) => {
			const currentDate = new Date(currentStoreGift?.openingDate!)
			const newDate = new Date(
				currentDate.getFullYear()!,
				currentDate.getMonth()!,
				currentDate.getDate()!,
				hour,
				minute,
			)
			await updateGiftOpeningMode(
				currentProjectId,
				currentStoreGift?.id!,
				currentStoreGift?.openingMode!,
				newDate,
				currentStoreGift?.openingGame,
			)
		},
		[currentProjectId, currentStoreGift, updateGiftOpeningMode],
	)

	const updateDateTime = useCallback(
		async (hour: number, minute: number) => {
			try {
				await updateGiftOpeningDateTime(hour, minute)
			} catch (err) {
				setUpdateError(
					(err as Error)?.message ??
						intl.formatMessage(
							{
								description: "configureOpeningGiftUpdateError",
								defaultMessage:
									"Une erreur s'est produite lors de {openingDesc}.",
							},
							{ openingDesc: "la mise à jour de la date" },
						),
				)
			}
		},
		[intl, updateGiftOpeningDateTime],
	)

	const hourChanged = useCallback(
		async (h: number) => {
			setHour(h)
			await updateDateTime(h, minute)
		},
		[minute, updateDateTime],
	)

	const minuteChanged = useCallback(
		async (m: number) => {
			setMinute(m)
			await updateDateTime(hour, m)
		},
		[hour, updateDateTime],
	)

	return {
		currentProjectId,
		currentStoreGift,
		hour,
		hourChanged,
		minute,
		minuteChanged,
		updateError,
	}
}
