import React, { useCallback, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import useProjectStore from "../stores/projectStore"

import SlicesHelper from "../stores/slices/helpers/slicesHelper"
import useRetrieveGiftUseCase from "../useCases/retrieveGiftUseCase"
import { FormattedMessage } from "react-intl"

import "./ExperienceLoadingScreen.css"

export default function ExperienceLoadingScreen() {
	const navigate = useNavigate()

	const [isDraggingMouse, setIsDraggingMouse] = useState(false)
	const [isDraggingTouch, setIsDraggingTouch] = useState(false)
	const [isComplete, setIsComplete] = useState(false)
	const [defaultButtonY, setDefaultButtonY] = useState(0)
	const [defaultSliderHeight, setDefaultSliderHeight] = useState(0)
	const [sliderPosY, setSliderPosY] = useState("0")
	const [borderOpacity, setBorderOpacity] = useState(25)

	const [notified, setNotified] = useState(false)

	const main: React.MutableRefObject<HTMLDivElement | null> = useRef(null)
	const button: React.MutableRefObject<HTMLButtonElement | null> =
		useRef(null)
	const slider: React.MutableRefObject<HTMLDivElement | null> = useRef(null)

	const giftClient = useProjectStore(SlicesHelper.getGiftClient)
	const projectClient = useProjectStore(SlicesHelper.getProjectClient)
	const { projectId, giftId, nSub } = useRetrieveGiftUseCase(
		giftClient,
		projectClient,
	)

	const completeAnimation = useCallback(() => {
		document.body.style.backgroundColor =
			giftClient.openedGift?.template?.solidBackground1 ?? "#fff"
		setTimeout(() => {
			navigate(
				`/projects/${projectId}/gifts/${giftId}/opening?step=slide1`,
			)
		}, 500)
	}, [giftId, navigate, projectId])

	function handleDragEnd() {
		setIsDraggingTouch(false)
		setIsDraggingMouse(false)
		if (isComplete) return
		setSliderPosY("0")
		setBorderOpacity(25)
	}

	const handleMove = useCallback(
		(yPos: number) => {
			if ((!isDraggingMouse && !isDraggingTouch) || isComplete) return

			const sliderTop = defaultButtonY - defaultSliderHeight + 42

			setBorderOpacity(
				(((yPos - sliderTop) / defaultSliderHeight) * 100) / 3,
			)

			if (yPos < defaultButtonY && yPos > sliderTop) {
				setSliderPosY(`${yPos - defaultButtonY}px`)
			}
			if (yPos <= sliderTop) {
				setIsDraggingMouse(false)
				setIsDraggingTouch(false)
				setIsComplete(true)
				completeAnimation()
			}
		},
		[
			completeAnimation,
			defaultButtonY,
			defaultSliderHeight,
			isComplete,
			isDraggingMouse,
			isDraggingTouch,
		],
	)

	const handleTouchMove = useCallback(
		(e: TouchEvent) => {
			e.preventDefault()
			handleMove(e.touches[0].clientY - 42)
		},
		[handleMove],
	)

	const handleMouseMove = useCallback(
		(e: MouseEvent) => {
			e.preventDefault()
			handleMove(e.clientY - 42)
		},
		[handleMove],
	)

	useEffect(() => {
		if (!button.current) return
		setDefaultButtonY(button.current.getBoundingClientRect().y)
		setDefaultSliderHeight(slider.current!.getBoundingClientRect().height)

		const mainElement: HTMLDivElement | null = main?.current

		mainElement?.addEventListener("mousemove", handleMouseMove, {
			passive: false,
		})
		mainElement?.addEventListener("touchmove", handleTouchMove, {
			passive: false,
		})

		return () => {
			mainElement?.removeEventListener("mousemove", handleMouseMove)
			mainElement?.removeEventListener("touchmove", handleTouchMove)
		}
	}, [
		isDraggingMouse,
		isDraggingTouch,
		giftId,
		handleMouseMove,
		handleTouchMove,
		projectId,
	])

	useEffect(() => {
		const openingDate = giftClient?.openedGift?.openingDate
		const isOpeningDatePassed =
			!openingDate ||
			new Date(openingDate).getTime() < new Date().getTime()
		const isOpeningGameNecessary =
			giftClient?.openedGift?.openingGame &&
			!projectClient.isOpeningGamePassed

		if (!isOpeningDatePassed) {
			navigate(
				`/projects/${projectId}/gifts/${giftId}/loading/date?nSub=${nSub}`,
			)
			return
		}
		if (isOpeningGameNecessary) {
			navigate(
				`/projects/${projectId}/gifts/${giftId}/loading/game?nSub=${nSub}`,
			)
			return
		}
	})

	useEffect(() => {
		if (
			giftClient.openedGift === undefined ||
			giftClient.openedGift.id === undefined ||
			giftClient.currentReceiverTrackingId === undefined
		) {
			return
		}

		if (!giftClient.openedGift.hasBeenOpened) {
			if (notified) return
			giftClient.notifyCreatorGiftOpening(
				projectId,
				giftClient.openedGift.id,
			)
			setNotified(true)
		}
	}, [giftClient, notified, projectId])

	return (
		<main
			ref={main}
			className={`container ${isComplete ? "complete" : ""}`}
			onMouseUp={handleDragEnd}
			onTouchEnd={handleDragEnd}
			style={{
				backgroundColor:
					giftClient.openedGift?.template?.solidBackground1,
			}}
		>
			<section
				className={"mainContentContainer"}
				style={{
					top: sliderPosY,
				}}
			>
				<section
					className={"topPanel"}
					style={{
						backgroundColor:
							giftClient.openedGift?.template?.solidBackground3,
					}}
				>
					<aside
						className={"topPanelContent"}
						style={{
							top: sliderPosY,
						}}
					>
						<h1>
							{projectClient.openedProject?.creator?.sign}{" "}
							<FormattedMessage
								description="loadingScreenTitle"
								defaultMessage="à un présent pour toi"
							/>
						</h1>
						<h2>
							<FormattedMessage
								description="loadingScreenSubtitle"
								defaultMessage="Il est temps de découvrir ce qu'il renferme"
							/>
						</h2>
					</aside>
				</section>
				<section className={"bottomPanel"}>
					<aside
						className={"leftPanel"}
						style={{
							backgroundColor:
								giftClient.openedGift?.template
									?.solidBackground3,
						}}
					></aside>
					<aside className={"middlePanel"}>
						<article
							className={"slider-container"}
							style={{
								backgroundColor:
									giftClient.openedGift?.template
										?.solidBackground3,
							}}
						>
							<article
								className={"slider"}
								style={{
									borderColor: `rgba(0,0,0,${borderOpacity}%)`,
								}}
							>
								<div ref={slider}>
									<button
										ref={button}
										onTouchStart={() => {
											setIsDraggingTouch(true)
										}}
										onMouseDown={() => {
											setIsDraggingMouse(true)
										}}
										style={{
											backgroundColor:
												giftClient.openedGift?.template
													?.callToActionBackgroundColor,
										}}
									>
										<img
											src={"/arrow_up.svg"}
											alt={"Swipe UP"}
										/>
									</button>
									<article
										className={"slider-extension"}
										style={{
											backgroundColor:
												giftClient.openedGift?.template
													?.solidBackground1,
										}}
									></article>
								</div>
							</article>
						</article>
					</aside>
					<aside
						className={"rightPanel"}
						style={{
							backgroundColor:
								giftClient.openedGift?.template
									?.solidBackground3,
						}}
					></aside>
				</section>
			</section>
			<footer>
				<img src={"/present.svg"} alt={"Logo Présent"} />
			</footer>
		</main>
	)
}
