import { useGiftStoreDependencies } from "../components/hooks/useGiftStoreDependencies"
import { GiftEventInfos } from "../stores/projectStore"
import { useCallback, useMemo, useState } from "react"
import OccasionsByCategory from "../models/occasionsByCategory"
import { useIntl } from "react-intl"
import OccasionClient from "../domain/adapters/secondary/occasionClient"
import GiftClient from "../domain/adapters/secondary/giftClient"

export default function useChooseOccasionUseCase(
	occasionClient: OccasionClient,
	giftClient: GiftClient,
) {
	const intl = useIntl()
	const { currentProjectId, currentStoreGift } = useGiftStoreDependencies()

	const loadOccasions = occasionClient.getOccasions
	const updateGiftEventInfos = giftClient.updateGiftEventInfos

	const [occasionsByCategory, setOccasionsByCategory] = useState({
		occasions: { categories: {} },
	} as OccasionsByCategory)
	const [occasionsError, setOccasionsError] = useState<string | null>(null)
	const [selectedOccasion, setSelectedOccasion] = useState<string | null>(
		currentStoreGift?.event?.occasion ?? null,
	)
	const isCustomOccasion = useMemo(
		() =>
			(currentStoreGift?.event?.occasion?.length ?? 0) > 0 &&
			(!selectedOccasion ||
				!Object.values(occasionsByCategory.occasions.categories)
					.flatMap((occasions) => occasions)
					.includes(selectedOccasion)),
		[
			occasionsByCategory,
			selectedOccasion,
			currentStoreGift?.event?.occasion?.length,
		],
	)
	const [updateError, setUpdateError] = useState(null as string | null)

	const getOccasions = useCallback(async () => {
		try {
			const occasionsByCat = await loadOccasions()
			setOccasionsByCategory(occasionsByCat)
			setOccasionsError(null)
		} catch {
			const genericError = intl.formatMessage({
				description: "generalUnknownErrorMessage",
				defaultMessage: "Une erreur s'est produite.",
			})

			setOccasionsError(genericError)
		}
	}, [loadOccasions, intl])

	const occasionSelected = useCallback(
		async (category: string, occasion: string) => {
			if (occasion === selectedOccasion) {
				setSelectedOccasion(null)
				return
			}
			setSelectedOccasion(occasion)

			try {
				const giftEventInfos: GiftEventInfos = {
					giftId: currentStoreGift?.id!,
					occasion: occasion,
					category: category,
					receivers: currentStoreGift?.event?.receivers,
					sendingMode: currentStoreGift?.event?.sendingMode,
				}

				await updateGiftEventInfos(currentProjectId, giftEventInfos)
				setUpdateError(null)
			} catch (err) {
				setUpdateError(
					(err as Error)?.message ??
						intl.formatMessage({
							description: "generalUnknownErrorMessage",
							defaultMessage: "Une erreur s'est produite.",
						}),
				)
			}
		},
		[
			currentStoreGift,
			intl,
			currentProjectId,
			selectedOccasion,
			updateGiftEventInfos,
		],
	)

	return {
		// occasions
		occasionsByCategory,
		selectedOccasion,
		occasionsError,
		updateError,
		isCustomOccasion,

		getOccasions,
		occasionSelected,
		// navigation
		currentProjectId,
		currentStoreGift,
	}
}
