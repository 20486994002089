import { useIntl } from "react-intl"
import { useGiftStoreDependencies } from "../components/hooks/useGiftStoreDependencies"
import { useTitle } from "../components/hooks/useTitle"
import { useDescription } from "../components/hooks/useDescription"
import { ConfigurationHelper } from "../helpers/configurationHelper"
import { useFileUpload } from "../components/hooks/useFileUpload"
import { useCallback } from "react"

export default function useConfigurePdfGiftUseCase() {
	const intl = useIntl()

	const { currentStoreGift, currentProjectId } = useGiftStoreDependencies()

	const {
		title,
		titleLoading,
		titleUpdateError,
		changeTitle,
		validateTitle,
		updateTitle,
		hasTitleChanged,
	} = useTitle(currentStoreGift?.title || "")

	const {
		description,
		descriptionLoading,
		descriptionUpdateError,
		changeDescription,
		validateDescription,
		updateDescription,
		hasDescriptionChanged,
	} = useDescription(currentStoreGift?.description || "")

	const existingPictureUrl =
		currentStoreGift?.picturePath !== undefined
			? `${
					process.env.REACT_APP_API_URL
			  }/${currentStoreGift?.picturePath.replaceAll("\\", "/")}`
			: undefined
	const { maxImageWeightInMb } = ConfigurationHelper.readConfiguration()
	const sizeErrorMessage = intl.formatMessage(
		{
			description: "configurePdfGiftSizeErrorMsg",
			defaultMessage: "Votre fichier ne peut pas excéder {maxSizeMo}mo",
		},
		{ maxSizeMo: maxImageWeightInMb },
	)
	const formatErrorMessage = intl.formatMessage({
		description: "configurePdfGiftFormatErrorMsg",
		defaultMessage: "Votre fichier doit être aux format pdf",
	})
	const unknownErrorMessage = intl.formatMessage({
		description: "generalUnknownErrorMessage",
		defaultMessage: "Une erreur s'est produite.",
	})
	const {
		fileContent: pictureContent,
		handleFileUpload: handlePictureUpload,
		fileLoading: pictureLoading,
		fileUploadError: pictureUploadError,
	} = useFileUpload({
		initialValue: existingPictureUrl || "",
		propertyToUpdateOnUpload: "picture",
		maxFileWeightInMb: maxImageWeightInMb,
		formatErrorMessage,
		sizeErrorMessage,
		unknownErrorMessage,
	})

	const { maxPdfWeightInMb } = ConfigurationHelper.readConfiguration()
	const pdfSizeErrorMessage = intl.formatMessage(
		{
			description: "configurePdfGiftSizeErrorMsg",
			defaultMessage: "Votre fichier ne peut pas excéder {maxSizeMo}mo",
		},
		{ maxSizeMo: maxPdfWeightInMb },
	)
	const pdfFormatErrorMessage = intl.formatMessage({
		description: "configurePdfGiftFormatErrorMsg",
		defaultMessage: "Votre fichier doit être aux format pdf",
	})
	const pdfUnknownErrorMessage = intl.formatMessage({
		description: "generalUnknownErrorMessage",
		defaultMessage: "Une erreur s'est produite.",
	})

	const existingPdfUrl =
		currentStoreGift?.pdf !== undefined
			? `${
					process.env.REACT_APP_API_URL
			  }/${currentStoreGift?.pdf.replaceAll("\\", "/")}`
			: undefined
	const pdfParts = existingPdfUrl?.split("/")
	const existingPdfFileName = pdfParts && pdfParts[pdfParts.length - 1]
	const {
		fileContent: pdfContent,
		handleFileUpload: handlePdfUpload,
		handleFileUploadError: handlePdfUploadError,
		fileValidationError: pdfValidationError,
		fileUploadError: pdfUploadError,
		fileLoading: pdfLoading,
	} = useFileUpload({
		initialValue: existingPdfUrl || "",
		propertyToUpdateOnUpload: "pdf",
		maxFileWeightInMb: maxPdfWeightInMb,
		formatErrorMessage: pdfFormatErrorMessage,
		sizeErrorMessage: pdfSizeErrorMessage,
		unknownErrorMessage: pdfUnknownErrorMessage,
	})

	const validPdfMimeTypes = ["application/pdf"]

	const checkAllFields = useCallback(
		() =>
			[pdfContent ?? "", title, description, pictureContent].find(
				(str) => str.length === 0,
			) === undefined,
		[pdfContent, title, description, pictureContent],
	)

	return {
		// title
		title,
		titleLoading,
		titleUpdateError,
		changeTitle,
		validateTitle,
		updateTitle,
		hasTitleChanged,
		// description
		description,
		descriptionLoading,
		descriptionUpdateError,
		changeDescription,
		validateDescription,
		updateDescription,
		hasDescriptionChanged,
		// fileUpload
		pdfContent,
		handlePdfUpload,
		handlePdfUploadError,
		pdfValidationError,
		pdfUploadError,
		pdfLoading,
		validPdfMimeTypes,
		// picture
		pictureContent,
		handlePictureUpload,
		pictureLoading,
		pictureUploadError,
		// others
		updateError:
			pictureUploadError ||
			pdfUploadError ||
			titleUpdateError ||
			descriptionUpdateError,
		loading:
			pictureLoading || pdfLoading || titleLoading || descriptionLoading,
		checkAllFields,
		existingPdfFileName,
		maxPdfWeightInMb,
		currentProjectId,
		currentStoreGift,
	}
}
