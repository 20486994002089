import { FormattedMessage } from "react-intl"
import useProjectStore from "../stores/projectStore"
import Page from "../components/Page"
import ProjectBreadcrumb, { Step } from "../components/ProjectBreadcrumb"
import { getSteps } from "./breadcrumbStepsHelper"
import Header2 from "../components/Header2"
import SmallText from "../components/SmallText"
import { Flex } from "@chakra-ui/react"
import TimePicker from "../components/TimePicker"
import React from "react"
import SecondaryButton from "../components/SecondaryButton"
import PrimaryButton from "../components/PrimaryButton"
import { Link as DomLink, useNavigate } from "react-router-dom"

import "./HideMainNavbar.css"
import useChooseOpeningHourUseCase from "../useCases/chooseOpeningDateHourUseCase"
import SlicesHelper from "../stores/slices/helpers/slicesHelper"

export default function OpeningDateHour() {
	const navigate = useNavigate()

	const giftClient = useProjectStore(SlicesHelper.getGiftClient)
	const {
		currentProjectId,
		currentStoreGift,
		hour,
		hourChanged,
		minute,
		minuteChanged,
		updateError,
	} = useChooseOpeningHourUseCase(giftClient)

	const steps: Step[] = getSteps(
		"Jeux d'ouverture",
		currentStoreGift?.reachedStep,
	)

	return (
		<Page gap="80px">
			<Flex flexDir="column" gap="40px">
				<ProjectBreadcrumb
					steps={steps}
					justifyContent="center"
					projectId={currentProjectId.toString()}
					giftId={currentStoreGift?.id?.toString()}
				/>

				<Flex flexDir="column" gap="24px" alignItems="center">
					<Flex flexDir="column" gap="4px">
						<Header2 alignSelf="center">
							<FormattedMessage
								description="openingDateHourTitleLabel"
								defaultMessage="Configurer l'heure d'ouverture"
							/>
						</Header2>
						<SmallText>
							<FormattedMessage
								description="openingDateHourSubtitle"
								defaultMessage="Votre destinataire ne pourra ouvrir son présent qu’après cette heure, le format à saisir est sur 24 heures."
							/>
						</SmallText>
					</Flex>

					<TimePicker
						onHourChange={async (h) => await hourChanged(h)}
						onMinuteChange={async (m) => await minuteChanged(m)}
						initialHour={hour}
						initialMinute={minute}
					></TimePicker>
				</Flex>
			</Flex>

			<Flex justifyContent="center" gap="12px">
				<SecondaryButton
					as={DomLink}
					variant="outline"
					onClick={(e) => {
						e.preventDefault()
						navigate(
							`/projects/${currentProjectId}/gifts/${
								currentStoreGift!.id
							}/openingDate`,
						)
					}}
				>
					<FormattedMessage
						description="generalReturn"
						defaultMessage="Retour"
					/>
				</SecondaryButton>
				<PrimaryButton
					disabled={updateError !== undefined}
					onClick={(e) => {
						e.preventDefault()
						navigate(
							`/projects/${currentProjectId}/gifts/${currentStoreGift?.id!}/packaging`,
						)
					}}
				>
					<FormattedMessage
						description="generalNext"
						defaultMessage="Suivant"
					/>
				</PrimaryButton>
			</Flex>
		</Page>
	)
}
