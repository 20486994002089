import React from "react"
import Page from "../components/Page"
import ProjectBreadcrumb from "../components/ProjectBreadcrumb"
import { Flex, Stack, Text } from "@chakra-ui/react"
import Header2 from "../components/Header2"
import { FormattedMessage } from "react-intl"
import SmallText from "../components/SmallText"
import SecondaryButton from "../components/SecondaryButton"
import PrimaryButton from "../components/PrimaryButton"
import { useNavigate } from "react-router-dom"
import useProjectStore from "../stores/projectStore"
import { getSteps } from "./breadcrumbStepsHelper"
import { Checkbox, CheckboxGroup } from "@chakra-ui/react"

import "./HideMainNavbar.css"
import SlicesHelper from "../stores/slices/helpers/slicesHelper"
import useChangeOpeningModeUseCase from "../useCases/changeOpeningModeUseCase"
import { OpeningMode } from "../domain/gift"

function OpeningModeList(props: {
	children: React.ReactNode
	defaultValues: string[]
	onChange: (value: string[]) => void
}): React.JSX.Element {
	return (
		<CheckboxGroup
			onChange={props.onChange}
			defaultValue={props.defaultValues}
		>
			<Stack direction="column" spacing="24px">
				{props.children}
			</Stack>
		</CheckboxGroup>
	)
}

function OpeningModeListItem(props: {
	children: React.ReactNode
	value: string
}): React.JSX.Element {
	return (
		<Flex backgroundColor="#647D990D" p="16px 24px" borderRadius="8px">
			<Checkbox
				value={props.value}
				colorScheme="teal"
				gap="16px"
				size="lg"
			>
				{props.children}
			</Checkbox>
		</Flex>
	)
}

export default function OpeningModeComponent(): React.JSX.Element {
	const navigate = useNavigate()

	const giftClient = useProjectStore(SlicesHelper.getGiftClient)
	const {
		// openingMode
		selectedMode,
		openingModeUpdated,
		modesByOpeningMode,

		// navigation
		currentProjectId,
		currentStoreGift,
	} = useChangeOpeningModeUseCase(giftClient)

	const getOpeningMode = (values: string[]) => {
		const openingModeSelector: Record<number, OpeningMode> = {
			0: "NONE",
			1: values[0] as OpeningMode,
			2: "GAME_AND_DATE",
		}
		if (values.length in openingModeSelector) {
			return openingModeSelector[values.length]
		}
		return "NONE"
	}

	const steps = getSteps("Jeux d'ouverture", currentStoreGift?.reachedStep)

	return (
		<Page gap="80px">
			<ProjectBreadcrumb
				steps={steps}
				justifyContent="center"
				projectId={currentProjectId.toString()}
				giftId={currentStoreGift?.id?.toString()}
			/>

			<Flex flexDir="column" maxW="554px" w="100%" mx="auto" gap="80px">
				<Flex flexDir="column" gap="24px">
					<Flex flexDir="column" gap="4px">
						<Header2 alignSelf="center">
							<FormattedMessage
								description="openingModeTitleLabel"
								defaultMessage="Ajouter un jeu d’ouverture"
							/>
						</Header2>
						<SmallText>
							<FormattedMessage
								description="openingModeTitleDesc"
								defaultMessage="Sélectionnez les options pour ajouter de la surprise à votre destinataire."
							/>
						</SmallText>
					</Flex>

					<OpeningModeList
						defaultValues={
							modesByOpeningMode[
								currentStoreGift?.openingMode ?? "NONE"
							]
						}
						onChange={async (values) => {
							await openingModeUpdated(
								currentProjectId,
								currentStoreGift?.id!,
								getOpeningMode(values),
								new Date(),
								{
									question: "__blank__",
									answers: ["blank"],
								},
							)
						}}
					>
						<OpeningModeListItem value="GAME">
							<Header2 fontSize="xl">
								<FormattedMessage
									description="openingModeGameTitle"
									defaultMessage="Question / Réponse"
								/>
							</Header2>
							<Text fontSize="sm">
								<FormattedMessage
									description="openingModeGameDesc"
									defaultMessage="Configurez une Q/R d’ouverture"
								/>
							</Text>
						</OpeningModeListItem>
						<OpeningModeListItem value="DATE">
							<Header2 fontSize="xl">
								<FormattedMessage
									description="openingModeDateTitle"
									defaultMessage="Date et heure"
								/>
							</Header2>
							<Text fontSize="sm">
								<FormattedMessage
									description="openingModeDateDesc"
									defaultMessage="Choisissez la date et l’heure d’ouverture"
								/>
							</Text>
						</OpeningModeListItem>
					</OpeningModeList>
				</Flex>

				<Flex justifyContent="center" gap="12px">
					<SecondaryButton
						variant="outline"
						onClick={(e) => {
							e.preventDefault()
							navigate(
								`/projects/${currentProjectId}/gifts/${currentStoreGift?.id}/packaging`,
							)
						}}
					>
						<FormattedMessage
							description="generalPass"
							defaultMessage="Passer"
						/>
					</SecondaryButton>
					<PrimaryButton
						isDisabled={selectedMode === undefined}
						onClick={(e) => {
							e.preventDefault()

							if (
								selectedMode === "GAME" ||
								selectedMode === "GAME_AND_DATE"
							) {
								navigate(
									`/projects/${currentProjectId}/gifts/${currentStoreGift?.id}/openingGame`,
								)
							} else if (selectedMode === "DATE") {
								navigate(
									`/projects/${currentProjectId}/gifts/${currentStoreGift?.id}/openingDate`,
								)
							} else if (selectedMode === "NONE") {
								navigate(
									`/projects/${currentProjectId}/gifts/${currentStoreGift?.id}/packaging`,
								)
							}
						}}
					>
						<FormattedMessage
							description="generalValidate"
							defaultMessage="Valider"
						/>
					</PrimaryButton>
				</Flex>
			</Flex>
		</Page>
	)
}
