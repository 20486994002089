import { Flex, Select, Text } from "@chakra-ui/react"
import React, { useState } from "react"

function TimePickerLabel(props: {
	label: string
	isFocused: boolean
}): React.JSX.Element {
	return (
		<Text
			fontSize="2xl"
			fontWeight="bold"
			w="48px"
			h="48px"
			p="4px"
			textAlign={"center"}
			borderRadius={"md"}
			bg={props.isFocused ? "teal.800" : "gray.200"}
			color={props.isFocused ? "white" : undefined}
		>
			{props.label}
		</Text>
	)
}

function TimePickerInput(props: {
	min: number
	max: number
	initialValue?: number
	onValueChange?: (value: number) => void
}): React.JSX.Element {
	const [value, setValue] = useState(props.initialValue ?? props.min)
	const [isFocused, setIsFocused] = useState(false)

	function handleChange(e: React.ChangeEvent<HTMLSelectElement>): void {
		const newValue = parseInt(e.target.value)
		setValue(newValue)
		if (!props.onValueChange) return
		props.onValueChange(newValue)
	}

	return (
		<Flex>
			<TimePickerLabel
				label={value.toString()}
				isFocused={isFocused}
			></TimePickerLabel>
			<Select
				cursor="pointer"
				position="absolute"
				w="48px"
				h="48px"
				opacity="0"
				icon={<></>}
				onChange={handleChange}
				onFocus={() => setIsFocused(true)}
				onBlur={() => setIsFocused(false)}
			>
				{Array.from(Array(props.max).keys()).map((val) => {
					return <option key={val}>{val}</option>
				})}
			</Select>
		</Flex>
	)
}

export default function TimePicker(props: {
	onHourChange: (hour: number) => void
	onMinuteChange: (minute: number) => void
	initialHour: number
	initialMinute: number
}) {
	return (
		<Flex gap="12px">
			<TimePickerInput
				min={0}
				max={24}
				initialValue={props.initialHour}
				onValueChange={(hour) => props.onHourChange(hour)}
			/>
			<Text fontSize="2xl" fontWeight="bold">
				:
			</Text>
			<TimePickerInput
				min={0}
				max={60}
				initialValue={props.initialMinute}
				onValueChange={(minute) => props.onMinuteChange(minute)}
			/>
		</Flex>
	)
}
