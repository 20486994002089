import { useGiftStoreDependencies } from "../components/hooks/useGiftStoreDependencies"
import { GiftEventInfos } from "../stores/projectStore"
import { useTextField } from "../components/hooks/useTextField"
import GiftEvent from "../domain/giftEvent"
import GiftClient from "../domain/adapters/secondary/giftClient"
import { useCallback } from "react"
import { useIntl } from "react-intl"

export default function useChooseOtherOccasionUseCase(giftClient: GiftClient) {
	const intl = useIntl()
	const { currentProjectId, currentStoreGift } = useGiftStoreDependencies()

	const updateGiftEventInfos = giftClient.updateGiftEventInfos

	const syncOccasion = useCallback(
		async (occasion: string) => {
			const giftEventInfos: GiftEventInfos = {
				giftId: currentStoreGift?.id!,
				occasion,
				receivers: undefined,
				sendingMode: undefined,
			}
			return await updateGiftEventInfos(currentProjectId, giftEventInfos)
		},
		[updateGiftEventInfos, currentStoreGift?.id, currentProjectId],
	)

	const {
		field: occasion,
		fieldLoading: occasionLoading,
		fieldUpdateError: occasionUpdateError,
		hasFieldChanged: hasOccasionChanged,
		updateField: updateOccasion,
		validateField: validateOccasion,
		changeField: changeOccasion,
	} = useTextField<GiftEvent>({
		initialValue: currentStoreGift?.event?.occasion ?? "",
		minSize: 3,
		maxSize: 20,
		uploadFieldDesc: intl.formatMessage({
			description: "otherOccasionUploadErrorEventDescription",
			defaultMessage: "la mise à jour de l'évènement",
		}),
		synchronize: syncOccasion,
	})

	const isOccasionLoadOrUpdateKO = () =>
		occasionLoading ||
		occasionUpdateError !== null ||
		!validateOccasion(occasion).success

	return {
		// other occasion
		occasion,
		occasionLoading,
		occasionUpdateError,
		hasOccasionChanged,
		updateOccasion,
		validateOccasion,
		changeOccasion,

		isOccasionLoadOrUpdateKO,
		// navigation
		currentProjectId,
		currentStoreGift,
	}
}
