import { getSteps } from "./breadcrumbStepsHelper"
import ProjectBreadcrumb, { Step } from "../components/ProjectBreadcrumb"
import { FormattedMessage } from "react-intl"
import Page from "../components/Page"
import Header2 from "../components/Header2"
import SmallText from "../components/SmallText"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import useProjectStore from "../stores/projectStore"
import { Flex, Select, chakra } from "@chakra-ui/react"
import PrimaryButton from "../components/PrimaryButton"
import SecondaryButton from "../components/SecondaryButton"
import { Link as DomLink } from "react-router-dom"
import SlicesHelper from "../stores/slices/helpers/slicesHelper"
import useSelectClientUseCase from "../useCases/selectClientUseCase"
import useSelectOrderUseCase from "../useCases/selectOrderUseCase"
import useReserveGiftUseCase from "../useCases/reserveGiftUseCase"

export default function Packaging(): React.JSX.Element {
	const navigate = useNavigate()

	const clientClient = useProjectStore(SlicesHelper.getClientSlice)
	const projectClient = useProjectStore(SlicesHelper.getProjectClient)
	const {
		// clients
		availableClients,
		selectedClient,
		loadClientsError,
		loadClients,
		selectedClientChanged,

		// navigation
		currentStoreGift,
		currentProjectId,
	} = useSelectClientUseCase(clientClient, projectClient)

	const commandClient = useProjectStore(SlicesHelper.getCommandSlice)
	const {
		// order selection
		selectedOrder,
		orderSelected,
		availableCommands,
	} = useSelectOrderUseCase(commandClient, Number(selectedClient))

	const giftClient = useProjectStore(SlicesHelper.getGiftClient)
	const { reserveGift, reserveError, isReserveLoading } =
		useReserveGiftUseCase(commandClient, giftClient)

	useEffect(() => {
		loadClients().then()
	}, [loadClients])

	const steps: Step[] = getSteps("Emballage", currentStoreGift?.reachedStep)

	return (
		<Page>
			<ProjectBreadcrumb
				steps={steps}
				pb="63.5px"
				justifyContent="center"
				projectId={currentProjectId.toString()}
				giftId={currentStoreGift?.id?.toString()}
			/>
			<Header2 alignSelf="center" mb="4px">
				<FormattedMessage
					description="packagingTitleLabel"
					defaultMessage="Lier le projet à une commande"
				/>
			</Header2>
			<SmallText mb="24px">
				<FormattedMessage
					description="packagingSubtitle"
					defaultMessage="Sélectionnez la commande lié à cette expérience Présent pour charger les noeuds."
				/>
			</SmallText>
			<Flex gap="80px" flexDir="column">
				<Flex
					flexDir="column"
					maxW="554px"
					w="100%"
					mx="auto"
					gap="24px"
				>
					<Select
						placeholder="Client"
						bgColor="white"
						value={selectedClient}
						onChange={(e) => {
							selectedClientChanged(e.target.value)
						}}
					>
						{availableClients.map((client) => (
							<option key={client.id} value={client.id}>
								{client.company}
							</option>
						))}
					</Select>
					<Select
						placeholder="Numéro de commande"
						bgColor="white"
						isDisabled={selectedClient === ""}
						value={selectedOrder}
						onChange={(e) => orderSelected(e.target.value)}
					>
						{availableCommands.flatMap((command, idx) => [
							<chakra.option key={idx} value={command.id}>
								{command.remaining_knots}{" "}
								<FormattedMessage
									description="genLabelNode"
									defaultMessage="{nodeCount, plural, one {noeud} other {noeuds}}"
									values={{
										nodeCount: command.knot_quantity,
									}}
								/>{" "}
								<FormattedMessage
									description="genNodeSize"
									defaultMessage="{size, select, Petit {Petit} Moyen {Moyen} Grand {Grand} other {Inconnue}}"
									values={{
										size: command.knot_size,
									}}
								/>{" "}
								<FormattedMessage
									description="genNodeColor"
									defaultMessage="{color, select, Bleu {Bleu} Rouge {Rouge} Beige {Beige} Vert {Vert} other {Inconnue}}"
									values={{ color: command.knot_color }}
								/>
							</chakra.option>,
							<chakra.option
								key={idx + availableCommands.length + 10}
								value={command.id}
								disabled={true}
								fontStyle="italic"
							>
								&nbsp;&nbsp;&nbsp;&nbsp;{command.order_number}
							</chakra.option>,
						])}
					</Select>
				</Flex>
				{loadClientsError && (
					<Flex color="red.500">{loadClientsError}</Flex>
				)}
				{(reserveError?.length ?? 0) > 0 && (
					<Flex color="red.500">{reserveError}</Flex>
				)}
				<Flex justifyContent="center" gap="12px">
					<SecondaryButton
						as={DomLink}
						variant="outline"
						onClick={(e) => {
							e.preventDefault()
							navigate(
								`/projects/${currentProjectId}/gifts/${
									currentStoreGift!.id
								}/openingDateHour`,
							)
						}}
					>
						<FormattedMessage
							description="generalReturn"
							defaultMessage="Retour"
						/>
					</SecondaryButton>
					<PrimaryButton
						isDisabled={
							selectedClient === "" || selectedOrder === ""
						}
						isLoading={isReserveLoading}
						onClick={async (e) => {
							e.preventDefault()

							const success = await reserveGift(
								currentProjectId,
								currentStoreGift.id ?? -1,
								Number(selectedOrder),
							)
							if (!success) {
								return
							}

							navigate(
								`/projects/${currentProjectId}/gifts/${currentStoreGift?.id!}/packagingTable`,
							)
						}}
					>
						<FormattedMessage
							description="generalNext"
							defaultMessage="Suivant"
						/>
					</PrimaryButton>
				</Flex>
			</Flex>
		</Page>
	)
}
