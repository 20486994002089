import React from "react"
import ProjectBreadcrumb, { Step } from "../components/ProjectBreadcrumb"
import Page from "../components/Page"
import {
	Flex,
	FormControl,
	FormErrorMessage,
	Input,
	InputGroup,
	Radio,
	RadioGroup,
	Stack,
} from "@chakra-ui/react"
import Header2 from "../components/Header2"
import { FormattedMessage, useIntl } from "react-intl"
import SmallText from "../components/SmallText"
import SecondaryButton from "../components/SecondaryButton"
import PrimaryButton from "../components/PrimaryButton"
import { getSteps } from "./breadcrumbStepsHelper"
import { Link as DomLink, useNavigate } from "react-router-dom"
import useProjectStore from "../stores/projectStore"
import { Kind } from "../domain/receiver"

import "./CreateProject.css"
import "./HideMainNavbar.css"
import SlicesHelper from "../stores/slices/helpers/slicesHelper"
import useFinalizeUniqueReceiverUseCase from "../useCases/finalizeUniqueReceiverUseCase"

export default function ReceiversUnique(): React.JSX.Element {
	const intl = useIntl()
	const navigate = useNavigate()

	const giftClient = useProjectStore(SlicesHelper.getGiftClient)
	const projectClient = useProjectStore(SlicesHelper.getProjectClient)

	const {
		// receiver
		triedToSubmit,
		receiverInfos,
		finalizeAttempted,
		receiverUpdated,
		isFormNotComplete,
		receiverValidatedField,
		updateReceiverInfos,
		// navigation
		currentProjectId,
		currentStoreGift,
		// project opening
		refreshCurrentStoreGift,
	} = useFinalizeUniqueReceiverUseCase(giftClient, projectClient)

	const steps: Step[] = getSteps("Évènement", currentStoreGift?.reachedStep)

	return (
		<Page gap="80px">
			<ProjectBreadcrumb
				steps={steps}
				justifyContent="center"
				projectId={currentProjectId.toString()}
				giftId={currentStoreGift?.id?.toString()}
			/>

			<Flex flexDir="column" maxW="554px" w="100%" mx="auto" gap="80px">
				<Flex flexDir="column" gap="24px">
					<Flex flexDir="column" gap="4px">
						<Header2 alignSelf="center">
							<FormattedMessage
								description="receiversUniqueTitleLabel"
								defaultMessage="À qui est destiné ce cadeau ?"
							/>
						</Header2>
						<SmallText>
							<FormattedMessage
								description="receiversUniqueSubtitle"
								defaultMessage="Saisissez le nom de votre destinataire, celui-ci apparaîtra lors de l’ouverture de l’expérience. Vous pouvez saisir un nom de famille, un prénom, un surnom, une civilité, etc."
							/>
						</SmallText>
					</Flex>
				</Flex>

				<Flex display="flex" flexDir="column" gap="16px">
					<InputGroup gap="16px">
						<FormControl
							alignSelf="center"
							bgColor="rgba(57, 119, 121, 0.04)"
							borderRadius="6px"
							isInvalid={
								triedToSubmit &&
								!receiverValidatedField.firstname
							}
							className={
								triedToSubmit &&
								!receiverValidatedField.firstname
									? "invactiveFc"
									: "activeFc"
							}
						>
							<Input
								size="lg"
								maxLength={50}
								type="text"
								className={
									triedToSubmit &&
									!receiverValidatedField.firstname
										? "inactiveInput"
										: "activeInput"
								}
								color="var(--var-text-color)"
								placeholder={intl.formatMessage({
									description:
										"receiversUniqueFirstnamePlaceholder",
									defaultMessage: "Prénom du destinataire",
								})}
								value={receiverInfos.firstname}
								onChange={(e) =>
									receiverUpdated({
										...receiverInfos,
										firstname: e.target.value,
									})
								}
							/>
							<FormErrorMessage>
								<FormattedMessage
									description="receiversUniqueFirstnameErrorMessage"
									defaultMessage="Veuillez saisir un prénom valide, entre 3 et 50 caractères"
								></FormattedMessage>
							</FormErrorMessage>
						</FormControl>
						<FormControl
							isInvalid={
								triedToSubmit && !receiverValidatedField.name
							}
							alignSelf="center"
							bgColor="rgba(57, 119, 121, 0.04)"
							borderRadius="6px"
							className={
								triedToSubmit && !receiverValidatedField.name
									? "invactiveFc"
									: "activeFc"
							}
						>
							<Input
								size="lg"
								maxLength={50}
								type="text"
								placeholder={intl.formatMessage({
									description:
										"receiversUniqueNamePlaceholder",
									defaultMessage: "Nom du destinataire",
								})}
								value={receiverInfos.name}
								onChange={(e) =>
									receiverUpdated({
										...receiverInfos,
										name: e.target.value,
									})
								}
							/>
							<FormErrorMessage>
								<FormattedMessage
									description="receiversUniqueNameErrorMessage"
									defaultMessage="Veuillez saisir un nom valide, entre 3 et 50 caractères"
								></FormattedMessage>
							</FormErrorMessage>
						</FormControl>
					</InputGroup>
					<FormControl
						isInvalid={
							triedToSubmit && !receiverValidatedField.email
						}
						alignSelf="center"
						bgColor="rgba(57, 119, 121, 0.04)"
						borderRadius="6px"
						className={
							triedToSubmit && !receiverValidatedField.email
								? "invactiveFc"
								: "activeFc"
						}
					>
						<Input
							size="lg"
							maxLength={50}
							type="email"
							placeholder={intl.formatMessage({
								description:
									"receiversUniqueFirstnamePlaceholder",
								defaultMessage: "E-mail du destinataire",
							})}
							value={receiverInfos.email}
							onChange={(e) =>
								receiverUpdated({
									...receiverInfos,
									email: e.target.value,
								})
							}
						/>
						<FormErrorMessage>
							<FormattedMessage
								description="receiversUniqueEmailErrorMessage"
								defaultMessage="Veuillez saisir un e-mail valide"
							></FormattedMessage>
						</FormErrorMessage>
					</FormControl>
					<FormControl
						isInvalid={
							triedToSubmit && !receiverValidatedField.kind
						}
						alignSelf="center"
						borderRadius="6px"
						className={
							triedToSubmit && !receiverValidatedField.kind
								? "invactiveFc"
								: "activeFc"
						}
					>
						<RadioGroup
							onChange={(value) => {
								receiverUpdated({
									...receiverInfos,
									kind: value as Kind,
								})
							}}
							value={receiverInfos.kind}
						>
							<Stack direction="row" gap="16px">
								<Radio
									colorScheme="teal"
									value="Man"
									py={"4px"}
								>
									<FormattedMessage
										description="receiversUniqueManLabel"
										defaultMessage="Homme"
									/>
								</Radio>
								<Radio
									colorScheme="teal"
									value="Woman"
									py={"4px"}
								>
									<FormattedMessage
										description="receiversUniqueWomanLabel"
										defaultMessage="Femme"
									/>
								</Radio>
								<Radio
									colorScheme="teal"
									value="Other"
									py={"4px"}
								>
									<FormattedMessage
										description="receiversUniqueOtherLabel"
										defaultMessage="Autre"
									/>
								</Radio>
							</Stack>
						</RadioGroup>
					</FormControl>
				</Flex>

				<Flex justifyContent="center" gap="12px">
					<SecondaryButton
						as={DomLink}
						variant="outline"
						onClick={(e) => {
							e.preventDefault()
							navigate(
								`/projects/${currentProjectId}/gifts/${
									currentStoreGift!.id
								}/receiversMode`,
							)
						}}
					>
						<FormattedMessage
							description="generalReturn"
							defaultMessage="Retour"
						/>
					</SecondaryButton>
					<PrimaryButton
						isDisabled={isFormNotComplete()}
						onClick={async (e) => {
							e.preventDefault()
							finalizeAttempted(true)
							await updateReceiverInfos(
								receiverInfos,
								currentStoreGift,
							)
							await refreshCurrentStoreGift()

							navigate(
								`/projects/${currentProjectId}/gifts/${currentStoreGift?.id!}/occasion`,
							)
						}}
					>
						<FormattedMessage
							description="generalNext"
							defaultMessage="Suivant"
						/>
					</PrimaryButton>
				</Flex>
			</Flex>
		</Page>
	)
}
