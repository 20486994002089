export default class PathHelper {
	static extractFileName(path: string) {
		return (path.split("\\").pop() ?? "").split("/").pop()
	}

	static appendBeforeExtension(pathOrFileName: string, append: string) {
		const dotIndex = pathOrFileName.lastIndexOf(".")
		return `${pathOrFileName.substring(
			0,
			dotIndex,
		)}${append}${pathOrFileName.substring(dotIndex)}`
	}
}
