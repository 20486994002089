import React, { useCallback, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import SlicesHelper from "../stores/slices/helpers/slicesHelper"
import useProjectStore from "../stores/projectStore"
import useRetrieveGiftUseCase from "../useCases/retrieveGiftUseCase"

import "./ExperienceOpeningQA.css"

export default function ExperienceOpeningQA(): React.JSX.Element {
	const navigate = useNavigate()
	const intl = useIntl()
	const giftClient = useProjectStore(SlicesHelper.getGiftClient)
	const projectClient = useProjectStore(SlicesHelper.getProjectClient)
	const { projectId, giftId, nSub } = useRetrieveGiftUseCase(
		giftClient,
		projectClient,
	)

	const [answer, setAnswer] = useState("")
	const [isAnswerInvalid, setIsAnswerInvalid] = useState(false)

	const redirectToLoading = useCallback(() => {
		navigate(
			`/projects/${projectId}/gifts/${giftId}/loading/swipe?nSub=${nSub}`,
		)
	}, [giftId, nSub, navigate, projectId])

	function validateAnswer(): void {
		const isAnswerValid = giftClient?.openedGift?.openingGame?.answers
			.map((possibleAnswer) => possibleAnswer.toLowerCase())
			.includes(answer.toLowerCase())
		if (isAnswerValid) {
			projectClient.updateOpeningGameState(true)
		}
		setIsAnswerInvalid(!isAnswerValid)
	}

	return (
		<main
			className={`container-QA`}
			style={{
				backgroundColor:
					giftClient.openedGift?.template?.solidBackground3,
			}}
		>
			{!projectClient.isOpeningGamePassed && (
				<div className="content-QA">
					<h1>{giftClient?.openedGift?.openingGame?.question}</h1>
					<form
						className={isAnswerInvalid ? "error" : ""}
						onSubmit={(e) => {
							e.preventDefault()
							validateAnswer()
						}}
					>
						<input
							type={"text"}
							placeholder={intl.formatMessage({
								description:
									"experienceOpeningGameAnswerPlaceholder",
								defaultMessage: "Saisissez la réponse",
							})}
							onChange={(e) => {
								setAnswer(e.target.value)
								setIsAnswerInvalid(false)
							}}
						/>
						{isAnswerInvalid && (
							<p>
								<FormattedMessage
									description="experienceOpeningGameAnswerError"
									defaultMessage="Ce n’est pas la bonne réponse... veuillez réessayer ! Pensez à vérifier l’orthographe."
								/>
							</p>
						)}
					</form>
				</div>
			)}

			{projectClient.isOpeningGamePassed && (
				<div className="content-QA">
					<img src={"/opening_success.gif"} alt={"Opening Success"} />
					<h1>
						<FormattedMessage
							description="experienceOpeningGameSuccessTitle"
							defaultMessage="Bonne réponse 🎉"
						/>
					</h1>
				</div>
			)}

			<footer>
				<button
					type={"button"}
					className={"btn-primary"}
					onClick={
						projectClient.isOpeningGamePassed
							? redirectToLoading
							: validateAnswer
					}
					style={{
						backgroundColor:
							giftClient.openedGift?.template
								?.callToActionBackgroundColor,
					}}
				>
					{!projectClient.isOpeningGamePassed && (
						<FormattedMessage
							description="experienceOpeningGameValidateAnswer"
							defaultMessage="Valider la réponse"
						/>
					)}
					{projectClient.isOpeningGamePassed && (
						<FormattedMessage
							description="experienceOpeningGameValidAnswer"
							defaultMessage="Ouvrir mon présent"
						/>
					)}
				</button>
				<img src={"/present_noir.svg"} alt={"Logo Présent"} />
			</footer>
		</main>
	)
}
