import { StateCreator } from "zustand"
import { ProjectStoreState } from "../../projectStore"
import ProjectClient from "../../../domain/adapters/secondary/projectClient"
import { fetchApi } from "../../storeHelper"
import Project from "../../../domain/project"
import Gift from "../../../domain/gift"

export const createProjectSlice: StateCreator<
	ProjectStoreState,
	[["zustand/immer", never]],
	[],
	ProjectClient
> = (set, get) => ({
	isOpeningGamePassed: false,
	currentProjectId: -1,
	projects: [],
	openedProject: undefined,

	createProject: async (project: Pick<Project, "name">) => {
		const newProject = await fetchApi<Project>(
			"projects",
			"POST",
			JSON.stringify(project),
		)
		set((state) => {
			state.projects.push(newProject)
		})
		return newProject
	},
	projectSelected: (projectId: number) => {
		set((state) => {
			state.currentProjectId = projectId
		})
	},
	refreshCurrentGift: async (): Promise<void> => {
		const currentProjectId = get().currentProjectId
		const projectIdx = get().projects.findIndex(
			(p) => p.id === currentProjectId,
		)

		if (projectIdx === -1) {
			throw new Error("current project not found!")
		}

		const gifts = get().projects[projectIdx].gifts
		const currentGift = (gifts.length > 0 && gifts[0]) || undefined
		if (currentGift === undefined) {
			throw new Error(
				"Invalid operation : no gift is present on current project!",
			)
		}
		const refreshedGift = await fetchApi<Gift>(
			`projects/${currentProjectId}/gifts/${currentGift.id}`,
			"GET",
		)
		set((state) => {
			state.projects[projectIdx].gifts[0] = refreshedGift
		})
	},
	renameProject: async (projectId: number, newName: string) => {
		const renamedProject = await fetchApi<Project>(
			`projects/${projectId}`,
			"PUT",
			JSON.stringify({
				newName,
			}),
		)

		set((state) => {
			const projIdx = state.projects.findIndex(
				(proj) => proj.id === projectId,
			)
			state.projects[projIdx] = renamedProject
		})

		return renamedProject
	},
	getAll: () =>
		fetchApi<Project[]>("projects", "GET").then((projects) => {
			set((state) => {
				state.projects = projects
			})
			return projects
		}),
	getProject: async (projectId: number) => {
		const project = await fetchApi<Project>(`projects/${projectId}`, "GET")
		set((state) => {
			state.openedProject = project
			if (
				(state.projects.filter((proj) => proj.id === project.id)
					?.length ?? 0) === 0
			) {
				state.projects.push(project)
			}
		})
		return project
	},
	updateOpeningGameState: (isPassed: boolean) => {
		set((state) => {
			state.isOpeningGamePassed = isPassed
		})
	},
})
