import ProjectClient from "../domain/adapters/secondary/projectClient"
import TemplateClient from "../domain/adapters/secondary/templateClient"
import { useCallback, useState } from "react"
import Project from "../domain/project"

export default function useOpenProjectUseCase(
	projectClient: ProjectClient,
	templateClient: TemplateClient,
) {
	const [currentProject, setCurrentProject] = useState<Project | undefined>(
		projectClient.projects.find(
			(proj) => proj.id === projectClient.currentProjectId,
		),
	)
	const [openProjectError, setOpenProjectError] = useState<string | null>(
		null,
	)

	const refreshCurrentGift = projectClient.refreshCurrentGift
	const projectSelected = projectClient.projectSelected
	const projects = projectClient.projects
	const getTemplates = templateClient.getTemplates

	const openProject = useCallback(
		async (projectId: number) => {
			if (
				currentProject !== null &&
				(currentProject?.id ?? -1) === projectId
			) {
				return
			}

			try {
				// projectSelected does not produces any error
				projectSelected(projectId)
				setCurrentProject(
					projects.find((proj) => proj.id === projectId),
				)
				await getTemplates()
				await refreshCurrentGift()
				setOpenProjectError(null)
			} catch (err) {
				if (err instanceof Error) {
					setOpenProjectError(
						`[nonblocking][error][1] ${err.message ?? ""}\n${
							err.stack ?? ""
						}`,
					)
					return
				}
				setOpenProjectError(
					"[nonblocking][error][1] Une erreur s'est produite au chargement du projet",
				)
			}
		},
		[
			currentProject,
			projects,
			projectSelected,
			getTemplates,
			refreshCurrentGift,
		],
	)

	return {
		currentProject,
		openProjectError,
		openProject,
	}
}
